import GeneralUrls from "./GeneralUrls";


export const datos = {
  Cabana_1: {
    nombre: "Ave azul",
    capacidadMax: 12,
    capacidadMin: 3,
    admiteNinos: true,
    admiteMascotas: true,
    elCostoVariaSegunHuespedes: true,
    camasKing: 1,
    camasQueen: 0,
    camasMatrimonial: 4,
    camasIndividual: 2,
    sofaCama: 0,
    literas: 0,
    inodoros: 2,
    regaderas: 2,
    fotoPortada: `${GeneralUrls.landing_page}recursos/inicio/portadaCab1.png`,
    longitud_galeria: 37,
    rutaGaleria: `${GeneralUrls.landing_page}recursos/Mas_detalles/Ave_azul`,
    plano: `${GeneralUrls.landing_page}recursos/Mas_detalles/Ave_azul/LAYOUT.png`,
    videoVistas:
      "https://www.youtube.com/embed/j4_h7Q1lJSc?controls=1&autoplay=1&mute=1&loop=1&playlist=j4_h7Q1lJSc",
  },
  Cabana_2: {
    nombre: "Jilguero",
    capacidadMax: 6,
    capacidadMin: 2,
    admiteNinos: true,
    admiteMascotas: true,
    elCostoVariaSegunHuespedes: true,
    camasKing: 1,
    camasQueen: 0,
    camasMatrimonial: 0,
    camasIndividual: 0,
    sofaCama: 0,
    literas: 2,
    inodoros: 1,
    regaderas: 1,
    fotoPortada: `${GeneralUrls.landing_page}recursos/inicio/portadaCab2.png`,
    longitud_galeria: 29,
    rutaGaleria: `${GeneralUrls.landing_page}recursos/Mas_detalles/Jilguero`,
    plano: `${GeneralUrls.landing_page}recursos/Mas_detalles/Jilguero/LAYOUT.png`,
    videoVistas:
      "https://www.youtube.com/embed/z5r24WJD5gY?controls=1&autoplay=1&mute=1&loop=1&playlist=z5r24WJD5gY",
  },
  Cabana_3: {
    nombre: "Tigrillo",
    capacidadMax: 8,
    capacidadMin: 3,
    admiteNinos: true,
    admiteMascotas: true,
    elCostoVariaSegunHuespedes: true,
    camasKing: 1,
    camasQueen: 0,
    camasMatrimonial: 1,
    camasIndividual: 0,
    sofaCama: 1,
    literas: 2,
    inodoros: 2,
    regaderas: 1,
    longitud_galeria: 32,
    fotoPortada: `${GeneralUrls.landing_page}recursos/inicio/portadaCab3.png`,
    rutaGaleria: `${GeneralUrls.landing_page}recursos/Mas_detalles/Tigrillo`,
    plano: `${GeneralUrls.landing_page}recursos/Mas_detalles/Tigrillo/LAYOUT.png`,
    videoVistas:
      "https://www.youtube.com/embed/GC6TKHHRerM?controls=1&autoplay=1&mute=1&loop=1&playlist=GC6TKHHRerM",
  },
  Cabana_4: {
    nombre: "Colibrí",
    capacidadMax: 6,
    capacidadMin: 2,
    admiteNinos: true,
    admiteMascotas: true,
    elCostoVariaSegunHuespedes: true,
    camasKing: 1,
    camasQueen: 0,
    camasMatrimonial: 2,
    camasIndividual: 0,
    sofaCama: 0,
    literas: 0,
    inodoros: 2,
    regaderas: 2,
    longitud_galeria: 33,
    fotoPortada: `${GeneralUrls.landing_page}recursos/inicio/portadaCab4.png`,
    rutaGaleria: `${GeneralUrls.landing_page}recursos/Mas_detalles/Colibri`,
    plano: `${GeneralUrls.landing_page}recursos/Mas_detalles/Colibri/LAYOUT.png`,
    videoVistas:
      "https://www.youtube.com/embed/i81u90YxNfI?controls=1&autoplay=1&mute=1&loop=1&playlist=i81u90YxNfI",
  },
  Cabana_5: {
    nombre: "Coa",
    capacidadMax: 7,
    capacidadMin: 2,
    admiteNinos: true,
    admiteMascotas: true,
    elCostoVariaSegunHuespedes: true,
    camasKing: 0,
    camasQueen: 1,
    camasMatrimonial: 2,
    camasIndividual: 1,
    sofaCama: 1,
    literas: 0,
    inodoros: 2,
    regaderas: 2,
    longitud_galeria: 39,
    fotoPortada: `${GeneralUrls.landing_page}recursos/inicio/portadaCab5.png`,
    rutaGaleria: `${GeneralUrls.landing_page}recursos/Mas_detalles/Coa`,
    plano: `${GeneralUrls.landing_page}recursos/Mas_detalles/Coa/LAYOUT.png`,
    videoVistas:
      "https://www.youtube.com/embed/hm2Hp1TmEIM?controls=1&autoplay=1&mute=1&loop=1&playlist=hm2Hp1TmEIM",
  },
  Cabana_6: {
    nombre: "Búho",
    capacidadMax: 2,
    capacidadMin: 2,
    admiteNinos: false,
    admiteMascotas: false,
    elCostoVariaSegunHuespedes: false,
    camasKing: 0,
    camasQueen: 1,
    camasMatrimonial: 0,
    camasIndividual: 0,
    sofaCama: 1,
    literas: 0,
    inodoros: 1,
    regaderas: 1,
    longitud_galeria: 23,
    fotoPortada: `${GeneralUrls.landing_page}recursos/inicio/portadaCab6.png`,
    rutaGaleria: `${GeneralUrls.landing_page}recursos/Mas_detalles/Buho`,
    plano: undefined,
    videoVistas:
      "https://www.youtube.com/embed/XXRUc6FSSd8?controls=1&autoplay=1&mute=1&loop=1&playlist=XXRUc6FSSd8",
  },
};

export const cantidadMaximaDeHuespedes = () => {
  return Object.keys(datos).reduce(
    (acc, cur) => acc + datos[cur].capacidadMax,
    0
  );
};

export const cantidadMinimaDeHuespedes = () => {
  return Object.keys(datos).reduce(
    (acc, cur) => acc + datos[cur].capacidadMin,
    0
  );
};

export const cantidadMaximaDeBebes = 5;

export const cantidadMaximaDeMascotas = 5;
