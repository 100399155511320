import * as types from "../actionTypes";

const initialState = {
  reservacionesPropias: [],
  reservacionEnfocada: null,
  refrescaReservacionesPropias: false,
  refrescaReservacionEnfocada: false,
  cargandoUsuario: false,
};

export default function CabanasReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_RESERVACIONES_USUARIO:
      return {
        ...state,
        cargandoUsuario: true,
      };
    case types.GET_RESERVACIONES_USUARIO_SUCCESS:
      return {
        ...state,
        reservacionesPropias: action.resultados.data,
        refrescaReservacionesPropias: false,
        cargandoUsuario: false,
      };
    case types.GET_RESERVACIONES_USUARIO_ERROR:
      return {
        ...state,
        reservacionesPropias: [],
        cargandoUsuario: false,
      };
    case types.GET_RESERVACION_USUARIO:
      return {
        ...state,
        cargandoUsuario: true,
      };
    case types.GET_RESERVACION_USUARIO_SUCCESS:
      return {
        ...state,
        reservacionEnfocada: action.resultados.data,
        refrescaReservacionEnfocada: false,
        cargandoUsuario: false,
      };
    case types.GET_RESERVACION_USUARIO_ERROR:
      return {
        ...state,
        reservacionEnfocada: null,
        cargandoUsuario: false,
      };
    case types.GET_RESERVACION_USUARIO_TEMPORAL:
      return {
        ...state,
        cargandoUsuario: true,
      };
    case types.GET_RESERVACION_USUARIO_TEMPORAL_SUCCESS:
      return {
        ...state,
        reservacionEnfocada: action.resultados.data,
        refrescaReservacionEnfocada: false,
        cargandoUsuario: false,
      };
    case types.GET_RESERVACION_USUARIO_TEMPORAL_ERROR:
      return {
        ...state,
        reservacionEnfocada: null,
        cargandoUsuario: false,
      };
    case types.PUT_DATOS_RESERVACION:
      return {
        ...state,
        cargandoUsuario: true,
      };
    case types.PUT_DATOS_RESERVACION_SUCCESS:
      return {
        ...state,
        reservacionEnfocada: action.resultados.data,
        refrescaReservacionEnfocada: true,
        cargandoUsuario: false,
      };
    case types.PUT_DATOS_RESERVACION_ERROR:
      return {
        ...state,
        cargandoUsuario: false,
      };
    case types.ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES:
      return {
        ...state,
        cargandoUsuario: true,
      };
    case types.ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES_SUCCESS:
      return {
        ...state,
        cargandoUsuario: false,
      };
    case types.ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES_ERROR:
      return {
        ...state,
        cargandoUsuario: false,
      };
    default:
      return { ...state };
  }
}
