import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import "./PanelDeReservacion.css";

//services
import { getInstruccionSeguimiento } from "../../services/seguimientoServices";

// import BotonPaypal from "../../Components/BotonPaypal/BotonPaypal";
import MercadoPago from "../../Components/MercadoPago/MercadoPago";
import Modal from "../../Components/Modal/Modal";
import ConsultorDeCupon from "../../Components/ConsultorDeCupon/ConsultorDeCupon";
import VisualizadorDeCupon from "../../Components/VisualizadorDeCupon/VisualizadorDeCupon";
import DesgloseDePrecio from "../../Components/DesgloseDePrecio/DesgloseDePrecio";
import FeedbackPago from "../../Components/FeedbackPago/FeedbackPago";
import FlechaAnimadaScroll from "../../Components/FlechaAnimadaScroll/FlechaAnimadaScroll";
// import CantidadDeHuespedes from "../../Components/CantidadDeHuespedes/CantidadDeHuespedes";

//Assets
import * as GeneralSVG from "../../assets/SVG/ExportadorGeneralSVG";

//Utils
import fechaLarga from "../../util/fechaLarga";
import generadorDeCodigoTemp from "../../util/generadorDeCodigoTemp";
import generadorDeCodigoReservacion from "../../util/generadorDeCodigoReservacion";
import switchNombreDeCabana from "../../util/switchNombreDeCabana";
import jsToSqlDate from "../../util/NodeToSQLDateParser";

//Redux
import * as AuthActions from "../../redux/Actions/AuthActions";
import * as AuthSelectors from "../../redux/Selectors/AuthSelector";
import * as ReservacionesActions from "../../redux/Actions/reservacionesActions";
import * as EventosActions from "../../redux/Actions/EventosActions";
import * as ReservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";
import * as MercadoPagoSelectors from "../../redux/Selectors/MercadoPagoSelector";

//Animaciones
import * as AnimacionesGenerales from "../../AnimacionesGenerales/AnimacionesGenerales_exporter";
import { Fragment } from "react";

//Consts
import GeneralUrls from "../../consts/GeneralUrls";
import * as CaracteristicasCabanas from "../../consts/CaracteristicasCabanas";

//iconos
import * as BootstrapIcons from "react-icons/bs";
import * as MaterialDesignIcons from "react-icons/md";
import * as Ionicons5 from "react-icons/io5";

const PanelDeReservacion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const logged = useSelector((state) => AuthSelectors.logged(state));
  const modalLogin = useSelector((state) => AuthSelectors.modalLogin(state));
  const userInfo = useSelector((state) => AuthSelectors.userInfo(state));
  const cabanasARentar = useSelector((state) =>
    ReservacionesSelectors.cabanasARentar(state)
  );
  const fechaDeEntrada = useSelector((state) =>
    ReservacionesSelectors.fechaDeEntrada(state)
  );
  const fechaDeSalida = useSelector((state) =>
    ReservacionesSelectors.fechaDeSalida(state)
  );
  const numeroDeHuespedes = useSelector((state) =>
    ReservacionesSelectors.numeroDeHuespedes(state)
  );
  const costo = useSelector((state) => ReservacionesSelectors.costo(state));
  const costoTotal = useSelector((state) =>
    ReservacionesSelectors.costoTotal(state)
  );
  const listoParaReservar = useSelector((state) =>
    ReservacionesSelectors.listoParaReservar(state)
  );
  const cupon = useSelector((state) => ReservacionesSelectors.cupon(state));
  const seCreoReservacionSinPago = useSelector((state) =>
    ReservacionesSelectors.seCreoReservacionSinPago(state)
  );
  const seCreoReservacionPorAdmin = useSelector((state) =>
    ReservacionesSelectors.seCreoReservacionPorAdmin(state)
  );
  const estatusDePagoMercadoPago = useSelector((state) =>
    MercadoPagoSelectors.estatusDePago(state)
  );
  const porcentajeDePrimerPago = 30;
  const [codigoDeReservacion, setCodigoDeReservacion] = useState(null);
  const [codigoDeReservacionTemporal, setCodigoDeReservacionTemporal] =
    useState(generadorDeCodigoTemp()); //FIX
  const [reservacionTemporal, setReservacionTemporal] = useState({});
  const [verModalCupon, setVerModalCupon] = useState(false);
  const [verModalDesglose, setVerModalDesglose] = useState(false);
  const [nombre, setNombre] = useState();
  const [apellido, setApellido] = useState();
  const [telefono, setTelefono] = useState();
  const [correoElectronico, setCorreoElectronico] = useState();
  const [reservarAOtroNombre, setReservarAOtroNombre] = useState(false);
  const [aceptaReglas, setAceptaReglas] = useState(false);
  const [reservacionSinPago, setReservacionSinPago] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    let idTimeout = window.setTimeout(() => {
      Swal.fire(
        "Se ha llegado al tiempo limite para concluir su reservación",
        "Será redirigido al comparador",
        "warning"
      );
      history.push("/");
    }, 3600000);
    return function cleanup() {
      window.clearTimeout(idTimeout);
    };
  }, []);
  useEffect(() => {
    if (
      fechaDeEntrada &&
      fechaDeSalida &&
      cabanasARentar &&
      numeroDeHuespedes
    ) {
      if (getInstruccionSeguimiento() !== "no_seguir") {
        // Evento listo para reservar para Tag Manager
        window.dataLayer.push({
          event: "listo_reservar",
          descripcion_reservacion: `C:${cabanasARentar.join(
            "-"
          )}/E:${jsToSqlDate(fechaDeEntrada)}/S:${jsToSqlDate(
            fechaDeSalida
          )}/A:${numeroDeHuespedes.numeroDeAdultos}/N:${
            numeroDeHuespedes.numeroDeNinos
          }/B:${numeroDeHuespedes.numeroDeBebes}/M:${
            numeroDeHuespedes.numeroDeMascotas
          }`,
        });
        // Evento listo para reservar para actividad-api
        dispatch(
          EventosActions.postEventoListoReservar({
            fechaDeEntrada,
            fechaDeSalida,
            numeroDeAdultos: numeroDeHuespedes.numeroDeAdultos,
            numeroDeNinos: numeroDeHuespedes.numeroDeNinos,
            numeroDeBebes: numeroDeHuespedes.numeroDeBebes,
            numeroDeMascotas: numeroDeHuespedes.numeroDeMascotas,
            cabana: cabanasARentar,
          })
        );
      }
      dispatch(
        ReservacionesActions.getCosto({
          fechaDeEntrada,
          fechaDeSalida,
          numeroDeAdultos: numeroDeHuespedes.numeroDeAdultos,
          numeroDeMascotas: numeroDeHuespedes.numeroDeMascotas,
          cabana: cabanasARentar.join("-"),
        })
      );
    } else {
      dispatch(ReservacionesActions.setCostoTotal(null));
    }
  }, [
    fechaDeEntrada,
    fechaDeSalida,
    cabanasARentar,
    dispatch,
    numeroDeHuespedes,
  ]);
  useEffect(() => {
    if (userInfo && reservarAOtroNombre === false) {
      setNombre(userInfo.nombre);
      setApellido(userInfo.apellido);
      setCorreoElectronico(userInfo.email);
      setTelefono(userInfo.telefono);
    }
  }, [reservarAOtroNombre, userInfo]);
  useEffect(() => {
    if (userInfo && userInfo.rol === "Agencia de viajes") {
      setReservarAOtroNombre(true);
      setCorreoElectronico(userInfo.email);
    }
  }, [userInfo]);
  useEffect(() => {
    if (!listoParaReservar) {
      history.push("/");
    } else {
      let objeto = {
        codigoDeReservacion: codigoDeReservacionTemporal,
        cabana: cabanasARentar.join("-"),
        fechaDeEntrada: fechaDeEntrada,
        fechaDeSalida: fechaDeSalida,
        numeroDeAdultos: numeroDeHuespedes.numeroDeAdultos,
        numeroDeNinos: numeroDeHuespedes.numeroDeNinos,
        numeroDeBebes: numeroDeHuespedes.numeroDeBebes,
        numeroDeMascotas: numeroDeHuespedes.numeroDeMascotas,
      };
      setReservacionTemporal(objeto);
      dispatch(ReservacionesActions.postReservacionTemporal(objeto));
      AnimacionesGenerales.Opening(() => {});
      window.onbeforeunload = function (e) {
        dispatch(ReservacionesActions.deleteReservacionTemporal(objeto));
        dispatch(ReservacionesActions.resetReservacionesState());
        return "Las fechas fueron liberadas, si desea volver a intentar y permanecer en el sitio, haga clic en cancelar, si desea abandonar el sitio haga clic en salir";
      };
      return () => {
        dispatch(ReservacionesActions.deleteReservacionTemporal(objeto));
        dispatch(ReservacionesActions.resetReservacionesState());
      };
    }
  }, [
    cabanasARentar,
    dispatch,
    fechaDeEntrada,
    fechaDeSalida,
    history,
    listoParaReservar,
    numeroDeHuespedes,
    codigoDeReservacionTemporal,
  ]);
  useEffect(() => {
    if (costoTotal <= 100 && !reservacionSinPago) {
      setReservacionSinPago(true);
    } else if (costoTotal > 100 && reservacionSinPago) {
      setReservacionSinPago(false);
    }
  }, [costoTotal]);
  useEffect(() => {
    //Actualizar reservacion temporal discretamente
    if (document.getElementById("formDatosDelCliente").checkValidity()) {
      let objeto = Object.assign(
        reservarAOtroNombre || !userInfo
          ? {
              nombre: nombre + " " + apellido,
              telefono: telefono,
              correoElectronico: correoElectronico,
            }
          : {
              nombre: userInfo.nombre + " " + userInfo.apellido,
              telefono: userInfo.telefono,
              correoElectronico: userInfo.email,
            },
        reservacionTemporal
      );
      objeto.usuarioRegistrado = logged;
      objeto.fechaDeConfirmacion = new Date(Date.now());
      setCodigoDeReservacion(generadorDeCodigoReservacion(objeto));
      dispatch(ReservacionesActions.putReservacionTemporal(objeto));
    }
  }, [
    reservarAOtroNombre,
    userInfo,
    nombre,
    apellido,
    telefono,
    correoElectronico,
    aceptaReglas,
  ]);
  const registrarReservacionSinPago = () => {
    let objeto = Object.assign(
      reservarAOtroNombre || !userInfo
        ? {
            nombre: nombre + " " + apellido,
            telefono: telefono,
            correoElectronico: correoElectronico,
          }
        : {
            nombre: userInfo.nombre + " " + userInfo.apellido,
            telefono: userInfo.telefono,
            correoElectronico: userInfo.email,
          },
      reservacionTemporal
    );
    objeto.codigoDeReservacionTemporal = objeto.codigoDeReservacion;
    objeto.usuarioRegistrado = logged;
    objeto.fechaDeConfirmacion = new Date(Date.now());
    objeto.IdCupon = cupon.Id;
    let codigo = generadorDeCodigoReservacion(objeto);
    setCodigoDeReservacion(codigo);
    objeto.codigoDeReservacion = codigo;
    dispatch(ReservacionesActions.putReservacionSinPago(objeto));
  };
  const descripcionDeReservacionString = () => {
    if (listoParaReservar) {
      return `Renta de cabaña${
        cabanasARentar.length > 1 ? "s" : ""
      } ${switchNombreDeCabana(cabanasARentar.join("-"))} para ${
        numeroDeHuespedes.numeroDeAdultos
      } adultos, ${
        numeroDeHuespedes.numeroDeNinos > 0
          ? `${numeroDeHuespedes.numeroDeNinos} niños, `
          : ""
      }${
        numeroDeHuespedes.numeroDeBebes > 0
          ? `${numeroDeHuespedes.numeroDeBebes} bebes, `
          : ""
      }${
        numeroDeHuespedes.numeroDeMascotas > 0
          ? `${numeroDeHuespedes.numeroDeMascotas} mascotas, `
          : ""
      } entrando el ${fechaLarga(fechaDeEntrada)} y saliendo el ${fechaLarga(
        fechaDeSalida
      )} - Código de reservación ${codigoDeReservacion}`;
    }
  };
  return (
    <div className="panelDeReservacion vista">
      <Helmet>
        <link rel="icon" href="../logoSoloMini.png" />
        <link rel="apple-touch-icon" href="../logoSoloMini.png" />
        <meta
          name="description"
          content={`Cima Calidez le ofrece hermosas cabañas en el bosque y uno de los mejores servicios de atención en todo Mazamitla`}
        />
        <title>Cima Calidez - Resumen Reservación</title>
      </Helmet>
      <div id="reservacion">
        <section id="datosDeReservacion">
          <h1>Informacion de la reservación</h1>
          <div id="contenedorCabanasARentar">
            {Array.isArray(cabanasARentar) &&
              cabanasARentar.map((cabana) => {
                return (
                  <div key={cabana} className="cabanasARentar">
                    <img
                      src={
                        CaracteristicasCabanas.datos[`Cabana_${cabana}`]
                          .fotoPortada
                      }
                      alt="imagen de portada de cabaña"
                    />{" "}
                    <h4>
                      Cabaña{" "}
                      {`${
                        CaracteristicasCabanas.datos[`Cabana_${cabana}`].nombre
                      }`}
                    </h4>
                  </div>
                );
              })}
          </div>
          <div className="datosDeReservacion-bloqueTexto">
            <div>
              <div className="contenedores-datos-reservacion">
                <label htmlFor="datos-fecha-entrada">Fecha de entrada</label>
                <p id="datos-fecha-entrada">
                  {fechaDeEntrada && fechaLarga(fechaDeEntrada)}, 4:00pm hora
                  local
                </p>
              </div>
              <div className="contenedores-datos-reservacion">
                <label htmlFor="datos-fecha-salida">Fecha de salida</label>
                <p id="datos-fecha-salida">
                  {fechaDeSalida && fechaLarga(fechaDeSalida)}, 12:00pm hora
                  local
                </p>
              </div>
              {numeroDeHuespedes.numeroDeAdultos > 0 && (
                <div className="contenedores-datos-reservacion">
                  <label htmlFor="datos-huespedes-adultos">Adultos</label>
                  <p id="datos-huespedes-adultos">
                    {numeroDeHuespedes.numeroDeAdultos}
                  </p>
                </div>
              )}
              {numeroDeHuespedes.numeroDeNinos > 0 && (
                <div className="contenedores-datos-reservacion">
                  <label htmlFor="datos-huespedes-ninos">
                    Menores de 12 años
                  </label>
                  <p id="datos-huespedes-ninos">
                    {numeroDeHuespedes.numeroDeNinos}
                  </p>
                </div>
              )}
              {numeroDeHuespedes.numeroDeBebes > 0 && (
                <div className="contenedores-datos-reservacion">
                  <label htmlFor="datos-huespedes-bebes">
                    Menores de 3 años
                  </label>
                  <p id="datos-huespedes-bebes">
                    {numeroDeHuespedes.numeroDeBebes}
                  </p>
                </div>
              )}
              {numeroDeHuespedes.numeroDeMascotas > 0 && (
                <div className="contenedores-datos-reservacion">
                  <label htmlFor="datos-huespedes-mascotas">Mascotas</label>
                  <p id="datos-huespedes-mascotas">
                    {numeroDeHuespedes.numeroDeMascotas}
                  </p>
                </div>
              )}
            </div>
            <div className="datosDeReservacion-info-pago">
              {costo && costo.descuento && (
                <div className="descuento">
                  <label>
                    {costo.descuento.etiqueta} (
                    {costo.descuento.tipo === "dinero" ? "$" : ""}-
                    {costo.descuento.valor}
                    {costo.descuento.tipo === "porcentaje" ? "%" : ""})
                  </label>
                  <p>
                    <span className="precio-original">${costo.descuento.costoOriginal}</span>
                    {"   "}
                    <BootstrapIcons.BsArrowRight />
                    {"   "}
                    <span className="precio-descuento">${costoTotal}</span>
                  </p>
                </div>
              )}
              {porcentajeDePrimerPago < 100 && !reservacionSinPago && (
                <div className="primero">
                  <label>Anticipo hoy (30%)</label>
                  <p>
                    ${(costoTotal * (porcentajeDePrimerPago / 100)).toFixed(2)}{" "}
                  </p>
                </div>
              )}
              {porcentajeDePrimerPago < 100 && !reservacionSinPago && (
                <div className="segundo">
                  <label>Pago el día de entrada (70%)</label>
                  <p>
                    $
                    {(costoTotal * (1 - porcentajeDePrimerPago / 100)).toFixed(
                      2
                    )}{" "}
                  </p>
                </div>
              )}
              <div className="total">
                <label>
                  Total a pagar {reservacionSinPago && "el dia de entrada"}
                </label>
                <p>${costoTotal} MXN</p>
              </div>
            </div>
            <div className="div-boton-aplicar-cupon-desglose">
              <button onClick={() => setVerModalDesglose(true)}>
                Ver desglose
              </button>
              {cupon ? (
                <button
                  className="boton-quitar-cupon"
                  onClick={() => {
                    dispatch(ReservacionesActions.quitarCupon());
                    dispatch(
                      ReservacionesActions.getCosto({
                        fechaDeEntrada,
                        fechaDeSalida,
                        numeroDeAdultos: numeroDeHuespedes.numeroDeAdultos,
                        numeroDeMascotas: numeroDeHuespedes.numeroDeMascotas,
                        cabana: cabanasARentar.join("-"),
                      })
                    );
                  }}
                >
                  Quitar cupón de descuento
                </button>
              ) : (
                <button onClick={() => setVerModalCupon(true)}>
                  Aplicar cupón de descuento
                </button>
              )}
            </div>
          </div>
        </section>
        <section id="datosDelCliente">
          <h1>Información de cliente</h1>
          <form
            id="formDatosDelCliente"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {logged && !reservarAOtroNombre && userInfo && (
              <Fragment>
                <div className="infoUsuarioPanelReservacion">
                  <img
                    className="panelReservacionImagenPerfil"
                    src={
                      userInfo && userInfo.imagenPerfil
                        ? userInfo.imagenPerfil
                        : GeneralSVG.Avatar_predeterminado
                    }
                    alt="imagen de perfil"
                  />
                  <p>
                    {userInfo.nombre} {userInfo.apellido}
                  </p>
                  <p>{userInfo.email}</p>
                  <p>{userInfo.telefono}</p>
                </div>
                <br />
                <p
                  className="textoResaltadoVerde"
                  onClick={() => setReservarAOtroNombre(true)}
                >
                  Haga clic aquí para reservar a nombre de otra persona
                </p>
              </Fragment>
            )}
            {(!logged || reservarAOtroNombre) && (
              <Fragment>
                {logged && userInfo && userInfo.rol === "Agencia de viajes" && (
                  <div className="divDatosAgenciaDeViajes">
                    <img
                      className="panelReservacionImagenPerfil"
                      src={
                        userInfo && userInfo.imagenPerfil
                          ? userInfo.imagenPerfil
                          : GeneralSVG.Avatar_predeterminado
                      }
                      alt="imagen de perfil"
                    />
                    <p>
                      {userInfo.nombre} {userInfo.apellido}
                    </p>
                    <p>Agencia de viajes</p>
                    <p className="pDatosDeSuCliente">Datos de su cliente</p>
                  </div>
                )}
                <div className="divDatosEscritos">
                  <div className="divNombre">
                    <label htmlFor="nombreCliente">Nombre</label>
                    <input
                      type="text"
                      id="nombreCliente"
                      required
                      onChange={(event) => setNombre(event.target.value)}
                    />
                  </div>
                  <div className="divApellido">
                    <label htmlFor="apellidoCliente">Apellido</label>
                    <input
                      type="text"
                      id="apellidoCliente"
                      required
                      onChange={(event) => setApellido(event.target.value)}
                    />
                  </div>
                  <div className="divTelefono">
                    <label htmlFor="telefono">Telefono o celular</label>
                    <input
                      type="tel"
                      id="telefono"
                      minLength="8"
                      required
                      onChange={(event) => {
                        setTelefono(event.target.value);
                      }}
                    />
                  </div>
                  <br />
                  {(!userInfo ||
                    (userInfo && userInfo.rol !== "Agencia de viajes")) && (
                    <div className="divCorreo">
                      <label htmlFor="correoElectronico">email</label>
                      <input
                        type="email"
                        id="correoElectronico"
                        required
                        onChange={(event) =>
                          setCorreoElectronico(event.target.value.toLowerCase())
                        }
                      />
                      <br />
                    </div>
                  )}
                </div>
                {(!userInfo ||
                  (userInfo && userInfo.rol !== "Agencia de viajes")) && (
                  <Fragment>
                    {logged && userInfo ? (
                      <p
                        className="textoResaltadoVerde cambiarNombreDeCliente"
                        onClick={() => setReservarAOtroNombre(false)}
                      >
                        Haga clic aqui para usar su información de usuario
                      </p>
                    ) : (
                      <p
                        className="textoResaltadoVerde"
                        onClick={() =>
                          dispatch(AuthActions.modalLogin(!modalLogin))
                        }
                      >
                        Ó haga clic aquí para ingresar con su usuario
                      </p>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
            <div id="divLabelAceptaReglamento">
              {aceptaReglas ? (
                <MaterialDesignIcons.MdCheckBox
                  className="aceptaReglamentoSustituto"
                  onClick={() => setAceptaReglas(false)}
                />
              ) : (
                <MaterialDesignIcons.MdCheckBoxOutlineBlank
                  className="aceptaReglamentoSustituto"
                  onClick={() => setAceptaReglas(true)}
                />
              )}
              <input
                type="checkbox"
                id="aceptaReglamento"
                name="aceptaReglamento"
                checked={aceptaReglas}
                onChange={() => {}}
                required
              />
              <label htmlFor="aceptaReglamento" id="labelAceptaReglamento">
                {userInfo && userInfo.rol === "Agencia de viajes"
                  ? "Tanto yo como el cliente hemos leído y aceptamos el"
                  : "He leído y acepto el"}{" "}
                <a
                  href={`${GeneralUrls.landing_page}Reglamento/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Reglamento interno de Cima Calidez
                </a>{" "}
                al igual que las{" "}
                <a
                  href={`${GeneralUrls.landing_page}Politicas_de_cancelacion/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Políticas de cancelación
                </a>
              </label>
            </div>
          </form>
        </section>
        <section id="metodoDePago">
          {reservacionSinPago ? (
            <button
              id="divBotonContinuarAPago"
              onClick={() => {
                if (
                  document
                    .getElementById("formDatosDelCliente")
                    .reportValidity()
                ) {
                  registrarReservacionSinPago();
                } else {
                  //Se requiere hacer un reportValidity despues de un segundo ya que por medio del scroll se borra el mensaje de validez
                  setTimeout(
                    () =>
                      document
                        .getElementById("formDatosDelCliente")
                        .reportValidity(),
                    1000
                  );
                }
              }}
            >
              Concluir Reservación
            </button>
          ) : (
            <Fragment>
              <h1>Información de Pago</h1>
              <MercadoPago
                cantidad={Number(
                  (costoTotal * (porcentajeDePrimerPago / 100)).toFixed(2)
                )}
                fechaDeEntrada={fechaDeEntrada}
                descripcion={descripcionDeReservacionString()}
                correoElectronico={correoElectronico}
                nombre={logged && userInfo ? userInfo.nombre : nombre}
                apellido={logged && userInfo ? userInfo.apellido : apellido}
                codigoDeReservacion={codigoDeReservacion}
                codigoDeReservacionTemporal={codigoDeReservacionTemporal}
              />
            </Fragment>
          )}
        </section>
        <button
          id="botonRegresar"
          onClick={(e) => {
            e.preventDefault();
            AnimacionesGenerales.Closing(() => history.goBack());
          }}
        >
          <Ionicons5.IoArrowUndoSharp /> Regresar
        </button>
        <Modal
          visibilidad={verModalCupon}
          setModal={setVerModalCupon}
          forzado={true}
        >
          {cupon ? (
            <VisualizadorDeCupon
              funcionCancelar={() => setVerModalCupon(false)}
            />
          ) : (
            <ConsultorDeCupon funcionCancelar={() => setVerModalCupon(false)} />
          )}
        </Modal>
        <Modal
          visibilidad={verModalDesglose}
          setModal={setVerModalDesglose}
          forzado={true}
        >
          <DesgloseDePrecio funcionCerrar={() => setVerModalDesglose(false)} />
        </Modal>
        <Modal visibilidad={seCreoReservacionSinPago} forzado={true}>
          <div className="modal-feedback-pago">
            <FeedbackPago
              estatus={"approved"}
              detalle={"accredited"}
              codigoDeReservacion={codigoDeReservacion}
              descripcionReservacion={descripcionDeReservacionString()}
            />
          </div>
        </Modal>
        <Modal visibilidad={estatusDePagoMercadoPago} forzado={true}>
          <div className="modal-feedback-pago">
            <FeedbackPago
              estatus={
                estatusDePagoMercadoPago
                  ? estatusDePagoMercadoPago.status
                  : seCreoReservacionPorAdmin
                  ? "Pago directo"
                  : null
              }
              detalle={
                estatusDePagoMercadoPago
                  ? estatusDePagoMercadoPago.status_detail
                  : seCreoReservacionPorAdmin
                  ? "Pago directo"
                  : null
              }
              descripcionReservacion={descripcionDeReservacionString()}
              codigoDeReservacion={codigoDeReservacion}
            />
          </div>
        </Modal>
      </div>
      <FlechaAnimadaScroll
        elemento={document.getElementById("botonRegresar")}
      />
    </div>
  );
};

export default PanelDeReservacion;
