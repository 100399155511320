import { get } from "lodash";

export const modalLogin = (state) => get(state, "AuthReducer.modalLogin");
export const userInfo = (state) => get(state, "AuthReducer.userInfo");
export const logged = (state) => get(state, "AuthReducer.logged");
export const token = (state) => get(state, "AuthReducer.token");
export const registrado = (state) => get(state, "AuthReducer.registrado");
export const correoDeRecuperacionEnviado = (state) =>
  get(state, "AuthReducer.correoDeRecuperacionEnviado");
export const contrasenaActualizada = (state) =>
  get(state, "AuthReducer.contrasenaActualizada");
export const cargandoAuth = (state) => get(state, "AuthReducer.cargandoAuth");
export const esAdmin = (state) => get(state, "AuthReducer.esAdmin");
