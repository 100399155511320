// Buscador

export const GET_FECHAS_SIN_DISPONIBILIDAD = "GET_FECHA_SIN_DISPONIBILIDAD"
export const GET_FECHAS_SIN_DISPONIBILIDAD_SUCCESS = "GET_FECHA_SIN_DISPONIBILIDAD_SUCCESS"
export const GET_FECHAS_SIN_DISPONIBILIDAD_ERROR = "GET_FECHA_SIN_DISPONIBILIDAD_ERROR"

export const GET_CABANAS_DISPONIBLES = "GET_CABANAS_DISPONIBLES";
export const GET_CABANAS_DISPONIBLES_SUCCESS = "GET_CABANAS_DISPONIBLES_SUCCESS";
export const GET_CABANAS_DISPONIBLES_ERROR = "GET_CABANAS_DISPONIBLES_ERROR";

export const BORRAR_CABANAS_DISPONIBLES = "BORRAR_CABANAS_DISPONIBLES"

//Cabañas individuales

export const GET_FECHAS_OCUPADAS_CABANA = "GET_FECHAS_OCUPADAS_CABANA"
export const GET_FECHAS_OCUPADAS_CABANA_SUCCESS = "GET_FECHAS_OCUPADAS_CABANA_SUCCESS"
export const GET_FECHAS_OCUPADAS_CABANA_ERROR = "GET_FECHAS_OCUPADAS_CABANA_ERROR"

// Reservaciones

export const SET_CABANAS_RENTAR = "SET_CABANAS_RENTAR";
export const SET_FECHA_ENTRADA = "SET_FECHA_ENTRADA";
export const SET_FECHA_SALIDA = "SET_FECHA_SALIDA";
export const SET_FECHA_SALIDA_MAXIMA = "SET_FECHA_SALIDA_MAXIMA";
export const SET_NUMERO_HUESPEDES = "SET_CANTIDAD_HUESPEDES";
export const SET_COSTO_TOTAL = "SET_COSTO_TOTAL";
export const SET_LISTO_RESERVAR = "SET_LISTO_RESERVER";
export const SET_SE_RESERVO = "SET_SE_RESERVO";
export const RESET_RESERVACIONES_STATE = "RESET_RESERVACIONES_STATE";

export const GET_COSTO = "GET_COSTO"
export const GET_COSTO_SUCCESS = "GET_COSTO_SUCCESS"
export const GET_COSTO_ERROR = "GET_COSTO_ERROR"

export const VERIFICAR_DISPONIBILIDAD = "VERIFICAR_DISPONIBILIDAD"
export const VERIFICAR_DISPONIBILIDAD_SUCCESS = "VERIFICAR_DISPONIBILIDAD_SUCCESS"
export const VERIFICAR_DISPONIBILIDAD_ERROR = "VERIFICAR_DISPONIBILIDAD_ERROR"

export const POST_RESERVACION_TEMPORAL = "POST_RESERVACION_TEMPORAL"
export const POST_RESERVACION_TEMPORAL_SUCCESS = "POST_RESERVACION_TEMPORAL_SUCCESS"
export const POST_RESERVACION_TEMPORAL_ERROR = "POST_RESERVACION_TEMPORAL_ERROR"

export const PUT_RESERVACION_TEMPORAL = "PUT_RESERVACION_TEMPORAL"
export const PUT_RESERVACION_TEMPORAL_SUCCESS = "PUT_RESERVACION_TEMPORAL_SUCCESS"
export const PUT_RESERVACION_TEMPORAL_ERROR = "PUT_RESERVACION_TEMPORAL_ERROR"

export const DELETE_RESERVACION_TEMPORAL = "DELETE_RESERVACION_TEMPORAL"
export const DELETE_RESERVACION_TEMPORAL_SUCCESS = "DELETE_RESERVACION_TEMPORAL_SUCCESS"
export const DELETE_RESERVACION_TEMPORAL_ERROR = "DELETE_RESERVACION_TEMPORAL_ERROR"

export const CREAR_RESERVACION_COMO_ADMIN = "CREAR_RESERVACION_COMO_ADMIN"
export const CREAR_RESERVACION_COMO_ADMIN_SUCCESS = "CREAR_RESERVACION_COMO_ADMIN_SUCCESS"
export const CREAR_RESERVACION_COMO_ADMIN_ERROR = "CREAR_RESERVACION_COMO_ADMIN_ERROR"

export const GET_INFO_CUPON = "GET_INFO_CUPON"
export const GET_INFO_CUPON_SUCCESS = "GET_INFO_CUPON_SUCCESS"
export const GET_INFO_CUPON_ERROR = "GET_INFO_CUPON_ERROR"

export const QUITAR_CUPON = "QUITAR_CUPON"

export const PUT_RESERVACION_SIN_PAGO = "PUT_RESERVACION_SIN_PAGO"
export const PUT_RESERVACION_SIN_PAGO_SUCCESS = "PUT_RESERVACION_SIN_PAGO_SUCCESS"
export const PUT_RESERVACION_SIN_PAGO_ERROR = "PUT_RESERVACION_SIN_PAGO_ERROR"


//MercadoPago

export const POST_PAGO_MERCADOPAGO = "POST_PAGO_MERCADOPAGO"
export const POST_PAGO_MERCADOPAGO_SUCCESS = "POST_PAGO_MERCADOPAGO_SUCCESS"
export const POST_PAGO_MERCADOPAGO_ERROR = "POST_PAGO_MERCADOPAGO_ERROR"

export const GET_OPCIONES_PAGO = "GET_OPCIONES_PAGO" 
export const GET_OPCIONES_PAGO_SUCCESS = "GET_OPCIONES_PAGO_SUCCESS" 
export const GET_OPCIONES_PAGO_ERROR = "GET_OPCIONES_PAGO_ERROR" 

export const RESET_MERCADOPAGO_ESTATUS_PAGO = "RESET_MERCADOPAGO_ESTATUS_PAGO"
export const RESET_CLEAR_SESSION_MERCADOPAGO = "RESET_CLEAR_SESSION_MERCADOPAGO"

//Auth
export const MODAL_LOGIN = "MODAL_LOGIN"

export const LOGIN_USUARIO_REGISTRADO = "LOGIN_USUARIO_REGISTRADO"
export const LOGIN_USUARIO_REGISTRADO_SUCCESS = "LOGIN_USUARIO_REGISTRADO_SUCCESS"
export const LOGIN_USUARIO_REGISTRADO_ERROR = "LOGIN_USUARIO_REGISTRADO_ERROR"

export const LOGIN_USUARIO_TEMPORAL = "LOGIN_USUARIO_TEMPORAL"
export const LOGIN_USUARIO_TEMPORAL_SUCCESS = "LOGIN_USUARIO_TEMPORAL_SUCCESS"
export const LOGIN_USUARIO_TEMPORAL_ERROR = "LOGIN_USUARIO_TEMPORAL_ERROR"

export const REGISTRAR_USUARIO = "REGISTRAR_USUARIO"
export const REGISTRAR_USUARIO_SUCCESS = "REGISTRAR_USUARIO_SUCCESS"
export const REGISTRAR_USUARIO_ERROR = "REGISTRAR_USUARIO_ERROR"

export const VERIFICAR_USUARIO = "VERIFICAR_USUARIO"
export const VERIFICAR_USUARIO_SUCCESS = "VERIFICAR_USUARIO_SUCCESS"
export const VERIFICAR_USUARIO_ERROR = "VERIFICAR_USUARIO_ERROR"

export const RESET_VERIFICAR_USUARIO = "RESET_VERIFICAR_USUARIO"

export const LOG_OUT = "LOG_OUT"

export const VERIFICA_ACCESS_TOKEN = "VERIFY_ACCESS_TOKEN"
export const VERIFICA_ACCESS_TOKEN_SUCCESS = "VERIFY_ACCESS_TOKEN_SUCCESS"
export const VERIFICA_ACCESS_TOKEN_ERROR = "VERIFY_ACCESS_TOKEN_ERROR"

export const PUT_IMAGEN_PERFIL = "PUT_IMAGEN_PERFIL"
export const PUT_IMAGEN_PERFIL_SUCCESS = "PUT_IMAGEN_PERFIL_SUCCESS"
export const PUT_IMAGEN_PERFIL_ERROR = "PUT_IMAGEN_PERFIL_ERROR"

export const PUT_INFO_USUARIO = "PUT_INFO_USUARIO"
export const PUT_INFO_USUARIO_SUCCESS = "PUT_INFO_USUARIO_SUCCESS"
export const PUT_INFO_USUARIO_ERROR = "PUT_INFO_USUARIO_ERROR"

export const RECUPERACION_ENVIAR_CORREO = "RECUPERACION_ENVIAR_CORREO"
export const RECUPERACION_ENVIAR_CORREO_SUCCESS = "RECUPERACION_ENVIAR_CORREO_SUCCESS"
export const RECUPERACION_ENVIAR_CORREO_ERROR = "RECUPERACION_ENVIAR_CORREO_ERROR"

export const RECUPERACION_CAMBIAR_CONTRASENA = "RECUPERACION_CAMBIAR_CONTRASENA"
export const RECUPERACION_CAMBIAR_CONTRASENA_SUCCESS = "RECUPERACION_CAMBIAR_CONTRASENA_SUCCESS"
export const RECUPERACION_CAMBIAR_CONTRASENA_ERROR = "RECUPERACION_CAMBIAR_CONTRASENA_ERROR"

export const RESET_RECUPERACION = "RESET_RECUPERACION"

export const ELIMINAR_CUENTA = "ELIMINAR_CUENTA"
export const ELIMINAR_CUENTA_SUCCESS = "ELIMINAR_CUENTA_SUCCESS"
export const ELIMINAR_CUENTA_ERROR = "ELIMINAR_CUENTA_ERROR"

export const ES_ADMIN = "ES_ADMIN"
export const ES_ADMIN_SUCCESS = "ES_ADMIN_SUCCESS"
export const ES_ADMIN_ERROR = "ES_ADMIN_ERROR"

//Usuario
export const GET_RESERVACION_USUARIO = "GET_RESERVACION_USUARIO"
export const GET_RESERVACION_USUARIO_SUCCESS = "GET_RESERVACION_USUARIO_SUCCESS"
export const GET_RESERVACION_USUARIO_ERROR = "GET_RESERVACION_USUARIO_ERROR"

export const GET_RESERVACIONES_USUARIO = "GET_RESERVACIONES_USUARIO"
export const GET_RESERVACIONES_USUARIO_SUCCESS = "GET_RESERVACIONES_USUARIO_SUCCESS"
export const GET_RESERVACIONES_USUARIO_ERROR = "GET_RESERVACIONES_USUARIO_ERROR"

export const GET_RESERVACION_USUARIO_TEMPORAL = "GET_RESERVACION_USUARIO_TEMPORAL"
export const GET_RESERVACION_USUARIO_TEMPORAL_SUCCESS = "GET_RESERVACION_USUARIO_TEMPORAL_SUCCESS"
export const GET_RESERVACION_USUARIO_TEMPORAL_ERROR = "GET_RESERVACION_USUARIO_TEMPORAL_ERROR"

export const PUT_DATOS_RESERVACION = "PUT_DATOS_RESERVACION"
export const PUT_DATOS_RESERVACION_SUCCESS = "PUT_DATOS_RESERVACION_SUCCESS"
export const PUT_DATOS_RESERVACION_ERROR = "PUT_DATOS_RESERVACION_ERROR"

export const ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES = "ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES"
export const ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES_SUCCESS = "ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES_SUCCESS"
export const ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES_ERROR = "ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES_ERROR"

//Eventos
export const POST_EVENTO_BUSCAR = "POST_EVENTO_BUSCAR"
export const POST_EVENTO_BUSCAR_SUCCESS = "POST_EVENTO_BUSCAR_SUCCESS"
export const POST_EVENTO_BUSCAR_ERROR = "POST_EVENTO_BUSCAR_ERROR"

export const POST_EVENTO_COTIZACION_CABANA = "POST_EVENTO_COTIZACION_CABANA"
export const POST_EVENTO_COTIZACION_CABANA_SUCCESS = "POST_EVENTO_COTIZACION_CABANA_SUCCESS"
export const POST_EVENTO_COTIZACION_CABANA_ERROR = "POST_EVENTO_COTIZACION_CABANA_ERROR"

export const POST_EVENTO_LISTO_RESERVAR = "POST_EVENTO_LISTO_RESERVAR"
export const POST_EVENTO_LISTO_RESERVAR_SUCCESS = "POST_EVENTO_LISTO_RESERVAR_SUCCESS"
export const POST_EVENTO_LISTO_RESERVAR_ERROR = "POST_EVENTO_LISTO_RESERVAR_ERROR"

export const PUT_EVENTO_ACTUAL_BUSCAR = "PUT_EVENTO_ACTUAL_BUSCAR"
export const PUT_EVENTO_ACTUAL_BUSCAR_SUCCESS = "PUT_EVENTO_ACTUAL_BUSCAR_SUCCESS"
export const PUT_EVENTO_ACTUAL_BUSCAR_ERROR = "PUT_EVENTO_ACTUAL_BUSCAR_ERROR"

export const PUT_EVENTO_ACTUAL_COTIZACION_CABANA = "PUT_EVENTO_ACTUAL_COTIZACION_CABANA"
export const PUT_EVENTO_ACTUAL_COTIZACION_CABANA_SUCCESS = "PUT_EVENTO_ACTUAL_COTIZACION_CABANA_SUCCESS"
export const PUT_EVENTO_ACTUAL_COTIZACION_CABANA_ERROR = "PUT_EVENTO_ACTUAL_COTIZACION_CABANA_ERROR"

export const BORRAR_EVENTO_ACTUAL_BUSCAR = "BORRAR_EVENTO_ACTUAL_BUSCAR"
export const BORRAR_EVENTO_ACTUAL_COTIZACION_CABANA = "BORRAR_EVENTO_ACTUAL_COTIZACION_CABANA"
