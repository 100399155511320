import React, { Fragment, useState } from "react";
import Modal from "../Modal/Modal";
import Galeria from "../Galeria/Galeria";
import * as CaracteristicasCabanas from "../../consts/CaracteristicasCabanas";
import * as GeneralSVG from "../../assets/SVG/ExportadorGeneralSVG";
import * as BoostrapIcons from "react-icons/bs";
import "./MasDetalles.css";

const MasDetalles = ({ cabana }) => {
  const [modal, setModal] = useState(false);
  const objetoCabana = Object.assign(
    {},
    CaracteristicasCabanas.datos[`Cabana_${cabana}`]
  );
  const constructorDeCaracteristica = (
    caracteristica,
    svgAsociado,
    prefijo,
    sufijo = null
  ) => {
    if (objetoCabana[caracteristica] && objetoCabana[caracteristica] > 0) {
      let array = [];
      for (let index = 0; index < objetoCabana[caracteristica]; index++) {
        array[index] = (
          <img key={index} src={GeneralSVG[svgAsociado]} alt={svgAsociado} />
        );
      }
      return (
        <div className="caracteristica-mas-detalles">
          <p>
            {objetoCabana[caracteristica]} {prefijo}
            {objetoCabana[caracteristica] > 1 ? "s" : ""} {sufijo}
          </p>
          <div className="div-iconos-mas-detalles">{array}</div>
        </div>
      );
    }
  };
  return (
    <Fragment>
      <button id="botonMasDetalles" onClick={() => setModal(true)}>
        Más detalles
      </button>
      <Modal visibilidad={modal} setModal={setModal}>
        <div className="modal-mas-detalles">
          <div className="tituloMasdetalles">
            <h1>Cabaña {objetoCabana.nombre}</h1>
            <BoostrapIcons.BsXCircle
              className="icono-cerrar-modal-mas-detalles"
              onClick={() => setModal(false)}
            />
          </div>
          <div className="div-caracteristicas-plano">
            <div className="div-galeria-caracteristicas">
              <div className="div-galeria-mas-detalles">
                <Galeria cabana={cabana} altura={250} />
              </div>
              {objetoCabana.videoVistas && (
                <div className="div-video-vistas">
                  <iframe
                    class="videoDeVistas"
                    src={objetoCabana.videoVistas}
                    title={`Vistas Cabaña ${objetoCabana.nombre}`}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              )}
              <div className="div-carateristicas-mas-detalles">
                <div className="caracteristica-mas-detalles">
                  <p>Capacidad máxima</p>
                  <span>{objetoCabana.capacidadMax}</span>
                  <p>huéspedes</p>
                </div>

                {constructorDeCaracteristica(
                  "camasKing",
                  "camaKing",
                  "Cama",
                  "king size"
                )}
                {constructorDeCaracteristica(
                  "camasQueen",
                  "camaQueen",
                  "Cama",
                  "queen size"
                )}
                {constructorDeCaracteristica(
                  "camasMatrimonial",
                  "camaMatrimonial",
                  "Cama",
                  "matrimonial"
                )}
                {constructorDeCaracteristica(
                  "camasIndividual",
                  "camaIndividual",
                  "Cama",
                  "individual"
                )}
                {constructorDeCaracteristica("literas", "litera", "Litera")}
                {constructorDeCaracteristica(
                  "sofaCama",
                  "sofa",
                  "Sofá",
                  "cama"
                )}
                {constructorDeCaracteristica("inodoros", "wc", "Inodoro")}
                {constructorDeCaracteristica("regaderas", "ducha", "Regadera")}
              </div>
            </div>
            {objetoCabana.plano && (
              <div className="div-plano">
                <img src={objetoCabana.plano} alt="Plano de la cabaña" />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default MasDetalles;
