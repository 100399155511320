import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./ModificarDatosDeUsuario.css";

//redux
import * as AuthActions from "../../redux/Actions/AuthActions";
import * as AuthSelectors from "../../redux/Selectors/AuthSelector";

const ModificarDatosDeUsuario = ({ cerrarModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => AuthSelectors.userInfo(state));
  const [nombre, setNombre] = useState(userInfo.nombre);
  const [apellidos, setApellidos] = useState(userInfo.apellido);
  const [fechaDeNacimiento, setFechaDeNacimiento] = useState(
    userInfo.fechaNacimiento
  );
  const [telefono, setTelefono] = useState(userInfo.telefono);
  const [email, setEmail] = useState(userInfo.email);
  const [password, setPassword] = useState(null);
  const enviarInfo = () => {
    if (document.getElementById("modificarDatosDeUsuario").checkValidity()) {
      dispatch(
        AuthActions.putInfoUsuario({
          nombre: nombre,
          apellido: apellidos,
          email: email,
          password: password,
          fechaNacimiento: fechaDeNacimiento,
          telefono: telefono,
          mismoEmail: userInfo.email === email ? true : false 
        })
      );
      if (userInfo.email !== email) {
        history.push("/");
        dispatch(AuthActions.logOut());
      }
      cerrarModal();
    } else {
      document.getElementById("modificarDatosDeUsuario").reportValidity();
    }
  };
  return (
    <form id="modificarDatosDeUsuario">
      <label>Nombre(s)</label>
      <input
        type="text"
        onChange={(event) => setNombre(event.target.value)}
        defaultValue={userInfo.nombre}
        required
      />
      <label>Apellidos</label>
      <input
        type="text"
        onChange={(event) => setApellidos(event.target.value)}
        defaultValue={userInfo.apellido}
        required
      />
      <label>Fecha de nacimiento</label>
      <input
        type="date"
        onChange={(event) => setFechaDeNacimiento(event.target.value)}
        defaultValue={userInfo.fechaNacimiento.toString().split("T")[0]}
        required
      />
      <label>Teléfono (opcional)</label>
      <input
        type="tel"
        onChange={(event) => setTelefono(event.target.value)}
        defaultValue={userInfo.telefono}
      />
      <label>Correo electrónico</label>
      <input
        type="email"
        onChange={(event) => setEmail(event.target.value.toLowerCase())}
        defaultValue={userInfo.email}
        required
      />
      <label>Nueva contraseña</label>
      <input
        type="password"
        minLength={8}
        onChange={(event) => setPassword(event.target.value)}
      />
      <button
        onClick={(e) => {
          e.preventDefault();
          enviarInfo();
        }}
      >
        Guardar
      </button>
    </form>
  );
};

export default ModificarDatosDeUsuario;
