import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./ReservacionTemporal.css";

//Componentes
import DatosReservacion from "../../Components/DatosReservacion/DatosReservacion";

//Animaciones
import * as AnimacionesPanelUsuario from "./Animaciones/Animaciones";

const ReservacionTemporal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="reservacionTemporal">
      <Helmet>
        <link rel="icon" href="../../logoSoloMini.png" />
        <link rel="apple-touch-icon" href="../../logoSoloMini.png" />
        <meta
          name="description"
          content={`Cima Calidez le ofrece hermosas cabañas en el bosque y uno de los mejores servicios de atención en todo Mazamitla`}
        />
        <title>Cima Calidez - Reservación sin usuario</title>
      </Helmet>
      <DatosReservacion
        animacionDeApertura={() =>
          AnimacionesPanelUsuario.EntraContenido(() => {})
        }
        animacionDeCierre={AnimacionesPanelUsuario.SaleContenido}
      />
    </div>
  );
};

export default ReservacionTemporal;
