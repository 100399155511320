export const getInstruccionSeguimiento = () => {
  return localStorage.getItem("instrucciones_seguimiento_cima_calidez");
};

export const setInstruccionSeguimiento = (instruccion) => {
  localStorage.setItem("instrucciones_seguimiento_cima_calidez", instruccion);
};

export const eraseInstruccionSeguimiento = () => {
  localStorage.removeItem("instrucciones_seguimiento_cima_calidez");
};

export const getIdSesion = () => {
  return sessionStorage.getItem("id_sesion_cima_calidez")
}

export const setIdSesion = (_id) => {
  return sessionStorage.setItem("id_sesion_cima_calidez", _id)
}
