import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../reservaciones_api/reservaciones_api";
import * as types from "../actionTypes";
import { getToken } from "../../services/tokenServices";
import Swal from "sweetalert2";

export function* postPagoMercadoPagoSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "POST",
      `mercado_pago/process_payment/`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.POST_PAGO_MERCADOPAGO_SUCCESS, resultados });
  } catch (error) {
    console.log(
      "Error al procesar pago con Mercado Pago: ",
      error.response.data
    );
    Swal.fire(
      "No pudimos procesar su pago",
      "Si esta intentando pagar con tarjeta, verifique que el tipo de tarjeta sea correcto, débito ó crédito, Visa, Master Card ó American Express. Si el problema persiste contactese con nosotros al teléfono 3334404120",
      "error"
    );
    yield put({ type: types.POST_PAGO_MERCADOPAGO_ERROR, payload });
  }
}

export function* getOpcionesPagoSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      `mercado_pago/metodos_pago/`,
      null,
      {
        "Content-Type": "application/json",
      }
    );
    yield put({ type: types.GET_OPCIONES_PAGO_SUCCESS, resultados });
  } catch (error) {
    console.log(
      "Error al solicitar opciones de pago Mercado Pago: ",
      error.response.data
    );
    yield put({ type: types.GET_OPCIONES_PAGO_ERROR, payload });
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(types.POST_PAGO_MERCADOPAGO, postPagoMercadoPagoSaga);
  yield takeLatest(types.GET_OPCIONES_PAGO, getOpcionesPagoSaga);
}
