import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./DatosReservacion.css";

//Redux
import * as UsuarioActions from "../../redux/Actions/UsuarioActions";
import * as UsuarioSelectors from "../../redux/Selectors/UsuarioSelector";
import * as AuthSelectors from "../../redux/Selectors/AuthSelector";

//Utils
import SQLtoJSDateParser from "../../util/SQLtoJSDateParser";
import fechaLarga from "../../util/fechaLarga";
import switchNombreDeCabana from "../../util/switchNombreDeCabana";

//Assets
import * as BoostrapsIcons from "react-icons/bs";
import { Fragment } from "react";

//Componentes
import EnviarCorreoClienteAgencia from "../EnviarCorreoClienteAgencia/EnviarCorreoClienteAgencia";
import Modal from "../Modal/Modal";

//Consts
import * as CaracteristicasCabanas from "../../consts/CaracteristicasCabanas";

const DatosReservacion = ({
  cerrarBarraLateral,
  animacionDeApertura,
  animacionDeCierre,
}) => {
  const { codigoDeReservacion, password } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const reservacionEnfocada = useSelector((state) =>
    UsuarioSelectors.reservacionEnfocada(state)
  );
  const refrescaReservacionEnfocada = useSelector((state) =>
    UsuarioSelectors.refrescaReservacionEnfocada(state)
  );
  const logged = useSelector((state) => AuthSelectors.logged(state));
  const userInfo = useSelector((state) => AuthSelectors.userInfo(state));
  const [esUsuario, setEsUsuario] = useState(
    !password && logged ? true : false
  );
  const [nombre, setNombre] = useState(null);
  const [email, setEmail] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [adultos, setAdultos] = useState(null);
  const [ninos, setNinos] = useState(null);
  const [bebes, setBebes] = useState(null);
  const [mascotas, setMascotas] = useState(null);
  const [editando, setEditando] = useState(false);
  const [modalCorreoClienteAgencia, setModalCorreoClienteAgencia] =
    useState(false);
  useEffect(() => {
    if (password) {
      dispatch(
        UsuarioActions.getReservacionUsuarioTemporal({
          codigoDeReservacion: codigoDeReservacion,
          password: password,
        })
      );
    } else {
      dispatch(
        UsuarioActions.getReservacionUsuario({
          codigoDeReservacion: codigoDeReservacion,
        })
      );
    }
  }, [dispatch, codigoDeReservacion, password]);
  useEffect(() => {
    if (reservacionEnfocada) {
      setNombre(reservacionEnfocada.Cliente);
      setEmail(reservacionEnfocada.email);
      setTelefono(reservacionEnfocada.Telefono);
      setAdultos(reservacionEnfocada.Adultos);
      setNinos(reservacionEnfocada.Ninos);
      setBebes(reservacionEnfocada.Bebes);
      setMascotas(reservacionEnfocada.Mascotas);
      setEditando(false);
    }
    if (reservacionEnfocada && animacionDeApertura) {
      animacionDeApertura();
    }
  }, [reservacionEnfocada, animacionDeApertura]);
  useEffect(() => {
    setEsUsuario(!password && logged ? true : false);
  }, [password, logged]);
  useEffect(() => {
    if (refrescaReservacionEnfocada) {
      if (password) {
        dispatch(
          UsuarioActions.getReservacionUsuarioTemporal({
            codigoDeReservacion: codigoDeReservacion,
            password: password,
          })
        );
      } else {
        dispatch(
          UsuarioActions.getReservacionUsuario({
            codigoDeReservacion: codigoDeReservacion,
          })
        );
      }
    }
  }, [dispatch, codigoDeReservacion, password, refrescaReservacionEnfocada]);
  if (reservacionEnfocada) {
    return (
      <div
        className="datosDeReservacion contenidoPanelUsuario"
        onClick={cerrarBarraLateral}
      >
        {esUsuario && (
          <div
            className="regresarAMisReservaciones"
            onClick={() =>
              animacionDeCierre
                ? animacionDeCierre(() => history.push("/panel_usuario"))
                : history.push("/panel_usuario")
            }
          >
            <BoostrapsIcons.BsBackspace />
            <p>Regresar a mis reservaciones</p>
          </div>
        )}
        <h1 className="codigoYEstatusDatosReservacion">
          Reservación {reservacionEnfocada.Codigo_de_reservacion} -{" "}
          {reservacionEnfocada.Estatus}
        </h1>
        <div className="imagenDatosReservacion areasDatosReservacion">
          <div>
            {reservacionEnfocada.Cabana.split("-").map((cabana, index) => (
              <img
                key={index}
                src={
                  CaracteristicasCabanas.datos[`Cabana_${cabana}`].fotoPortada
                }
                alt={`Imagen de cabaña ${cabana}`}
              />
            ))}
          </div>
          <h1>
            Cabaña{reservacionEnfocada.Cabana.split("-").length > 1 ? "s" : ""}{" "}
            {switchNombreDeCabana(reservacionEnfocada.Cabana)}
          </h1>
        </div>
        <div className="fechasDatosReservacion areasDatosReservacion">
          <h2>Fechas del viaje</h2>
          <h3>
            Entrada:{" "}
            {fechaLarga(
              SQLtoJSDateParser(reservacionEnfocada.Fecha_de_entrada)
            )}{" "}
            a las 4:00pm
          </h3>
          <h3>
            Salida:{" "}
            {fechaLarga(SQLtoJSDateParser(reservacionEnfocada.Fecha_de_salida))}{" "}
            a las 12:00pm
          </h3>
        </div>
        <div className="balanceDatosReservacion areasDatosReservacion">
          <h2>Estatus de pago</h2>
          <h3>Costo total: ${reservacionEnfocada.Costo_total} MXN</h3>
          <h3>
            Pago pendiente: $
            {reservacionEnfocada.Pago_pendiente
              ? reservacionEnfocada.Pago_pendiente
              : reservacionEnfocada.Costo_total}{" "}
            MXN
          </h3>
        </div>
        <div className="huespedesDatosReservacion areasDatosReservacion">
          <h2>Húespedes</h2>
          {reservacionEnfocada.Adultos > 0 && (
            <h3>
              {reservacionEnfocada.Adultos} Adulto
              {reservacionEnfocada.Adultos > 1 ? "s" : ""}
            </h3>
          )}
          {reservacionEnfocada.Ninos > 0 && (
            <h3>
              {reservacionEnfocada.Ninos} Menor
              {reservacionEnfocada.Ninos > 1 ? "es" : ""} de 10 años
            </h3>
          )}
          {reservacionEnfocada.Bebes > 0 && (
            <h3>
              {reservacionEnfocada.Bebes} Menor
              {reservacionEnfocada.Bebes > 1 ? "es" : ""} de 3 años
            </h3>
          )}
          {reservacionEnfocada.Mascotas > 0 && (
            <h3>
              {reservacionEnfocada.Mascotas} Mascota
              {reservacionEnfocada.Mascotas > 1 ? "s" : ""}
            </h3>
          )}
        </div>
        <div className="clienteDatosReservacion areasDatosReservacion">
          <h2>Datos del Cliente</h2>
          <h3>
            Nombre:{" "}
            {editando ? (
              <input
                defaultValue={reservacionEnfocada.Cliente}
                onChange={(event) => setNombre(event.target.value)}
              />
            ) : (
              <span>{reservacionEnfocada.Cliente}</span>
            )}
          </h3>
          <h3>
            Telefono:{" "}
            {editando ? (
              <input
                defaultValue={reservacionEnfocada.Telefono}
                onChange={(event) => setTelefono(event.target.value)}
              />
            ) : (
              <span>{reservacionEnfocada.Telefono}</span>
            )}
          </h3>
          {(!userInfo ||
            (userInfo && userInfo.rol !== "Agencia de viajes")) && (
            <h3>
              Email:{" "}
              {editando ? (
                <input
                  defaultValue={reservacionEnfocada.email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              ) : (
                <span>{reservacionEnfocada.email}</span>
              )}
            </h3>
          )}
          <div className="clienteDatosReservacionDivBoton">
            {esUsuario && !editando && reservacionEnfocada !== "Concluida" && (
              <button
                className="botonesDetallesReservacion"
                onClick={() => setEditando(true)}
              >
                Editar datos de cliente
              </button>
            )}
            {editando && (
              <Fragment>
                <button
                  className="botonesDetallesReservacion"
                  onClick={() => setEditando(false)}
                >
                  Cancelar
                </button>
                <button
                  className="botonesDetallesReservacion"
                  onClick={() =>
                    dispatch(
                      UsuarioActions.putDatosReservacion({
                        codigoDeReservacion:
                          reservacionEnfocada.Codigo_de_reservacion,
                        nombre: nombre,
                        telefono: telefono,
                        correoElectronico: email,
                        numeroDeAdultos: adultos,
                        numeroDeNinos: ninos,
                        numeroDeBebes: bebes,
                        numeroDeMascotas: mascotas,
                      })
                    )
                  }
                >
                  Guardar
                </button>
              </Fragment>
            )}
          </div>
        </div>
        <div className="archivosDatosReservacion areasDatosReservacion">
          {reservacionEnfocada.Enlace_a_orden_de_pago &&
            reservacionEnfocada.Estatus === "Pago pendiente" && (
              <button
                className="botonesDetallesReservacion"
                onClick={() => {
                  window.open(
                    reservacionEnfocada.Enlace_a_orden_de_pago,
                    "_blank"
                  );
                }}
              >
                Ver ticket de pago
              </button>
            )}
          {reservacionEnfocada.Metodo_de_pago === "oxxo" &&
            reservacionEnfocada.Estatus === "Pago pendiente" && (
              <button
                className="botonesDetallesReservacion"
                onClick={() => {
                  let a = document.createElement("a");
                  a.href = `mailto:cimacalidez@gmail.com?subject=Comprobante de pago reservacón ${codigoDeReservacion}&body=Adjunto comprobante de pago para reservación con código ${codigoDeReservacion}`;
                  a.click();
                }}
              >
                Enviar comprobante de pago por correo
              </button>
            )}
          {reservacionEnfocada.Metodo_de_pago === "oxxo" &&
            reservacionEnfocada.Estatus === "Pago pendiente" && (
              <button
                className="botonesDetallesReservacion"
                onClick={() => {
                  window.open(
                    `https://api.whatsapp.com/send/?phone=+523334404120&text=Envío comprobante de pago para reservación con código ${codigoDeReservacion}&app_absent=0`,
                    "_blank"
                  );
                }}
              >
                Enviar comprobante de pago por Whatsapp
              </button>
            )}
          {userInfo &&
            userInfo.rol === "Agencia de viajes" &&
            reservacionEnfocada &&
            reservacionEnfocada.Estatus === "Confirmada" && (
              <button
                className="botonesDetallesReservacion"
                onClick={() => {
                  setModalCorreoClienteAgencia(true);
                }}
              >
                Enviar correo con datos de la reservacion al cliente
              </button>
            )}
        </div>
        <Modal
          visibilidad={modalCorreoClienteAgencia}
          setModal={setModalCorreoClienteAgencia}
        >
          <EnviarCorreoClienteAgencia
            codigoDeReservacion={codigoDeReservacion}
            cerrarModal={() => setModalCorreoClienteAgencia(false)}
          />
        </Modal>
      </div>
    );
  } else {
    return <></>;
  }
};

export default DatosReservacion;
