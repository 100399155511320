import React, { useEffect } from "react";
import Swal from "sweetalert2";
import "./FechaCal.css";

//Redux
import * as ReservacionesActions from "../../redux/Actions/reservacionesActions";
import * as ReservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";
import { useDispatch, useSelector } from "react-redux";

//Util
import numeroDeDiasEntreFechas from "../../util/numeroDeDiasEntreFechas";
import nombreDiaDeLaSemana from "../../util/nombreDiaDeLaSemana";
import fechaLarga from "../../util/fechaLarga";

const FechaCal = ({
  fecha,
  fechasOcupadas,
  minimoNochesSemana,
  minimoNochesExcepciones,
  cargando,
}) => {
  const dispatch = useDispatch();
  const milisegundosDeUnDia = 86400000;
  const fechaDeEntrada = useSelector((state) =>
    ReservacionesSelectors.fechaDeEntrada(state)
  );
  const fechaDeSalida = useSelector((state) =>
    ReservacionesSelectors.fechaDeSalida(state)
  );
  const fechaDeSalidaMaxima = useSelector((state) =>
    ReservacionesSelectors.fechaDeSalidaMaxima(state)
  );
  const defineFechaMaximaDeSalida = () => {
    if (fechasOcupadas.length > 0) {
      let fechaOcupadaMasCercana = null;
      fechasOcupadas.forEach((fechaOcupadaMilisegundos) => {
        let fechaOcupada = new Date(fechaOcupadaMilisegundos)
        if (Date.parse(fechaOcupada) > Date.parse(fecha)) {
          if (
            fechaOcupadaMasCercana === null ||
            numeroDeDiasEntreFechas(fecha, fechaOcupada) <
              numeroDeDiasEntreFechas(fecha, fechaOcupadaMasCercana)
          ) {
            fechaOcupadaMasCercana = new Date(fechaOcupada);
          }
        }
      });
      dispatch(
        ReservacionesActions.setFechaDeSalidaMaxima(fechaOcupadaMasCercana)
      );
    }
  };
  const seleccionarFecha = () => {
    //Borro los resultados de busqueda cuando vuelvo a hacer clic en las fechas
    // Si ya hay fechas seleccionadas, al hacer clic estas se borran para empezar de nuevo
    if (fechaDeEntrada !== null && fechaDeSalida !== null) {
      dispatch(ReservacionesActions.setFechaDeEntrada(null));
      dispatch(ReservacionesActions.setFechaDeSalida(null));
      dispatch(ReservacionesActions.setFechaDeSalidaMaxima(null));
      //No puedo seleccionar las fechas ocupadas con respecto a la base de datos
    } else if (
      fechasOcupadas.some(
        (fechaOcupada) => fechaOcupada === Date.parse(fecha)
      ) === false
    ) {
      //Si aún no esta definida la fecha de entrada entonces se define
      if (
        fechaDeEntrada === null &&
        Date.parse(fecha) >= Date.now() - milisegundosDeUnDia
      ) {
        defineFechaMaximaDeSalida();
        dispatch(ReservacionesActions.setFechaDeEntrada(fecha));
      } else if (fechaDeEntrada !== null && fechaDeSalida === null) {
        //Si ya esta definida la fecha de entrada pero no la de salida y selecciono una fecha previa a la fecha de entrada, esta sera la nueva fecha de entrada
        if (
          Date.parse(fecha) < Date.parse(fechaDeEntrada) &&
          Date.parse(fecha) >= Date.now() - milisegundosDeUnDia
        ) {
          defineFechaMaximaDeSalida();
          dispatch(ReservacionesActions.setFechaDeEntrada(fecha));
          //Si selecciono la fecha de entrada ya definida previamente, esta se deseleccionará
        } else if (Date.parse(fecha) === Date.parse(fechaDeEntrada)) {
          dispatch(ReservacionesActions.setFechaDeEntrada(null));
          dispatch(ReservacionesActions.setFechaDeSalidaMaxima(null));
        } else {
          //Como fecha de salida solo puedo seleccionar fechas anteriores o iguales a la fecha ocupada mas cercana a la fecha de entrada
          if (Date.parse(fecha) <= Date.parse(fechaDeSalidaMaxima)) {
            dispatch(ReservacionesActions.setFechaDeSalida(fecha));
            document
              .getElementById("presentacionDeHuespedes")
              .scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    } else if (fechaDeSalidaMaxima) {
      //Puedo seleccionar como fecha de salida la fecha ocupada más cercana la fecha de entrada, un cliente sale y otro entra
      if (
        Date.parse(fecha) === Date.parse(fechaDeSalidaMaxima) &&
        fechaDeEntrada !== null &&
        fechaDeSalida === null
      ) {
        dispatch(ReservacionesActions.setFechaDeSalida(fecha));
        document
          .getElementById("presentacionDeHuespedes")
          .scrollIntoView({ behavior: "smooth" });
      }
    }
    //Si no existen fechas ocupadas posteriores a la fecha de entrada, puedo escoger cualquier fecha, menos la fecha de entrada
    if (!fechaDeSalidaMaxima) {
      if (
        fechaDeEntrada !== null &&
        fechaDeSalida === null &&
        Date.parse(fecha) > Date.parse(fechaDeEntrada)
      ) {
        dispatch(ReservacionesActions.setFechaDeSalida(fecha));
        document
          .getElementById("presentacionDeHuespedes")
          .scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  //Funcion para determinar el minimo de noches en funcion de la informacion de la base de datos
  const minimoDeNochesParaFechaEspecifica = (fecha) => {
    if (minimoNochesExcepciones) {
      let stringFecha = `${fecha.getFullYear()}-${
        fecha.getMonth() + 1 >= 10
          ? fecha.getMonth() + 1
          : "0" + (fecha.getMonth() + 1)
      }-${fecha.getDate() >= 10 ? fecha.getDate() : "0" + fecha.getDate()}`;
      return minimoNochesExcepciones[stringFecha]
        ? minimoNochesExcepciones[stringFecha]
        : minimoNochesSemana[nombreDiaDeLaSemana(fecha.getDay())];
    }
  };
  const clasePorFecha = () => {
    // Las primeras dos lineas generan la fecha actual con 0 horas, 0 minutos, 0 segundos, 0 milisegundos
    let fechaActual = new Date(Date.now());
    fechaActual.setHours(0, 0, 0, 0);
    let fechaReservada = false;
    if (
      fechasOcupadas.some((fechaOcupada) => fechaOcupada === Date.parse(fecha))
    ) {
      fechaReservada = true;
    }
    if (fechaDeSalidaMaxima) {
      if (Date.parse(fecha) > Date.parse(fechaDeSalidaMaxima)) {
        fechaReservada = true;
      }
      if (Date.parse(fecha) === Date.parse(fechaDeSalidaMaxima)) {
        fechaReservada = false;
      }
    }
    // Define si la fecha esta disponible para ser seleccionada como fecha de salida segun las restricciones
    if (fechaDeEntrada && !fechaDeSalida) {
      if (
        numeroDeDiasEntreFechas(fechaDeEntrada, fecha) > 0 &&
        numeroDeDiasEntreFechas(fechaDeEntrada, fecha) <
          minimoDeNochesParaFechaEspecifica(fechaDeEntrada)
      ) {
        return "fechaNoDisponibleParaSalida";
      }
    }
    if (Date.parse(fecha) < Date.parse(fechaActual)) {
      return "fechasPasadas";
    } else if (fechaReservada) {
      return "fechasReservadas";
    } else if (Date.parse(fecha) >= Date.parse(fechaActual)) {
      return Date.parse(fecha) >= Date.parse(fechaDeEntrada) &&
        Date.parse(fecha) <= Date.parse(fechaDeSalida)
        ? "fechasSeleccionadas"
        : "fechasNoSeleccionadas";
    }
  };
  const IdEntradaOSalida = () => {
    if (fechaDeEntrada && Date.parse(fecha) === Date.parse(fechaDeEntrada)) {
      return "fechaDeEntrada";
    } else if (
      fechaDeSalida &&
      Date.parse(fecha) === Date.parse(fechaDeSalida)
    ) {
      return "fechaDeSalida";
    }
  };
  //UseEffect para determinar si el usuario esta intentado seleccionar una fecha con restriccion de minimo de noches
  useEffect(() => {
    if (fechaDeEntrada && fechaDeSalida) {
      for (
        let index = 0;
        index < numeroDeDiasEntreFechas(fechaDeEntrada, fechaDeSalida);
        index++
      ) {
        let fechaEnCurso = new Date(
          Date.parse(fechaDeEntrada) + milisegundosDeUnDia * index
        );
        if (
          numeroDeDiasEntreFechas(fechaDeEntrada, fechaDeSalida) <
          minimoDeNochesParaFechaEspecifica(fechaEnCurso)
        ) {
          dispatch(ReservacionesActions.setFechaDeSalida(null));
          Swal.fire(
            "Hay una restricción de mínimo de noches para las fechas seleccionadas",
            `Para el ${fechaLarga(
              fechaEnCurso
            )} el mínimo de noches es de ${minimoDeNochesParaFechaEspecifica(
              fechaEnCurso
            )} y usted seleccionó ${numeroDeDiasEntreFechas(
              fechaDeEntrada,
              fechaDeSalida
            )} noche${
              numeroDeDiasEntreFechas(fechaDeEntrada, fechaDeSalida) > 1
                ? "s"
                : ""
            }`,
            "warning"
          );
        }
      }
    }
  }, [fechaDeEntrada, fechaDeSalida]);
  return (
    <div
      onClick={seleccionarFecha}
      className={cargando ? "fechasReservadas" : clasePorFecha()}
      id={IdEntradaOSalida()}
    >
      <p id="diaDelMes">{fecha.getDate()}</p>
    </div>
  );
};

export default FechaCal;
