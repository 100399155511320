import * as types from '../actionTypes';

export const postPagoMercadoPago = (payload) => {
    return {
        type: types.POST_PAGO_MERCADOPAGO,
        payload
    }
}

export const getOpcionesPago = (payload) => {
    return {
        type: types.GET_OPCIONES_PAGO,
        payload
    }
}

export const resetMercadoPagoEstatusDePago = (payload) => {
    return {
        type: types.RESET_MERCADOPAGO_ESTATUS_PAGO,
        payload
    }
}

export const resetClearSessionMercadopago = (payload) => {
    return {
        type: types.RESET_CLEAR_SESSION_MERCADOPAGO,
        payload
    }
}


