import React, { useEffect, Fragment } from "react";
import Logo from "../../assets/imagenes/logo.png";
import GeneralUrls from "../../consts/GeneralUrls";
import "./FeedbackPago.css";

//Redux
import { useSelector } from "react-redux";
import * as AuthSelectors from "../../redux/Selectors/AuthSelector";
import * as ReservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";

//services
import { getInstruccionSeguimiento } from "../../services/seguimientoServices";

const FeedbackPago = ({
  estatus,
  detalle,
  codigoDeReservacion,
  descripcionReservacion,
}) => {
  const logged = useSelector((state) => AuthSelectors.logged(state));
  const costoTotal = useSelector((state) =>
    ReservacionesSelectors.costoTotal(state)
  );
  const traductorDetalle = () => {
    switch (detalle) {
      case "Pago directo":
        return (
          <Fragment>
            <h3>Reservación creada con exito</h3>
          </Fragment>
        );
      case "accredited":
        return (
          <Fragment>
            <h3>
              Un correo ha sido enviado a su email con los detalles de la
              reservación
            </h3>
            <h3>Estamos contentos de poder atenderlo, nos vemos pronto.</h3>
          </Fragment>
        );
      case "pending_waiting_payment":
        return (
            <h3>
              Le hemos enviado un correo electronico con el enlace de a la orden
              de pago. Vaya al establecimiento seleccionado, una vez acreditado
              el pago se lo haremos saber por medio de un correo electrónico.
            </h3>
        );
      case "pending_contingency":
        return (
          <Fragment>
            <h3>
              Parece que es necesaria una revisión del pago por parte del equipo
              de Mercado Pago. No se preocupes, en menos de 2 días hábiles le
              avisaremos por e-mail si se acreditó.
            </h3>
            <h3>
              Si necesita ayuda, puede comunicarse con nostros al{" "}
              <a href="tel:+523334404120">teléfono: +52 333 440 4120</a>
            </h3>
          </Fragment>
        );
      case "pending_review_manual":
        return (
          <Fragment>
            <h3>
              Parece que es necesaria una revisión del pago por parte del equipo
              de Mercado Pago. No se preocupes, menos de 2 días hábiles le
              avisaremos por e-mail si se acreditó o si necesitamos más
              información.
            </h3>
            <h3>
              Si necesita ayuda, puede comunicarse con nostros al{" "}
              <a href="tel:+523334404120">teléfono: +52 333 440 4120</a>
            </h3>
          </Fragment>
        );
      case "cc_rejected_bad_filled_card_number":
        return <h3>Revise el número de tarjeta</h3>;
      case "cc_rejected_bad_filled_date":
        return <h3>Revise la fecha de vencimiento.</h3>;
      case "cc_rejected_bad_filled_other":
        return <h3>Revise los datos.</h3>;
      case "cc_rejected_bad_filled_security_code":
        return <h3>Revise el código de seguridad de la tarjeta.</h3>;
      case "cc_rejected_blacklist":
        return <h3>No pudimos procesar su pago.</h3>;
      case "cc_rejected_call_for_authorize":
        return <h3>Debe autorizar el pago ante su entidad emisora.</h3>;
      case "cc_rejected_card_disabled":
        return (
          <h3>
            Llama a su entidad emisora para activar tu tarjeta o usa otro medio
            de pago. El teléfono está al dorso de tu tarjeta.
          </h3>
        );
      case "cc_rejected_card_error":
        return <h3>No pudimos procesar su pago.</h3>;
      case "cc_rejected_duplicated_payment":
        return (
          <h3>
            Ya hizo un pago por ese valor. Si necesita volver a pagar use otra
            tarjeta u otro medio de pago.
          </h3>
        );
      case "cc_rejected_high_risk":
        return (
          <h3>
            Su pago fue rechazado. Elija otro de los medios de pago, le
            recomendamos con medios en efectivo.
          </h3>
        );
      case "cc_rejected_insufficient_amount":
        return <h3>Su tarjeta no tiene fondos suficientes.</h3>;
      case "cc_rejected_invalid_installments":
        return (
          <h3>La tarjeta no procesa pagos en las cuotas seleccionadas.</h3>
        );
      case "cc_rejected_max_attempts":
        return (
          <h3>
            Llego al límite de intentos permitidos. Elija otra tarjeta u otro
            medio de pago.
          </h3>
        );
      case "cc_rejected_other_reason":
        return (
          <h3>
            No pudimos procesar su pago, verifique que su tarjeta no este
            bloqueada desde su banca en línea.
          </h3>
        );
      default:
        return <h3>No pudimos procesar su pago.</h3>;
    }
  };
  const opcionesDeBotones = () => {
    return (
      <Fragment>
        <button
          className="botones-feedback"
          onClick={() => (window.location.href = GeneralUrls.landing_page)}
        >
          Regresar a pagina principal
        </button>
        <button
          className="botones-feedback"
          onClick={() => (window.location.href = GeneralUrls.reservaciones_app)}
        >
          Hacer otra reservación
        </button>
        {logged && (
          <Fragment>
            <button
              className="botones-feedback"
              onClick={() =>
                (window.location.href =
                  GeneralUrls.reservaciones_app +
                  `panel_usuario/reservacion/${codigoDeReservacion}`)
              }
            >
              Ver reservación
            </button>
          </Fragment>
        )}
      </Fragment>
    );
  };
  const traductorEstatus = () => {
    switch (estatus) {
      case "Pago directo":
        return (
          <div className="div-feedback-pago">
            <img src={Logo} alt="Cima Calidez" />
            {traductorDetalle()}
            {opcionesDeBotones()}
          </div>
        );
      case "approved":
        return (
          <div className="div-feedback-pago">
            <img src={Logo} alt="Cima Calidez" />
            <h2>Agradecemos su preferencia!</h2>
            {traductorDetalle()}
            {opcionesDeBotones()}
          </div>
        );
      case "in_process":
        return (
          <div className="div-feedback-pago">
            <img src={Logo} alt="Cima Calidez" />
            <h2>Agradecemos su prefrencia! Se esta procesando su pago</h2>
            {traductorDetalle()}
            {opcionesDeBotones()}
          </div>
        );
      case "pending":
        return (
          <div className="div-feedback-pago">
            <img src={Logo} alt="Cima Calidez" />
            <h2>Agradecemos su preferencia!</h2>
            {traductorDetalle()}
            {opcionesDeBotones()}
          </div>
        );
      case "rejected":
        return (
          <div className="div-feedback-pago">
            <img src={Logo} alt="Cima Calidez" />
            <h2>Hubo un problema</h2>
            {traductorDetalle()}
            <button
              className="botones-feedback"
              onClick={() => {
                window.location.href = GeneralUrls.reservaciones_app;
              }}
            >
              Regresar
            </button>
          </div>
        );
      default:
    }
  };
  useEffect(() => {
    if (getInstruccionSeguimiento() !== "no_seguir") {
      window.dataLayer.push({
        event: "purchase",
        estatus_venta: estatus,
        currency: "MXN",
        value: costoTotal,
        transaction_id: codigoDeReservacion,
        items: [{ item_name: descripcionReservacion }],
      });
    }
    window.onbeforeunload = function (e) {}; //Elimina los mensajes previos a cerrar ventana que se pudieron haber generado
  }, []);

  return <div className="feedback-pago">{traductorEstatus()}</div>;
};

export default FeedbackPago;
