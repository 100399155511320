import { get } from "lodash";

export const cabanasARentar = (state) =>
  get(state, "ReservacionesReducer.cabanasARentar");
export const fechaDeEntrada = (state) =>
  get(state, "ReservacionesReducer.fechaDeEntrada");
export const fechaDeSalida = (state) =>
  get(state, "ReservacionesReducer.fechaDeSalida");
export const fechaDeSalidaMaxima = (state) =>
  get(state, "ReservacionesReducer.fechaDeSalidaMaxima");
export const numeroDeHuespedes = (state) =>
  get(state, "ReservacionesReducer.numeroDeHuespedes");
export const costo = (state) => get(state, "ReservacionesReducer.costo");
export const costoTotal = (state) =>
  get(state, "ReservacionesReducer.costoTotal");
export const listoParaReservar = (state) =>
  get(state, "ReservacionesReducer.listoParaReservar");
export const reservacionRegistrada = (state) =>
  get(state, "ReservacionesReducer.reservacionRegistrada");
export const cargandoReservaciones = (state) =>
  get(state, "ReservacionesReducer.cargandoReservaciones");
export const seCreoReservacionPorAdmin = (state) =>
  get(state, "ReservacionesReducer.seCreoReservacionPorAdmin");
export const cupon = (state) => get(state, "ReservacionesReducer.cupon");
export const seCreoReservacionSinPago = (state) => get(state, "ReservacionesReducer.seCreoReservacionSinPago");
