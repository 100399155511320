import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import * as ReservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";
import { BsArrowRight } from "react-icons/bs";
import "./DesgloseDePrecio.css";

//utils
import sqlToJsDate from "../../util/SQLtoJSDateParser";
import fechaLarga from "../../util/fechaLarga";

const DesgloseDePrecio = ({ funcionCerrar }) => {
  const costo = useSelector((state) => ReservacionesSelectors.costo(state));
  const costoTotal = useSelector((state) =>
    ReservacionesSelectors.costoTotal(state)
  );
  const constructorPrecioPorFecha = (fecha, index) => {
    return (
      <tr key={index} className="tr-fecha-precio-desglose">
        <td className="td-fecha">{fechaLarga(sqlToJsDate(fecha.fecha))}:</td>
        <td className="td-precio">
          <p
            className={
              fecha.subTotalConDescuento || fecha.subTotalConDescuento === 0
                ? "subtotal-descuento"
                : ""
            }
          >
            ${fecha.subTotal.toFixed(2)}
          </p>
          {(fecha.subTotalConDescuento || fecha.subTotalConDescuento === 0) && (
            <Fragment>
              <BsArrowRight />
              <p className="subtotal-oferta">
                ${fecha.subTotalConDescuento.toFixed(2)}
              </p>
            </Fragment>
          )}
        </td>
      </tr>
    );
  };
  return (
    <div id="desglose-de-precio">
      <h3>Desglose de precio por fechas</h3>
      <div className="div-tabla-desglose-precio">
        <table>
          <tbody>
            {costo &&
              costo.desglose &&
              costo.desglose.map((fecha, index) =>
                constructorPrecioPorFecha(fecha, index)
              )}
          </tbody>
        </table>
      </div>
      <table className="table-resumen-desglose-precio">
        <tbody>
          {costo && costo.descuento && (
            <Fragment>
              <tr>
                <td className="td-resumen-desglose-descripcion">
                  <p>Costo original:</p>
                </td>
                <td className="td-resumen-desglose-valor">
                  <p>${costo.descuento.costoOriginal.toFixed(2)}</p>
                </td>
              </tr>
              <tr>
                <td className="td-resumen-desglose-descripcion">
                  <p>{costo.descuento.etiqueta}:</p>
                </td>
                <td className="td-resumen-desglose-valor">
                  <p>
                    -${(costo.descuento.costoOriginal - costoTotal).toFixed(2)}
                  </p>
                </td>
              </tr>
            </Fragment>
          )}
          <tr>
            <td className="td-resumen-desglose-descripcion">
              <p>Total (MXN):</p>
            </td>
            <td className="td-resumen-desglose-valor">
              <p>${costoTotal.toFixed(2)}</p>
            </td>
          </tr>
        </tbody>
      </table>
      {funcionCerrar && <button onClick={() => funcionCerrar()}>Cerrar</button>}
    </div>
  );
};

export default DesgloseDePrecio;
