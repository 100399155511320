import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./LoginYRegistro.css";

//redux
import * as AuthActions from "../../redux/Actions/AuthActions";
import * as AuthSelectors from "../../redux/Selectors/AuthSelector";

//assets
import * as GeneralSVG from "../../assets/SVG/ExportadorGeneralSVG";

//icons
import * as BootstrapIcons from "react-icons/bs";

//Animaciones
import * as AnimacionesGenerales from "../../AnimacionesGenerales/AnimacionesGenerales_exporter";

const LoginYRegsitro = ({ cerrarModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = useSelector((state) => AuthSelectors.userInfo(state));
  const logged = useSelector((state) => AuthSelectors.logged(state));
  const registrado = useSelector((state) => AuthSelectors.registrado(state));
  const [tab, setTab] = useState("Login");
  const [nombre, setNombre] = useState(null);
  const [apellidos, setApellidos] = useState(null);
  const [fechaDeNacimiento, setFechaDeNacimiento] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [imagenPerfil, setImagenPerfil] = useState(null);
  const enviarInfo = () => {
    if (tab === "Login") {
      if (document.getElementById("formLoginModal").checkValidity()) {
        dispatch(
          AuthActions.loginUsuarioRegistrado({
            email: email,
            password: password,
          })
        );
      } else {
        document.getElementById("formLoginModal").reportValidity();
      }
    } else if (tab === "Registro") {
      if (document.getElementById("formRegistroModal").checkValidity()) {
        let obj = {
          nombre: nombre,
          apellido: apellidos,
          email: email,
          password: password,
          fechaNacimiento: fechaDeNacimiento,
          telefono: telefono,
        };
        if (imagenPerfil) Object.assign(obj, { imagenPerfil: imagenPerfil });
        dispatch(AuthActions.registrarUsuario(obj));
      } else {
        document.getElementById("formRegistroModal").reportValidity();
      }
    }
  };
  useEffect(() => {
    if (registrado) {
      cerrarModal();
      dispatch(AuthActions.resetVerificarUsuario());
    }
  }, [registrado]);
  useEffect(() => {
  }, []);
  return (
    <div className={logged ? "modalMiCuenta" : "modalLoginRegistro"}>
      <button
        className="botonCerrarLoginYRegistro"
        onClick={() => cerrarModal()}
      >
        <BootstrapIcons.BsXCircle />
      </button>
      {!logged && (
        <div className="modalLoginRegistroTabs">
          <p
            className={
              tab === "Login" ? "modalLoginTabActivo" : "modalLoginTabInactivo"
            }
            onClick={() => setTab("Login")}
          >
            Iniciar Sesión
          </p>
          <p
            className={
              tab === "Registro"
                ? "modalLoginTabActivo"
                : "modalLoginTabInactivo"
            }
            onClick={() => setTab("Registro")}
          >
            Registrarme
          </p>
        </div>
      )}
      {(tab === "Login" || logged) && (
        <Fragment>
          <img
            className="imagenPerfilModalMiCuenta"
            src={
              userInfo && userInfo.imagenPerfil
                ? userInfo.imagenPerfil
                : GeneralSVG.Avatar_predeterminado
            }
            alt="imagen de perfil"
          />
          {!logged && (
            <form id="formLoginModal">
              <label>Correo electrónico</label>
              <input
                type="email"
                onChange={(event) => setEmail(event.target.value.toLowerCase())}
                required
              />
              <label>Contraseña</label>
              <input
                type="password"
                minLength={8}
                onChange={(event) => setPassword(event.target.value)}
                required
              />
              <p
                className="noRecuerdoPassword"
                onClick={() => {
                  cerrarModal();
                  AnimacionesGenerales.Closing(() =>
                    history.push("/recuperacion")
                  );
                }}
              >
                No recuerdo mi contraseña
              </p>
            </form>
          )}
          {logged && (
            <p className="saludoModalMiCuenta">
              Bienvenido <br /> {userInfo.nombre}
            </p>
          )}
        </Fragment>
      )}
      {tab === "Registro" && !logged && (
        <form id="formRegistroModal">
          <canvas
            id="canvasImagenPerfilRegistro"
            className="previewImagenPerfilLoginYRegistrarme"
            onClick={() =>
              document.getElementById("registroImagenUpload").click()
            }
            style={{
              backgroundImage: `url(${GeneralSVG.Avatar_predeterminado})`,
            }}
          />
          <p
            className="cambiarImagenDePerfil"
            onClick={() =>
              document.getElementById("registroImagenUpload").click()
            }
          >
            Cambiar imagen de perfil
          </p>
          <label>Nombre(s)</label>
          <input
            type="text"
            onChange={(event) => setNombre(event.target.value)}
            required
          />
          <label>Apellidos</label>
          <input
            type="text"
            onChange={(event) => setApellidos(event.target.value)}
            required
          />
          <label>Fecha de nacimiento</label>
          <input
            type="date"
            onChange={(event) => setFechaDeNacimiento(event.target.value)}
            required
          />
          <label>Teléfono (opcional)</label>
          <input
            type="tel"
            onChange={(event) => setTelefono(event.target.value)}
          />
          <label>Correo electrónico</label>
          <input
            type="email"
            onChange={(event) => setEmail(event.target.value.toLowerCase())}
            required
          />
          <label>Contraseña</label>
          <input
            type="password"
            minLength={8}
            onChange={(event) => setPassword(event.target.value)}
            required
          />
          <input
            style={{ display: "none" }}
            id="registroImagenUpload"
            type="file"
            accept=".jpeg, .png, .jpg"
            onChange={(event) => {
              let imagen = event.target.files[0];
              const blobURL = window.URL.createObjectURL(imagen);
              const img = new Image();
              img.src = blobURL;
              img.onload = function () {
                window.URL.revokeObjectURL(blobURL);
                const canvas = document.getElementById(
                  "canvasImagenPerfilRegistro"
                );
                const proporcion = Math.floor(this.width / 150);
                const ancho =
                  proporcion > 1 ? this.width / proporcion : this.width;
                const alto =
                  proporcion > 1 ? this.height / proporcion : this.height;
                canvas.width = ancho;
                canvas.height = alto;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, ancho, alto);
                let dataURL = canvas.toDataURL();
                setImagenPerfil(dataURL);
              };
            }}
          />
        </form>
      )}
      {logged ? (
        <div className="divBotonesMiCuenta">
          <button
            className="botonesMiCuenta"
            onClick={() => {
              cerrarModal();
              AnimacionesGenerales.Closing(() =>
                history.push("/panel_usuario")
              );
            }}
          >
            Panel de usuario
          </button>
          <button
            className="botonesMiCuenta botonMiCuentaCerrarSesion"
            onClick={() => dispatch(AuthActions.logOut())}
          >
            Cerrar sesión
          </button>
        </div>
      ) : (
        <button
          className="botonEnviarLoginYRegistro"
          onClick={() => enviarInfo()}
        >
          Enviar
        </button>
      )}
    </div>
  );
};

export default LoginYRegsitro;
