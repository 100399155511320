import anime from "animejs";

const NuevosResultadosCerrar = (callbackAfter = () => {}) => {
  let tiempo = 1000;
  let timeline = anime.timeline({
    duration: tiempo,
    easing: "linear",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: ".resultados",
      height: 0,
      opacity: 0,
      easing: "cubicBezier(.5,0,.5,1)",
      duration: tiempo,
    },
    0
  );
};

export default NuevosResultadosCerrar;
