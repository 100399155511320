import * as types from "../actionTypes";

export const modalLogin = (payload) => {
  return {
    type: types.MODAL_LOGIN,
    payload,
  };
};

export const loginUsuarioRegistrado = (payload) => {
  return {
    type: types.LOGIN_USUARIO_REGISTRADO,
    payload,
  };
};

export const loginUsuarioTemporal = (payload) => {
  return {
    type: types.LOGIN_USUARIO_TEMPORAL,
    payload,
  };
};

export const registrarUsuario = (payload) => {
  return {
    type: types.REGISTRAR_USUARIO,
    payload,
  };
};

export const verificarUsuario = (payload) => {
  return {
    type: types.VERIFICAR_USUARIO,
    payload,
  };
};

export const resetVerificarUsuario = (payload) => {
  return {
    type: types.RESET_VERIFICAR_USUARIO,
    payload,
  };
};

export const logOut = (payload) => {
  return {
    type: types.LOG_OUT,
    payload,
  };
};

export const verificaAccessToken = (payload) => {
  return {
    type: types.VERIFICA_ACCESS_TOKEN,
    payload,
  };
};

export const putImagenDePerfil = (payload) => {
  return {
    type: types.PUT_IMAGEN_PERFIL,
    payload,
  };
};

export const putInfoUsuario = (payload) => {
  return {
    type: types.PUT_INFO_USUARIO,
    payload,
  };
};

export const recuperacionEnviarCorreo = (payload) => {
  return { type: types.RECUPERACION_ENVIAR_CORREO, payload };
};

export const recuperacionCambiarContrasena = (payload) => {
  return { type: types.RECUPERACION_CAMBIAR_CONTRASENA, payload };
};

export const resetRecuperacion = (payload) => {
  return { type: types.RESET_RECUPERACION, payload };
};

export const eliminarCuenta = (payload) => {
  return { type: types.ELIMINAR_CUENTA, payload };
};

export const esAdmin = (payload) => {
  return { type: types.ES_ADMIN, payload };
};
