import React, { useEffect, useState, Fragment } from "react";
import Swal from "sweetalert2";
import GeneralUrls from "../../consts/GeneralUrls";
import "./MercadoPago.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as reservacionesActions from "../../redux/Actions/reservacionesActions";
import * as reservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";
import * as MercadoPagoActions from "../../redux/Actions/MercadoPagoActions";
import * as MercadoPagoSelectors from "../../redux/Selectors/MercadoPagoSelector";
import * as AuthActions from "../../redux/Actions/AuthActions";
import * as AuthSelectors from "../../redux/Selectors/AuthSelector";

//imagenes
import mercadopago from "../../assets/imagenes/mercadopago.png";
import {
  BsFillCreditCard2BackFill,
  BsFillCreditCardFill,
  BsBank2,
  BsCashCoin,
} from "react-icons/bs";

//util
import fechaLargaConHora from "../../util/fechaLargaConHora";

const tiposDePago = [
  {
    nombre: "Tarjeta de crédito",
    etiquetaMP: "credit_card",
    admitidos: ["visa", "master", "amex"],
    minimoDeDias: 0,
    icono: BsFillCreditCard2BackFill,
  },
  {
    nombre: "Tarjeta de débito",
    etiquetaMP: "debit_card",
    admitidos: ["debvisa", "debmaster"],
    minimoDeDias: 0,
    icono: BsFillCreditCardFill,
  },
  {
    nombre: "Depósito/Tranferencia",
    etiquetaMP: "atm",
    admitidos: ["bancomer", "serfin", "banamex"],
    minimoDeDias: 3,
    icono: BsBank2,
  },
  {
    nombre: "Efectivo",
    etiquetaMP: "ticket",
    admitidos: ["oxxo"],
    minimoDeDias: 5,
    icono: BsCashCoin,
  },
];

const MercadoPago = ({
  cantidad,
  descripcion,
  fechaDeEntrada,
  correoElectronico,
  nombre,
  apellido,
  codigoDeReservacion,
  codigoDeReservacionTemporal,
}) => {
  const dispatch = useDispatch();
  const estatusDePago = useSelector((state) =>
    MercadoPagoSelectors.estatusDePago(state)
  );
  const opcionesDePago = useSelector((state) =>
    MercadoPagoSelectors.opcionesDePago(state)
  );

  const cupon = useSelector((state) => reservacionesSelectors.cupon(state));
  const clearSessionMercadoPago = useSelector((state) =>
    MercadoPagoSelectors.clearSessionMercadoPago(state)
  );
  const logged = useSelector((state) => AuthSelectors.logged(state));
  const esAdmin = useSelector((state) => AuthSelectors.esAdmin(state));
  const [doSubmit, setDoSubmit] = useState(false);
  const [tipoDePago, setTipoDePago] = useState(null);
  const [idFormaDePago, setIdFormaDePago] = useState(null);
  const [tiempoAcreditacionEstimado, setTiempoAcreditacionEstimado] =
    useState(null);
  const [nombreTitular, setNombreTitular] = useState(null);
  const [apellidoTitular, setApellidoTitular] = useState(null);
  const [seUsoOtroNombre, setSeUsoOtroNombre] = useState(false);
  const [seUsoOtroApellido, setSeUsoOtroApellido] = useState(false);
  const [numeroTarjeta, setNumeroTarjeta] = useState(null);
  const [mes, setMes] = useState(null);
  const [anio, setAnio] = useState(null);
  const [ccv, setCcv] = useState(null);
  const [bancosDisponibles, setBancosDisponibles] = useState([]);
  const [banco, setBanco] = useState(null);
  const [cuotasDisponibles, setCuotasDisponibles] = useState([]);
  const [cuotas, setCuotas] = useState(null);
  const [diasHabilesHastaFechaDeEntrada, setDiasHabilesHastaFechaDeEntrada] =
    useState([]);
  const [fechaLimiteDePagoTicket, setFechaLimiteDePagoTicket] = useState();
  const [fechaExpiracionPagoTicket, setFechaExpiracionPagoTicket] = useState();
  const horaLimiteDePago = 20;

  useEffect(() => {
    let fechaHoyConTiempo = new Date(Date.now());
    let fechaHoySinTiempo = fechaHoyConTiempo;
    fechaHoySinTiempo.setHours(0, 0, 0, 0);
    fechaHoySinTiempo = Date.parse(fechaHoySinTiempo);
    let fechaDeEntradaConTiempo = new Date(fechaDeEntrada);
    let fechaDeEntradaSinTiempo = fechaDeEntradaConTiempo;
    fechaDeEntradaSinTiempo.setHours(0, 0, 0, 0);
    fechaDeEntradaSinTiempo = Date.parse(fechaDeEntradaSinTiempo);
    const milisegundosDeUnDia = 86400000;
    let array = [];
    for (
      let index = 0;
      fechaHoySinTiempo + milisegundosDeUnDia * index <=
      fechaDeEntradaSinTiempo;
      index++
    ) {
      array.push(new Date(fechaHoySinTiempo + milisegundosDeUnDia * index));
    }
    array = array.filter(
      (fecha) => fecha.getDay() !== 0 && fecha.getDay() !== 6
    );
    setDiasHabilesHastaFechaDeEntrada(array);
  }, []);

  useEffect(() => {
    // Configura tu clave pública
    if (process.env.REACT_APP_MERCADO_PAGO === "produccion") {
      window.Mercadopago.setPublishableKey(
        process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY_PRODUCTION
      );
    } else if (process.env.REACT_APP_MERCADO_PAGO === "desarrollo") {
      window.Mercadopago.setPublishableKey(
        process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY_DEVELOPMENT
      );
    }
    dispatch(MercadoPagoActions.getOpcionesPago());
    return function cleanup() {
      dispatch(MercadoPagoActions.resetMercadoPagoEstatusDePago());
    };
  }, [dispatch]);

  useEffect(() => {
    if (opcionesDePago === "error") {
      dispatch(MercadoPagoActions.getOpcionesPago());
    }
  }, [opcionesDePago]);

  const resetEstadoInicial = () => {
    setDoSubmit(false);
    setTipoDePago(null);
    setIdFormaDePago(null);
    setTiempoAcreditacionEstimado(null);
    setNumeroTarjeta(null);
    setMes(null);
    setAnio(null);
    setCcv(null);
    setBancosDisponibles([]);
    setBanco(null);
    setCuotasDisponibles([]);
    setCuotas(null);
    window.Mercadopago.clearSession();
  };

  useEffect(() => {
    if (clearSessionMercadoPago) {
      resetEstadoInicial();
      dispatch(MercadoPagoActions.resetClearSessionMercadopago());
    }
  }, [clearSessionMercadoPago, dispatch]);

  useEffect(() => {
    let fecha;
    if (diasHabilesHastaFechaDeEntrada.length >= 2) {
      fecha = diasHabilesHastaFechaDeEntrada[1];
      fecha.setHours(horaLimiteDePago, 0, 0, 0);
    }
    if (
      tipoDePago === "atm" &&
      diasHabilesHastaFechaDeEntrada &&
      diasHabilesHastaFechaDeEntrada.length >= 3
    ) {
      setFechaLimiteDePagoTicket(fecha);
      setFechaExpiracionPagoTicket(diasHabilesHastaFechaDeEntrada[2]);
    } else if (
      tipoDePago === "ticket" &&
      diasHabilesHastaFechaDeEntrada &&
      diasHabilesHastaFechaDeEntrada.length >= 5
    ) {
      setFechaLimiteDePagoTicket(fecha);
      setFechaExpiracionPagoTicket(diasHabilesHastaFechaDeEntrada[4]);
    } else {
      setFechaLimiteDePagoTicket(undefined);
      setFechaExpiracionPagoTicket(undefined);
    }
  }, [tipoDePago]);

  // Igualar nombre y apellidos de los datos del cliente si aun no se han modificado en los datos de la tarjeta
  useEffect(() => {
    if (nombre && !seUsoOtroNombre) {
      setNombreTitular(nombre);
    }
    if (apellido && !seUsoOtroApellido) {
      setApellidoTitular(apellido);
    }
  }, [nombre, apellido]);

  // Obtener banco emisor
  function getIssuers(paymentMethodId) {
    window.Mercadopago.getIssuers(paymentMethodId, setIssuers);
  }

  function setIssuers(status, response) {
    if (status === 200) {
      let issuerSelect = document.getElementById("issuer");
      setBancosDisponibles(
        response.map((issuer, index) => {
          if (index === 0) {
            setBanco(issuer.id);
          }
          return {
            text: issuer.name,
            value: issuer.id,
          };
        })
      );

      getInstallments(idFormaDePago, cantidad, issuerSelect.value);
    } else {
      alert(
        "Error de MercadoPago: No se pudieron definir los bancos emisores disponibles"
      );
      console.log(response);
      resetEstadoInicial();
    }
  }

  // Obtener cantidad de cuotas
  function getInstallments(paymentMethodId, transactionAmount, issuerId) {
    window.Mercadopago.getInstallments(
      {
        payment_method_id: paymentMethodId,
        amount: parseFloat(transactionAmount),
        issuer_id: parseInt(issuerId),
      },
      setInstallments
    );
  }

  function setInstallments(status, response) {
    if (status === 200) {
      setCuotasDisponibles(
        response[0].payer_costs.map((payerCost, index) => {
          if (index === 0) {
            setCuotas(payerCost.installments);
          }
          return {
            text: payerCost.recommended_message,
            value: payerCost.installments,
          };
        })
      );
    } else {
      alert(
        "Error de MercadoPago: No se pudieron definir las mensualidades disponibles"
      );
      console.log(response);
      resetEstadoInicial();
    }
  }

  //  Crea el token de la tarjeta
  function getCardToken() {
    if (!doSubmit) {
      let $form = document.getElementById("paymentForm");
      window.Mercadopago.createToken($form, setCardTokenAndPay);
      return false;
    }
  }

  function setCardTokenAndPay(status, response) {
    if (status === 200 || status === 201) {
      let form = document.getElementById("paymentForm");
      let card = document.createElement("input");
      card.setAttribute("name", "token");
      card.setAttribute("type", "hidden");
      card.setAttribute("value", response.id);
      form.appendChild(card);
      setDoSubmit(true);
      let postObj = {
        usuarioRegistrado: logged,
        codigoDeReservacion: codigoDeReservacion,
        codigoDeReservacionTemporal: codigoDeReservacionTemporal,
        correoElectronico: correoElectronico,
        tipoDePago: tipoDePago,
        mercadopago_object: {
          transaction_amount: Number(cantidad),
          token: response.id,
          description: descripcion,
          installments: Number(cuotas),
          statement_descriptor: "CIMACALIDEZ",
          payment_method_id: idFormaDePago,
          issuer_id: banco,
          payer: {
            email: correoElectronico,
            first_name: nombreTitular,
            last_name: apellidoTitular,
          },
          notification_url:
            process.env.NODE_ENV === "production"
              ? GeneralUrls.reservaciones_api + "mercado_pago/notificaciones"
              : "https://api.cimacalidez.mx/mercado_pago/notificaciones",
        },
      };
      if (cupon && cupon.Id) postObj.IdCupon = cupon.Id;
      dispatch(MercadoPagoActions.postPagoMercadoPago(postObj));
    } else {
      let mensajeError = response.cause
        .map((error) => {
          if (error.code === "316") {
            return "Nombre de titular no valido.";
          }
          if (error.code === "E301") {
            return "Número de tarjeta no valido.";
          }
          if (error.code === "326") {
            return "Fecha de expiración no valida.";
          }
          if (error.code === "E302") {
            return "Código de seguridad no valido.";
          }
          return "";
        })
        .join(" ");
      Swal.fire("Error en los datos proporcionados", mensajeError, "error");
    }
  }

  //Pagar con otros metodos
  function pagarOtrosMetodos() {
    if (document.getElementById("formDatosDelCliente").reportValidity()) {
      let postObj = {
        usuarioRegistrado: logged,
        codigoDeReservacion: codigoDeReservacion,
        codigoDeReservacionTemporal: codigoDeReservacionTemporal,
        correoElectronico: correoElectronico,
        tipoDePago: tipoDePago,
        fechaLimiteDePagoTicket: fechaLimiteDePagoTicket.toISOString(),
        horaLimiteDePago: horaLimiteDePago,
        mercadopago_object: {
          transaction_amount: Number(cantidad),
          description: descripcion,
          payment_method_id: idFormaDePago,
          payer: {
            email: correoElectronico,
            first_name: nombreTitular,
            last_name: apellidoTitular,
          },
          date_of_expiration: fechaLimiteDePagoTicket
            ? fechaExpiracionPagoTicket.toISOString()
            : undefined,
          notification_url:
            process.env.NODE_ENV === "production"
              ? GeneralUrls.reservaciones_api + "mercado_pago/notificaciones"
              : "https://api.cimacalidez.mx/mercado_pago/notificaciones",
        },
      };
      if (cupon && cupon.Id) postObj.IdCupon = cupon.Id;
      dispatch(MercadoPagoActions.postPagoMercadoPago(postObj));
    } else {
      //Se requiere hacer un reportValidity despues de un segundo ya que por medio del scroll se borra el mensaje de validez
      setTimeout(
        () => document.getElementById("formDatosDelCliente").reportValidity(),
        1000
      );
    }
  }

  //Crear reservacion como admin
  function crearReservacion() {
    let postObj = {
      codigoDeReservacion: codigoDeReservacion,
      codigoDeReservacionTemporal: codigoDeReservacionTemporal,
    };
    if (cupon && cupon.Id) postObj.IdCupon = cupon.Id;
    dispatch(reservacionesActions.crearReservacionComoAdmin(postObj));
  }

  useEffect(() => {
    if (
      tipoDePago &&
      !idFormaDePago &&
      document.getElementById("div-ids-formas-pago")
    ) {
      document
        .getElementById("div-ids-formas-pago")
        .scrollIntoView({ behavior: "smooth" });
    }
    if (idFormaDePago) {
      if (tipoDePago === "credit_card" || tipoDePago === "debit_card") {
        getIssuers(idFormaDePago);
        if (document.getElementById("paymentForm"))
          document
            .getElementById("paymentForm")
            .scrollIntoView({ behavior: "smooth" });
      } else if (tipoDePago === "atm" || tipoDePago === "ticket") {
        if (document.getElementById("div-orden-pago"))
          document
            .getElementById("div-orden-pago")
            .scrollIntoView({ behavior: "smooth" });
      } else if (tipoDePago === "Pago directo") {
        if (document.getElementById("div-orden-pago-admin"))
          document
            .getElementById("div-orden-pago-admin")
            .scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [tipoDePago, idFormaDePago]);

  useEffect(() => {
    if (banco && idFormaDePago) {
      getInstallments(idFormaDePago, cantidad, banco);
    }
  }, [banco, idFormaDePago]);

  useEffect(() => {
    if (estatusDePago) {
      if (
        estatusDePago &&
        estatusDePago.transaction_details &&
        estatusDePago.transaction_details.external_resource_url
      ) {
        var tab = window.open(
          estatusDePago.transaction_details.external_resource_url,
          "_blank"
        );
        if (tab) {
          tab.focus();
        }
      }
    }
  }, [estatusDePago]);

  useEffect(() => {
    if (Array.isArray(opcionesDePago)) {
      dispatch(AuthActions.esAdmin());
    }
  }, [opcionesDePago, dispatch]);

  const ContinuarOrdenDePago = () => {
    return (
      <div id="div-orden-pago" className="div-orden-pago">
        {fechaLimiteDePagoTicket && (
          <p>
            <span>Importante: </span>Al crear la orden de pago usted tiene hasta
            las {fechaLargaConHora(fechaLimiteDePagoTicket)} para realizar el
            pago
            {idFormaDePago === "oxxo"
              ? " y enviar una imagen del comprobante de pago al correo cimacalidez@gmail.com o por medio de Whatsapp al celular 3334404120"
              : ""}
            , de lo contrario la reservación será cancelada.{" "}
            {tiempoAcreditacionEstimado > 0 &&
              `Una vez hecho el
            pago, este puede demorar hasta ${tiempoAcreditacionEstimado} hora
            ${
              tiempoAcreditacionEstimado > 1 ? "s" : ""
            } en días hábiles para que
            sea acreditado.`}
          </p>
        )}
        <button onClick={pagarOtrosMetodos}>
          Crear orden de pago de ${cantidad}
        </button>
        <h4>El resto lo pagará el día de entrada</h4>
      </div>
    );
  };

  const CrearReservacionComoAdmin = () => {
    return (
      <div id="div-orden-pago-admin" className="div-orden-pago">
        <button onClick={crearReservacion}>Crear reservación</button>
      </div>
    );
  };

  return (
    <div id="div-mercado-pago" className="mercado-pago">
      <div className="pago-procesado-por-mercado-pago">
        <p>Pago procesado por</p>
        <img src={mercadopago} alt="Mercado Pago" />
      </div>
      <h3>Elija un Método de pago</h3>
      <div className="div-opciones-pago">
        <div>
          {tiposDePago
            .filter(
              (opcion) =>
                diasHabilesHastaFechaDeEntrada.length >= opcion.minimoDeDias
            )
            .map((opcion, key) => (
              <div
                key={key}
                className={`opcion-pago ${
                  tipoDePago === opcion.etiquetaMP
                    ? "opcion-de-pago-seleccionada"
                    : ""
                }`}
                onClick={() => {
                  setIdFormaDePago(null);
                  setTipoDePago(opcion.etiquetaMP);
                }}
              >
                <div>
                  <opcion.icono />
                  <label htmlFor={opcion.id}>{opcion.nombre}</label>
                </div>
              </div>
            ))}
        </div>
        {tipoDePago &&
          tipoDePago !== "Pago directo" &&
          Array.isArray(opcionesDePago) &&
          (opcionesDePago.filter(
            (opcion) =>
              opcion.payment_type_id === tipoDePago &&
              tiposDePago.some((tipo) => tipo.admitidos.includes(opcion.id)) &&
              cantidad <= opcion.max_allowed_amount &&
              cantidad >= opcion.min_allowed_amount &&
              opcion.status === "active"
          ).length > 0 ? (
            <h3>Elija una opción</h3>
          ) : (
            <h3>
              Lo sentimos, no hay opciones de pago, por favor elija otro método
              de pago
            </h3>
          ))}
        <div id="div-ids-formas-pago">
          {Array.isArray(opcionesDePago) &&
            opcionesDePago
              .filter(
                (opcion) =>
                  opcion.payment_type_id === tipoDePago &&
                  tiposDePago.some((tipo) =>
                    tipo.admitidos.includes(opcion.id)
                  ) &&
                  cantidad <= opcion.max_allowed_amount &&
                  cantidad >= opcion.min_allowed_amount &&
                  opcion.status === "active"
              )
              .map((opcion, key) => (
                <div
                  key={key}
                  className={`opcion-pago ${
                    idFormaDePago === opcion.id
                      ? "opcion-de-pago-seleccionada"
                      : ""
                  }`}
                  onClick={() => {
                    setIdFormaDePago(opcion.id);
                    setTiempoAcreditacionEstimado(
                      opcion.accreditation_time / 60
                    );
                  }}
                >
                  <div>
                    <label htmlFor={opcion.id}>{opcion.name}</label>
                    <img src={opcion.secure_thumbnail} alt={opcion.name} />
                  </div>
                </div>
              ))}
        </div>
        {esAdmin && (
          <Fragment>
            <h3>Opciones para Administrador</h3>
            <div>
              <div
                className={`opcion-pago ${
                  idFormaDePago === "directo"
                    ? "opcion-de-pago-seleccionada"
                    : ""
                }`}
                onClick={() => {
                  setTipoDePago("Pago directo");
                  setIdFormaDePago("directo");
                }}
              >
                <div>
                  <label htmlFor={"directo"}>{"Pago directo"}</label>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
      {(tipoDePago === "credit_card" || tipoDePago === "debit_card") &&
        idFormaDePago && (
          <form
            id="paymentForm"
            onSubmit={(event) => {
              event.preventDefault();
              if (
                document.getElementById("formDatosDelCliente").reportValidity()
              ) {
                getCardToken();
              } else {
                //Se requiere hacer un reportValidity despues de un segundo ya que por medio del scroll se borra el mensaje de validez
                setTimeout(
                  () =>
                    document
                      .getElementById("formDatosDelCliente")
                      .reportValidity(),
                  1000
                );
              }
            }}
          >
            <h3>Detalles de la tarjeta</h3>
            <div className="div-formulario-tarjeta">
              <div className="div-datos-formulario-tarjeta">
                <label htmlFor="nombre">Nombre</label>
                <input
                  id="nombre"
                  type="text"
                  value={nombreTitular}
                  onChange={(event) => {
                    setNombreTitular(event.target.value);
                    if (event.target.value && !seUsoOtroNombre) {
                      setSeUsoOtroNombre(true);
                    }
                  }}
                  required
                />
                <label htmlFor="apellido">Apellido</label>
                <input
                  id="apellido"
                  type="text"
                  value={apellidoTitular}
                  onChange={(event) => {
                    setApellidoTitular(event.target.value);
                    if (event.target.value && !seUsoOtroApellido) {
                      setSeUsoOtroApellido(true);
                    }
                  }}
                  required
                />
                <input
                  id="cardholderName"
                  data-checkout="cardholderName"
                  type="hidden"
                  value={nombreTitular + " " + apellidoTitular}
                />
              </div>
              <div className="div-datos-formulario-tarjeta">
                <label htmlFor="cardNumber">Número de la tarjeta</label>
                <input
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9\s]{13,19}"
                  maxLength="19"
                  id="cardNumber"
                  data-checkout="cardNumber"
                  onSelectstart={() => false}
                  onPaste={() => false}
                  onCopy={() => false}
                  onCut={() => false}
                  onDrag={() => false}
                  onDrop={() => false}
                  autoComplete="off"
                  value={numeroTarjeta}
                  onChange={(event) => setNumeroTarjeta(event.target.value)}
                  required
                />
              </div>
              <div className="div-mes-anio-tarjeta">
                <div className="div-datos-formulario-tarjeta">
                  <label htmlFor="cardExpirationMonth">Mes de vencimiento</label>
                  <input
                    className="mes-anio-tarjeta"
                    type="text"
                    placeholder="MM"
                    inputMode="numeric"
                    pattern="[0-9\s]{2}"
                    id="cardExpirationMonth"
                    data-checkout="cardExpirationMonth"
                    onSelectstart={() => false}
                    onPaste={() => false}
                    onCopy={() => false}
                    onCut={() => false}
                    onDrag={() => false}
                    onDrop={() => false}
                    autoComplete="off"
                    value={mes}
                    onChange={(event) => setMes(event.target.value)}
                    required
                  />
                </div>
                <div className="div-datos-formulario-tarjeta">
                  <label htmlFor="cardExpirationYear">Año de vencimiento</label>
                  <input
                    className="mes-anio-tarjeta"
                    type="text"
                    placeholder="AA"
                    inputMode="numeric"
                    pattern="[0-9\s]{2}"
                    id="cardExpirationYear"
                    data-checkout="cardExpirationYear"
                    onSelectstart={() => false}
                    onPaste={() => false}
                    onCopy={() => false}
                    onCut={() => false}
                    onDrag={() => false}
                    onDrop={() => false}
                    autoComplete="off"
                    value={anio}
                    onChange={(event) => setAnio(event.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="div-datos-formulario-tarjeta">
                <label htmlFor="securityCode">Código de seguridad</label>
                <input
                  id="securityCode"
                  data-checkout="securityCode"
                  type="text"
                  inputMode="numeric"
                  onSelectstart={() => false}
                  onPaste={() => false}
                  onCopy={() => false}
                  onCut={() => false}
                  onDrag={() => false}
                  onDrop={() => false}
                  autoComplete="off"
                  value={ccv}
                  onChange={(event) => setCcv(event.target.value)}
                  required
                />
              </div>
              <div id="issuerInput" className="div-datos-formulario-tarjeta">
                <label htmlFor="issuer">Banco emisor</label>
                <select
                  id="issuer"
                  name="issuer"
                  data-checkout="issuer"
                  value={banco}
                  onChange={(event) => setBanco(event.target.value)}
                >
                  {bancosDisponibles.map((opt, key) => (
                    <option key={key} value={opt.value}>
                      {opt.text}
                    </option>
                  ))}
                </select>
              </div>
              {tipoDePago === "credit_card" && (
                <div className="div-datos-formulario-tarjeta">
                  <label htmlFor="installments">Cuotas</label>
                  <select
                    type="text"
                    id="installments"
                    name="installments"
                    value={cuotas}
                    onChange={(event) => setCuotas(event.target.value)}
                  >
                    {cuotasDisponibles.map((opt, key) => (
                      <option key={key} value={opt.value}>
                        {opt.text}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div>
                <input
                  type="hidden"
                  name="transactionAmount"
                  id="transactionAmount"
                  value={cantidad}
                />
                <input
                  type="hidden"
                  name="paymentMethodId"
                  id="paymentMethodId"
                />
                <input
                  type="hidden"
                  name="description"
                  id="description"
                  value={descripcion}
                />
                <br />
                <button type="submit" className="boton-pagar-tarjeta">
                  Pagar ${cantidad}
                </button>
                <h4>El resto lo pagará el día de entrada</h4>
              </div>
            </div>
          </form>
        )}
      {(tipoDePago === "ticket" || tipoDePago === "atm") && idFormaDePago && (
        <ContinuarOrdenDePago />
      )}
      {tipoDePago === "Pago directo" && <CrearReservacionComoAdmin />}
    </div>
  );
};

export default MercadoPago;
