import React, { useState, useEffect } from "react";
import "./Modal.css";

//Animaciones
import * as AnimacionesGenerales from "../../AnimacionesGenerales/AnimacionesGenerales_exporter";

const Modal = ({ children, visibilidad, setModal, forzado }) => {
  const [visible, setVisible] = useState(visibilidad);
  if (!visible && visibilidad) {
    setVisible(true);
  } else if (visible && !visibilidad) {
    setTimeout(() => {
      setVisible(false);
    }, 450);
  }
  useEffect(() => {
    if (visibilidad) {
      AnimacionesGenerales.OcultarMenu();
    }
    return function cleanup() {
      AnimacionesGenerales.ColapsarMenu();
    };
  }, [visibilidad]);
  if (visible) {
    return (
      <div
        id={visibilidad ? "modal" : "cierra-modal"}
        // onLoad={() =>
        //   document.body.appendChild(document.getElementById("modal"))
        // }
        onClick={(event) => {
          if (event.target.id === "modal" && !forzado && setModal) {
            event.target.id = "cierra-modal";
            setTimeout(() => {
              setModal(false);
              setVisible(false);
            }, 450);
          }
        }}
      >
        {children}
      </div>
    );
  } else {
    return false;
  }
};

export default Modal;
