import { put, call, takeLatest } from "redux-saga/effects";
import { authCall } from "../reservaciones_api/reservaciones_api";
import * as types from "../actionTypes";
import Swal from "sweetalert2";
import { getToken } from "../../services/tokenServices";

export function* loginUsuarioRegistradoSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "POST",
      `api/users/login`,
      payload,
      {
        "Content-Type": "application/json",
      }
    );
    yield put({ type: types.LOGIN_USUARIO_REGISTRADO_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.LOGIN_USUARIO_REGISTRADO_ERROR, payload });
  }
}

export function* loginUsuarioTemporalSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "POST",
      `api/temporalUsers/login`,
      payload,
      {
        "Content-Type": "application/json",
      }
    );
    yield put({ type: types.LOGIN_USUARIO_TEMPORAL_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.LOGIN_USUARIO_TEMPORAL_ERROR, payload });
  }
}

export function* registrarUsuarioSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "POST",
      `api/users/signup`,
      payload,
      {
        "Content-Type": "application/json",
      }
    );
    yield put({ type: types.REGISTRAR_USUARIO_SUCCESS, resultados });
    Swal.fire(
      "Email enviado!",
      "Se ha enviado un email con las instrucciones de verificación a la dirección proporcionada",
      "success"
    );
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.REGISTRAR_USUARIO_ERROR, payload });
  }
}

export function* verificarUsuarioSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "PUT",
      `api/users/verificarUsuario`,
      payload,
      {
        "Content-Type": "application/json",
      }
    );
    yield put({ type: types.VERIFICAR_USUARIO_SUCCESS, resultados });
    Swal.fire(
      "Verificación exitosa!",
      "Bienvenido a su panel de usuario, aquí puede gestionar sus reservaciones",
      "success"
    );
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.VERIFICAR_USUARIO_ERROR, payload });
  }
}

export function* verificaAccessTokenSaga({ payload }) {
  try {
    const resultados = yield call(authCall, "GET", `api/auth/`, null, {
      "Content-Type": "application/json",
      "x-auth-token": getToken(),
    });
    yield put({ type: types.VERIFICA_ACCESS_TOKEN_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.VERIFICA_ACCESS_TOKEN_ERROR, payload });
  }
}

export function* putImagenDePerfilSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "PUT",
      "api/users/imagenPerfil",
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.PUT_IMAGEN_PERFIL_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.PUT_IMAGEN_PERFIL_ERROR, payload });
  }
}

export function* putInfoUsuarioSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "PUT",
      "api/users/infoUsuario",
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.PUT_INFO_USUARIO_SUCCESS,
      resultados,
    });
    if (!payload.mismoEmail)
      Swal.fire(
        "Email enviado!",
        "Se ha enviado un email a la dirección nueva proporcionada con las instrucciones de verificación, para poder ingresar a su cuenta por favor primero verifique su correo",
        "info"
      );
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.PUT_INFO_USUARIO_ERROR, payload });
  }
}

export function* recuperacionEnviarCorreoSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "GET",
      `api/users/recuperacion_envio_de_correo?email=${payload}`,
      null,
      {
        "Content-Type": "application/json",
      }
    );
    yield put({
      type: types.RECUPERACION_ENVIAR_CORREO_SUCCESS,
      resultados,
    });
    Swal.fire(
      "Correo de recuperacion enviado!",
      "Siga las instrucciones del correo que le hemos enviado para actualizar su contraseña",
      "success"
    );
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    console.log("Error al solicitar correo de recuperacion: ", error);
    yield put({ type: types.RECUPERACION_ENVIAR_CORREO_ERROR, payload });
  }
}

export function* recuperacionCambiarContrasenaSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "PUT",
      `api/users/recuperacion/cambio_de_contrasena`,
      payload,
      {
        "Content-Type": "application/json",
      }
    );
    yield put({
      type: types.RECUPERACION_CAMBIAR_CONTRASENA_SUCCESS,
      resultados,
    });
    Swal.fire("Contraseña actualizada exitosamente!", "", "success");
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    console.log("Error al solicitar cambiar contraseña: ", error);
    yield put({ type: types.RECUPERACION_CAMBIAR_CONTRASENA_ERROR, payload });
  }
}

export function* eliminarCuentaSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "DELETE",
      `api/users/eliminar_cuenta`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.ELIMINAR_CUENTA_SUCCESS,
      resultados,
    });
    Swal.fire("Cuenta eliminada", "", "success");
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    console.log("Error al intentar eliminar cuenta: ", error);
    yield put({ type: types.ELIMINAR_CUENTA_ERROR, payload });
  }
}

export function* esAdminSaga({ payload }) {
  try {
    const resultados = yield call(authCall, "GET", `api/auth/isAdmin`, null, {
      "Content-Type": "application/json",
      "x-auth-token": getToken(),
    });
    yield put({
      type: types.ES_ADMIN_SUCCESS,
      resultados,
    });
  } catch (error) {
    yield put({ type: types.ES_ADMIN_ERROR, payload });
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(types.LOGIN_USUARIO_REGISTRADO, loginUsuarioRegistradoSaga);
  yield takeLatest(types.LOGIN_USUARIO_TEMPORAL, loginUsuarioTemporalSaga);
  yield takeLatest(types.REGISTRAR_USUARIO, registrarUsuarioSaga);
  yield takeLatest(types.VERIFICAR_USUARIO, verificarUsuarioSaga);
  yield takeLatest(types.VERIFICA_ACCESS_TOKEN, verificaAccessTokenSaga);
  yield takeLatest(types.PUT_IMAGEN_PERFIL, putImagenDePerfilSaga);
  yield takeLatest(types.PUT_INFO_USUARIO, putInfoUsuarioSaga);
  yield takeLatest(
    types.RECUPERACION_ENVIAR_CORREO,
    recuperacionEnviarCorreoSaga
  );
  yield takeLatest(
    types.RECUPERACION_CAMBIAR_CONTRASENA,
    recuperacionCambiarContrasenaSaga
  );
  yield takeLatest(types.ELIMINAR_CUENTA, eliminarCuentaSaga);
  yield takeLatest(types.ES_ADMIN, esAdminSaga);
}
