import React from "react";
import "./Resultado.css";

//Componentes
import MasDetalles from "../MasDetalles/MasDetalles";
import Reservar from "../Reservar/Reservar";

//const
import * as CaracteristicasCabanas from "../../consts/CaracteristicasCabanas";

const Resultado = ({ cabana, precio, precioConDescuento }) => {
  return (
    <div className="resultados">
      <p>
        Cabaña {`${CaracteristicasCabanas.datos[`Cabana_${cabana}`].nombre}`}
      </p>
      <img
        className="portadasResultados"
        src={CaracteristicasCabanas.datos[`Cabana_${cabana}`].fotoPortada}
        alt="Portada de cabaña"
      />
      <p>
        Total:{" "}
        <span className={precioConDescuento ? "tachado" : ""}>${precio}</span>
      </p>
      {precioConDescuento && (
        <p>
          Oferta:{" "}
          <span className="precioConDescuento">${precioConDescuento}</span>
        </p>
      )}
      <Reservar visibilidad={true} cabanasARentar={[cabana]} />
      <MasDetalles cabana={cabana} />
    </div>
  );
};

export default Resultado;
