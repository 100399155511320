import React, { useState } from "react";
import * as BootstrapIcons from "react-icons/bs";
import * as CaracteristicasCabanas from "../../consts/CaracteristicasCabanas";
import "./Galeria.css";

const Galeria = ({ cabana, altura }) => {
  const maximoImagenes =
    CaracteristicasCabanas.datos[`Cabana_${cabana}`].longitud_galeria;
  const [imagenEnVista, setImagenEnVista] = useState(1);
  const [touchX, setTouchX] = useState();
  const cambioDeImagen = (direccion) => {
    if (direccion === "izquierda") {
      if (imagenEnVista > 1) {
        setImagenEnVista(imagenEnVista - 1);
      } else {
        setImagenEnVista(maximoImagenes);
      }
    } else if (direccion === "derecha") {
      if (imagenEnVista < maximoImagenes) {
        setImagenEnVista(imagenEnVista + 1);
      } else {
        setImagenEnVista(1);
      }
    }
  };
  const constructorImagenUrl = () =>
    `${
      CaracteristicasCabanas.datos[`Cabana_${cabana}`].rutaGaleria
    }/Miniaturas/${imagenEnVista}.jpg`;
  return (
    <div
      className="galeria"
      style={{
        backgroundImage: `url(${constructorImagenUrl()})`,
      }}
    >
      <div
        onTouchStart={(event) => setTouchX(event.touches[0].clientX)}
        onTouchEnd={(event) => {
          let direccion = event.changedTouches[0].clientX - touchX;
          if (direccion < 0) {
            cambioDeImagen("derecha");
          } else if (direccion > 0) {
            cambioDeImagen("izquierda");
          }
        }}
      >
        <button
          className="boton-galeria-izquierdo"
          onClick={() => cambioDeImagen("izquierda")}
        >
          <BootstrapIcons.BsChevronLeft />
        </button>
        <img
          className="imagen-en-vista-galeria"
          src={`${constructorImagenUrl()}`}
          alt="galeria de imaganes"
          // style={{ maxHeight: altura }}
        />
        <button
          className="boton-galeria-derecho"
          onClick={() => cambioDeImagen("derecha")}
        >
          <BootstrapIcons.BsChevronRight />
        </button>
      </div>
    </div>
  );
};

export default Galeria;
