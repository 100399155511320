import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./MisReservaciones.css";

//Redux
import * as UsuarioActions from "../../redux/Actions/UsuarioActions";
import * as UsuarioSelector from "../../redux/Selectors/UsuarioSelector";
import * as AuthSelector from "../../redux/Selectors/AuthSelector";

//Componentes
import TablaDinamica from "../TablaDinamica/TablaDinamica";

//Utils
import switchNombreDeCabana from "../../util/switchNombreDeCabana";

const MisReservaciones = ({
  cerrarBarraLateral,
  animacionDeApertura,
  animacionDeCierre,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const reservacionesPropias = useSelector((state) =>
    UsuarioSelector.reservacionesPropias(state)
  );
  const userInfo = useSelector((state) => AuthSelector.userInfo(state));
  useEffect(() => {
    if (animacionDeApertura) {
      animacionDeApertura();
    }
    dispatch(UsuarioActions.getReservacionesUsuario());
  }, []);
  const dataParser = (array) => {
    return array.map((datos) => {
      let obj = {};
      obj = Object.assign(obj, { Id: datos.Codigo_de_reservacion });
      if (userInfo && userInfo.rol === "Agencia de viajes") {
        obj = Object.assign(obj, { Cliente: datos.Cliente });
      }
      obj = Object.assign(obj, {
        Estatus: datos.Estatus,
        Cabaña: switchNombreDeCabana(datos.Cabana),
        Fecha_de_entrada: datos.Fecha_de_entrada,
        Fecha_de_salida: datos.Fecha_de_salida,
        Huespedes: `${
          datos.Adultos > 0
            ? datos.Adultos + " Adulto" + (datos.Adultos > 1 ? "s" : "")
            : ""
        }${
          datos.Ninos > 0
            ? ", " + datos.Ninos + " Niño" + (datos.Ninos > 1 ? "s" : "")
            : ""
        }${
          datos.Bebes > 0
            ? ", " + datos.Bebes + " Bebé" + (datos.Bebes > 1 ? "s" : "")
            : ""
        }${
          datos.Mascotas > 0
            ? ", " +
              datos.Mascotas +
              " Mascota" +
              (datos.Mascotas > 1 ? "s" : "")
            : ""
        }`,
      });
      return obj;
    });
  };
  return (
    <div
      className="misReservaciones contenidoPanelUsuario"
      onClick={cerrarBarraLateral}
    >
      {reservacionesPropias.length > 0 ? (
        <Fragment>
          <h3>Mis reservaciones</h3>
          <div className="tabla">
            <TablaDinamica
              datos={dataParser(reservacionesPropias)}
              filaSeleccionable={true}
              funcionSeleccionDeFila={(dato) => {
                animacionDeCierre
                  ? animacionDeCierre(() =>
                      history.push(`/panel_usuario/reservacion/${dato.Id}`)
                    )
                  : history.push(`/panel_usuario/reservacion/${dato.Id}`);
              }}
              tieneBuscador={userInfo && userInfo.rol === "Agencia de viajes"}
            />
          </div>
        </Fragment>
      ) : (
        <h2 className="sinReservaciones">
          No hay reservaciones disponibles para visualizar
        </h2>
      )}
    </div>
  );
};

export default MisReservaciones;
