import * as types from "../actionTypes";

export const resetReservacionesState = () => {
  return {
    type: types.RESET_RESERVACIONES_STATE,
  };
};

export const setCabanasARentar = (arrayDeCabanas) => {
  return {
    type: types.SET_CABANAS_RENTAR,
    arrayDeCabanas,
  };
};

export const setFechaDeEntrada = (fechaDeEntrada) => {
  return {
    type: types.SET_FECHA_ENTRADA,
    fechaDeEntrada,
  };
};

export const setFechaDeSalida = (fechaDeSalida) => {
  return {
    type: types.SET_FECHA_SALIDA,
    fechaDeSalida,
  };
};

export const setFechaDeSalidaMaxima = (fechaDeSalidaMaxima) => {
  return {
    type: types.SET_FECHA_SALIDA_MAXIMA,
    fechaDeSalidaMaxima,
  };
};

export const setNumeroDeHuespedes = (numeroDeHuespedes) => {
  return {
    type: types.SET_NUMERO_HUESPEDES,
    numeroDeHuespedes,
  };
};

export const setCostoTotal = (costoTotal) => {
  return {
    type: types.SET_COSTO_TOTAL,
    costoTotal,
  };
};

export const setListoParaReservar = (payload) => {
  return {
    type: types.SET_LISTO_RESERVAR,
    payload,
  };
};

export const getCosto = (payload) => {
  return {
    type: types.GET_COSTO,
    payload,
  };
};

export const verificarDisponibilidad = (payload) => {
  return {
    type: types.VERIFICAR_DISPONIBILIDAD,
    payload,
  };
};

export const postReservacionTemporal = (payload) => {
  return {
    type: types.POST_RESERVACION_TEMPORAL,
    payload,
  };
};

export const putReservacionTemporal = (payload) => {
  return {
    type: types.PUT_RESERVACION_TEMPORAL,
    payload,
  };
};

export const deleteReservacionTemporal = (payload) => {
  return {
    type: types.DELETE_RESERVACION_TEMPORAL,
    payload,
  };
};

export const crearReservacionComoAdmin = (payload) => {
  return {
    type: types.CREAR_RESERVACION_COMO_ADMIN,
    payload,
  };
};

export const getInfoCupon = (payload) => {
  return {
    type: types.GET_INFO_CUPON,
    payload,
  };
};

export const quitarCupon = (payload) => {
  return {
    type: types.QUITAR_CUPON,
    payload,
  };
};

export const putReservacionSinPago = (payload) => {
  return {
    type: types.PUT_RESERVACION_SIN_PAGO,
    payload,
  };
};
