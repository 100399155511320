import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "./CantidadDeHuespedes.css";
import * as ReservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";
import * as ReservacionesActions from "../../redux/Actions/reservacionesActions";

//const
import * as caracteristicasCabanas from "../../consts/CaracteristicasCabanas";

//icons
import * as AntDesignIcons from "react-icons/ai";

const CantidadDeHuespedes = () => {
  const { cabana } = useParams();
  const dispatch = useDispatch();
  const numeroDeHuespedes = useSelector((state) =>
    ReservacionesSelectors.numeroDeHuespedes(state)
  );
  const maximoHuespedes = cabana
    ? caracteristicasCabanas.datos[`Cabana_${cabana}`].capacidadMax
    : caracteristicasCabanas.cantidadMaximaDeHuespedes();
  const cambiarNumeroDeHuespedes = (tipo, cantidad, cambio) => {
    let huespedes = Object.assign({}, numeroDeHuespedes);
    switch (tipo) {
      case "Adultos":
        if (
          cantidad + huespedes.numeroDeNinos < maximoHuespedes &&
          cambio === "aumenta"
        ) {
          huespedes.numeroDeAdultos = cantidad + 1;
        } else if (cantidad > 1 && cambio === "disminuye") {
          huespedes.numeroDeAdultos = cantidad - 1;
        }
        break;
      case "Menores de 10 años":
        if (
          huespedes.numeroDeAdultos + cantidad < maximoHuespedes &&
          cambio === "aumenta"
        ) {
          huespedes.numeroDeNinos = cantidad + 1;
        } else if (cantidad > 0 && cambio === "disminuye") {
          huespedes.numeroDeNinos = cantidad - 1;
        }
        break;
      case "Menores de 3 años":
        if (
          cantidad < caracteristicasCabanas.cantidadMaximaDeBebes &&
          cambio === "aumenta"
        ) {
          huespedes.numeroDeBebes = cantidad + 1;
        } else if (cantidad > 0 && cambio === "disminuye") {
          huespedes.numeroDeBebes = cantidad - 1;
        }
        break;
      case "Mascotas":
        if (
          cantidad < caracteristicasCabanas.cantidadMaximaDeMascotas &&
          cambio === "aumenta"
        ) {
          huespedes.numeroDeMascotas = cantidad + 1;
        } else if (cantidad > 0 && cambio === "disminuye") {
          huespedes.numeroDeMascotas = cantidad - 1;
        }
        break;
      default:
    }
    dispatch(ReservacionesActions.setNumeroDeHuespedes(huespedes));
  };
  const renderizaOpciones = (tipo, cantidad) => {
    return (
      <tr className="tipoDeHuesped">
        <td id="nombreDeTipo">
          <p>{`${tipo}: `}</p>
        </td>
        <td>
          <button
            className="botonesHuespedes"
            onClick={() =>
              cambiarNumeroDeHuespedes(tipo, cantidad, "disminuye")
            }
          >
            <AntDesignIcons.AiOutlineMinusCircle />
          </button>
          <p className="cantidadHuespedes">{cantidad}</p>
          <button
            className="botonesHuespedes"
            onClick={() => cambiarNumeroDeHuespedes(tipo, cantidad, "aumenta")}
          >
            <AntDesignIcons.AiOutlinePlusCircle />
          </button>
        </td>
      </tr>
    );
  };
  return (
    <div id="presentacionDeHuespedes">
      <h1>Indique la cantidad de Huéspedes</h1>
      {cabana ? (
        <Fragment>
          {caracteristicasCabanas.datos[`Cabana_${cabana}`]
            .elCostoVariaSegunHuespedes && (
            <h5>El costo varía según la cantidad de huéspedes</h5>
          )}
          {!caracteristicasCabanas.datos[`Cabana_${cabana}`].admiteNinos &&
          !caracteristicasCabanas.datos[`Cabana_${cabana}`].admiteMascotas ? (
            <h2 className="avisoHuespedes">Esta cabaña no es apta para niños ni mascotas</h2>
          ) : caracteristicasCabanas.datos[`Cabana_${cabana}`].admiteNinos &&
            !caracteristicasCabanas.datos[`Cabana_${cabana}`].admiteMascotas ? (
            <h2 className="avisoHuespedes">Esta cabaña no es apta para niños</h2>
          ) : !caracteristicasCabanas.datos[`Cabana_${cabana}`].admiteNinos &&
            caracteristicasCabanas.datos[`Cabana_${cabana}`].admiteMascotas ? (
            <h2 className="avisoHuespedes">Esta cabaña no es apta para mascotas</h2>
          ) : (
            ""
          )}
        </Fragment>
      ) : (
        <h5>El costo varía según la cantidad de huéspedes</h5>
      )}

      <table>
        <tbody>
          {renderizaOpciones("Adultos", numeroDeHuespedes.numeroDeAdultos)}
          {(cabana === undefined ||
            caracteristicasCabanas.datos[`Cabana_${cabana}`].admiteNinos) && (
            <Fragment>
              {renderizaOpciones(
                "Menores de 10 años",
                numeroDeHuespedes.numeroDeNinos
              )}
              {renderizaOpciones(
                "Menores de 3 años",
                numeroDeHuespedes.numeroDeBebes
              )}
            </Fragment>
          )}
          {(cabana === undefined ||
            caracteristicasCabanas.datos[`Cabana_${cabana}`].admiteMascotas) &&
            renderizaOpciones("Mascotas", numeroDeHuespedes.numeroDeMascotas)}
        </tbody>
      </table>
    </div>
  );
};

export default CantidadDeHuespedes;
