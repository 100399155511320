import React from "react";
import "./ResultadoCombinado.css";

//Componentes
import MasDetalles from "../MasDetalles/MasDetalles";
import Reservar from "../Reservar/Reservar";

//const
import * as CaracteristicasCabanas from "../../consts/CaracteristicasCabanas";

const ResultadoCombinado = ({ combinacion, precio, precioConDescuento }) => {
  const renderCombinacion = (combination) => {
    let opcion = [];
    combination.forEach((cabana) => {
      opcion.push(
        <div className="cabanaDeOpcion" key={cabana}>
          <p>
            Cabaña{" "}
            {`${CaracteristicasCabanas.datos[`Cabana_${cabana}`].nombre}`}
          </p>
          <img
            className="portadasResultados"
            src={CaracteristicasCabanas.datos[`Cabana_${cabana}`].fotoPortada}
            alt={`Cabaña ${cabana}`}
          />
          <MasDetalles cabana={cabana} />
        </div>
      );
    });
    return opcion;
  };
  return (
    <div className="opcionesResultadosCombinados">
      <div className="cabanasDeOpcion">{renderCombinacion(combinacion)}</div>
      <p>
        Total:{" "}
        <span className={precioConDescuento ? "tachado" : ""}>${precio}</span>
      </p>
      {precioConDescuento && (
        <p>
          Oferta:{" "}
          <span className="precioConDescuento">${precioConDescuento}</span>
        </p>
      )}
      <Reservar visibilidad={true} cabanasARentar={combinacion} />
    </div>
  );
};

export default ResultadoCombinado;
