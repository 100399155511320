import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import ComparadorReducer from "./ComparadorReducer";
import ReservacionesReducer from "./reservacionesReducer";
import CabanaReducer from "./CabanaReducer";
import MercadoPagoReducer from "./MercadoPagoReducer";
import UsuarioReducer from "./UsuarioReducer";
import EventosReducer from "./EventosReducer";

const rootReducer = combineReducers({
  ComparadorReducer,
  ReservacionesReducer,
  CabanaReducer,
  MercadoPagoReducer,
  AuthReducer,
  UsuarioReducer,
  EventosReducer,
});

export default rootReducer;
