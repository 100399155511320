import React, { useState } from "react";
import "./EnviarCorreoClienteAgencia.css";

//Redux
import { useDispatch } from "react-redux";
import * as UsuarioActions from "../../redux/Actions/UsuarioActions";

const EnviarCorreoClienteAgencia = ({ codigoDeReservacion, cerrarModal }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  return (
    <form
      id="enviarCorreoClienteAgencia"
      onSubmit={(event) => {
        event.preventDefault();
        if (!event.target.checkValidity()) {
          event.target.reportValidity();
        } else {
          dispatch(
            UsuarioActions.enviarCorreodatosReservacionClienteAgenciaViajes({
              codigoDeReservacion: codigoDeReservacion,
              email: email,
            })
          );
          cerrarModal();
        }
      }}
    >
      <label>Ingrese el correo del cliente</label>
      <input
        type="email"
        required
        onChange={(event) => setEmail(event.target.value)}
      />
      <button>Enviar correo</button>
    </form>
  );
};

export default EnviarCorreoClienteAgencia;
