import anime from "animejs";

const AparecerFlecha = (callbackAfter = () => {}) => {
  let tiempo = 300;
  let timeline = anime.timeline({
    duration: tiempo,
    easing: "linear",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: ".flecha-animada-scroll",
      opacity: 1,
      zIndex: 8,
      duration: tiempo,
    },
    0
  );
};

export default AparecerFlecha;
