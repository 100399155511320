import anime from "animejs";

const ApuntarAbajo = (callbackAfter = () => {}) => {
  let tiempo = 500;
  let timeline = anime.timeline({
    duration: tiempo,
    direction: "alternate",
    loop: true,
    easing: "spring(1, 80, 10, 0)",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: ".flecha-animada-scroll",
      translateY: -20,
      duration: tiempo,
    },
    0
  );
};

export default ApuntarAbajo;
