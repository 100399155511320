import { get } from "lodash";

export const estatusDePago = (state) =>
  get(state, "MercadoPagoReducer.estatusDePago");
export const opcionesDePago = (state) =>
  get(state, "MercadoPagoReducer.opcionesDePago");
export const cargando = (state) => get(state, "MercadoPagoReducer.cargando");
export const cargandoMercadoPago = (state) =>
  get(state, "MercadoPagoReducer.cargandoMercadoPago");
export const clearSessionMercadoPago = (state) =>
  get(state, "MercadoPagoReducer.clearSessionMercadoPago");
