import * as types from "../actionTypes";

const initialState = {
  cabanasDisponibles: [],
  fechasSinDisponibilidad: [],
  costos: null,
  cargandoCalendario: false,
  minimoNochesSemana: null,
  minimoNochesExcepciones: null,
  cargandoComparador: false,
};

export default function ComparadorReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_FECHAS_SIN_DISPONIBILIDAD:
      return {
        ...state,
        cargandoCalendario: true,
        cargandoComparador: true,
      };
    case types.GET_FECHAS_SIN_DISPONIBILIDAD_SUCCESS:
      return {
        ...state,
        fechasSinDisponibilidad:
          action.resultados.data.fechasSinDisponibilidad.map((fecha) =>
            // Se realiza el parseo en Front, ya que back maneja otra hora base
            Date.parse(new Date(fecha.anio, fecha.mes, fecha.dia, 0, 0, 0, 0))
          ),
        minimoNochesSemana: action.resultados.data.minimoNochesSemana,
        minimoNochesExcepciones: action.resultados.data.minimoNochesExcepciones,
        cargandoCalendario: false,
        cargandoComparador: false,
      };
    case types.GET_FECHAS_SIN_DISPONIBILIDAD_ERROR:
      return {
        ...state,
        fechasSinDisponibilidad: [],
        minimoNochesSemana: null,
        minimoNochesExcepciones: null,
        cargandoComparador: false,
      };
    case types.GET_CABANAS_DISPONIBLES:
      return {
        ...state,
        cargandoComparador: true,
      };
    case types.GET_CABANAS_DISPONIBLES_SUCCESS:
      return {
        ...state,
        cabanasDisponibles:
          action.resultados.data.cabanasDisponibles.length > 0
            ? action.resultados.data.cabanasDisponibles
            : false,
        costos: action.resultados.data.costos,
        cargandoComparador: false,
      };
    case types.GET_CABANAS_DISPONIBLES_ERROR:
      return {
        ...state,
        cabanasDisponibles: [],
        costos: null,
        cargandoComparador: false,
      };
    case types.BORRAR_CABANAS_DISPONIBLES:
      return {
        ...state,
        cabanasDisponibles: [],
        costos: null,
      };
    default:
      return { ...state };
  }
}
