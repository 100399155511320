import anime from "animejs";

const Opening = (callbackAfter = () => {}) => {
  let tiempo = 1000;
  let timeline = anime.timeline({
    duration: tiempo,
    easing: "linear",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: ".vista",
      translateY: 50,
      duration: 0.2 * tiempo,
    },
    0
  );
  timeline.add(
    {
      targets: ".vista",
      translateY: 0,
      opacity: 1,
      duration: 0.8 * tiempo,
    },
    0.2 * tiempo
  );
};

export default Opening;
