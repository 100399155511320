import * as types from "../actionTypes";

export const getReservacionesUsuario = (payload) => {
  return {
    type: types.GET_RESERVACIONES_USUARIO,
    payload,
  };
};

export const getReservacionUsuario = (payload) => {
  return {
    type: types.GET_RESERVACION_USUARIO,
    payload,
  };
};

export const getReservacionUsuarioTemporal = (payload) => {
  return {
    type: types.GET_RESERVACION_USUARIO_TEMPORAL,
    payload,
  };
};

export const putDatosReservacion = (payload) => {
  return {
    type: types.PUT_DATOS_RESERVACION,
    payload,
  };
};

export const enviarCorreodatosReservacionClienteAgenciaViajes = (payload) => {
  return {
    type: types.ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES,
    payload,
  };
};
