import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import "./Buscar.css";
import iconoCargando from "../../assets/imagenes/cargando.svg";
import * as reservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";
import { cabanasDisponiblesComparador } from "../../redux/Selectors/ComparadorSelectors";
import { getCabanasDisponibles } from "../../redux/Actions/ComparadorActions";
import * as eventosActions from "../../redux/Actions/EventosActions";

// utils
import jsToSqlDate from "../../util/NodeToSQLDateParser";

//services
import { getInstruccionSeguimiento } from "../../services/seguimientoServices";

const Buscar = ({ setResultados, busquedaCombinada, setNuevaBusqueda }) => {
  const dispatch = useDispatch();
  const [cargando, setCargando] = useState(false);
  const fechaDeEntrada = useSelector((state) =>
    reservacionesSelectors.fechaDeEntrada(state)
  );
  const fechaDeSalida = useSelector((state) =>
    reservacionesSelectors.fechaDeSalida(state)
  );
  const numeroDeHuespedes = useSelector((state) =>
    reservacionesSelectors.numeroDeHuespedes(state)
  );
  const cabanasDisponibles = useSelector((state) =>
    cabanasDisponiblesComparador(state)
  );
  const buscar = () => {
    if (fechaDeEntrada !== null && fechaDeSalida !== null) {
      if (getInstruccionSeguimiento() !== "no_seguir") {
        // Evento buscar para Tag Manager
        window.dataLayer.push({
          event: "buscar",
          fecha_entrada: jsToSqlDate(fechaDeEntrada),
          fecha_salida: jsToSqlDate(fechaDeSalida),
          numero_huespedes:
            numeroDeHuespedes.numeroDeAdultos + numeroDeHuespedes.numeroDeNinos,
          termino_busqueda: `E:${jsToSqlDate(fechaDeEntrada)}/S:${jsToSqlDate(
            fechaDeSalida
          )}/H:${
            numeroDeHuespedes.numeroDeAdultos + numeroDeHuespedes.numeroDeNinos
          }`,
        });
        // Evento buscar para actividad-api
        dispatch(
          eventosActions.postEventoBuscar({
            fechaDeEntrada,
            fechaDeSalida,
            numeroDeAdultos: numeroDeHuespedes.numeroDeAdultos,
            numeroDeNinos: numeroDeHuespedes.numeroDeNinos,
            numeroDeBebes: numeroDeHuespedes.numeroDeBebes,
            numeroDeMascotas: numeroDeHuespedes.numeroDeMascotas,
          })
        );
      }
      setNuevaBusqueda(true);
      setCargando(true);
      dispatch(
        getCabanasDisponibles({
          fechaDeEntrada,
          fechaDeSalida,
          numeroDeAdultos: numeroDeHuespedes.numeroDeAdultos,
          numeroDeNinos: numeroDeHuespedes.numeroDeNinos,
          numeroDeBebes: numeroDeHuespedes.numeroDeBebes,
          numeroDeMascotas: numeroDeHuespedes.numeroDeMascotas,
        })
      );
    }
  };

  useEffect(() => {
    if (cargando && cabanasDisponibles.length > 0) {
      setCargando(false);
    } else if (cargando && !cabanasDisponibles) {
      setCargando(false);
      Swal.fire(
        "Sin disponibilidad",
        "Lo sentimos, no hay cabañas disponibles para el periodo seleccionado.",
        "warning"
      );
    }
  }, [cabanasDisponibles, cargando]);

  useEffect(() => {
    if (!cargando && cabanasDisponibles.length > 0) {
      setResultados(cabanasDisponibles);
      setNuevaBusqueda(false);
    }
  }, [cargando, cabanasDisponibles, setResultados, setNuevaBusqueda]);

  return (
    <button
      id={!cargando ? "botonBuscar" : "cargandoBotonBuscar"}
      onClick={() => buscar()}
    >
      {!cargando && "Buscar"}
      {cargando && (
        <div>
          <img
            id="iconoCargandoBotonBuscar"
            src={iconoCargando}
            alt="cargando"
          />
        </div>
      )}
    </button>
  );
};

export default Buscar;
