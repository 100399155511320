import React, { useState, useEffect, Fragment } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./PanelUsuario.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as AuthActions from "../../redux/Actions/AuthActions";
import * as AuthSelectors from "../../redux/Selectors/AuthSelector";

//Componentes
import MisReservaciones from "../../Components/MisReservaciones/MisReservaciones";
import DatosReservacion from "../../Components/DatosReservacion/DatosReservacion";
import Perfil from "../../Components/Perfil/Perfil";
import Modal from "../../Components/Modal/Modal";
import ModificarDatosDeUsuario from "../../Components/ModificarDatosDeUsuario/ModificarDatosDeUsuario";

//Animaciones
import * as AnimacionesPanelUsuario from "./Animaciones/Animaciones";

//Hooks
import useWindowSize from "../../Hooks/useWindowsSize";

//Services
import { getToken } from "../../services/tokenServices";

//Icons
import * as BootstrapIcons from "react-icons/bs";

const PanelUsuario = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();
  const [width, height] = useWindowSize();
  const [visibilidad, setVisibilidad] = useState(false);
  const [modalDatosDeUsuario, setModalDatosDeUsuario] = useState(false);
  const [modalElimnarCuenta, setModalElimnarCuenta] = useState(false);
  const [listoParaBorrarCuenta, setListoParaBorrarCuenta] = useState(false);
  const logged = useSelector((state) => AuthSelectors.logged(state));
  const modalLogin = useSelector((state) => AuthSelectors.modalLogin(state));
  const { codigoDeReservacion, idUsuario, passwordVerificacion } = useParams();
  useEffect(() => {
    window.onbeforeunload = function (e) {}; //Elimina los mensajes previos a cerrar ventana que se pudieron haber generado
  }, []);
  useEffect(() => {
    if (visibilidad && width > 1200) {
      AnimacionesPanelUsuario.EntraBarraLateral();
    } else if (visibilidad && width <= 1200) {
      AnimacionesPanelUsuario.SaleBarraLateral();
    }
  }, [visibilidad, width]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(
      () => {},
      1000
    );
  }, [location]);
  useEffect(() => {
    if (idUsuario && passwordVerificacion) {
      dispatch(
        AuthActions.verificarUsuario({
          userId: idUsuario,
          passwordVerificacion: passwordVerificacion,
        })
      );
    }
  }, [idUsuario, passwordVerificacion]);
  useEffect(() => {
    setVisibilidad(logged);
    if (!logged && !getToken()) {
      dispatch(AuthActions.modalLogin(!modalLogin));
      history.push("/");
    }
  }, [logged]);


  return (
    <div className="panelUsuario">
      <Helmet>
        <link rel="icon" href="../logoSoloMini.png" />
        <link rel="apple-touch-icon" href="../logoSoloMini.png" />
        <meta
          name="description"
          content={`Cima Calidez le ofrece hermosas cabañas en el bosque y uno de los mejores servicios de atención en todo Mazamitla`}
        />
        <title>Cima Calidez - Panel de Usuario</title>
      </Helmet>
      {visibilidad && (
        <Fragment>
          <button
            className="botonDesplegarPerfil"
            onClick={() => {
              if (width <= 1200) {
                AnimacionesPanelUsuario.EntraBarraLateral();
              }
            }}
          >
            <BootstrapIcons.BsFillPersonLinesFill />
          </button>
          <Perfil
            abrirModalModificar={() => setModalDatosDeUsuario(true)}
            abrirModalEliminarCuenta={() => setModalElimnarCuenta(true)}
          />
          {codigoDeReservacion ? (
            <DatosReservacion
              cerrarBarraLateral={() => {
                if (width <= 1200) {
                  AnimacionesPanelUsuario.SaleBarraLateral();
                }
              }}
              animacionDeApertura={AnimacionesPanelUsuario.EntraContenido}
              animacionDeCierre={AnimacionesPanelUsuario.SaleContenido}
            />
          ) : (
            <MisReservaciones
              cerrarBarraLateral={() => {
                if (width <= 1200) {
                  AnimacionesPanelUsuario.SaleBarraLateral();
                }
              }}
              animacionDeApertura={AnimacionesPanelUsuario.EntraContenido}
              animacionDeCierre={AnimacionesPanelUsuario.SaleContenido}
            />
          )}
        </Fragment>
      )}
      <Modal
        visibilidad={modalDatosDeUsuario}
        setModal={setModalDatosDeUsuario}
      >
        <ModificarDatosDeUsuario
          cerrarModal={() => setModalDatosDeUsuario(false)}
        />
      </Modal>
      <Modal visibilidad={modalElimnarCuenta} setModal={setModalElimnarCuenta}>
        <div className="modalEliminarCuenta">
          <h2>
            Para eliminar su cuenta por favor escriba <span>cimacalidez</span>
          </h2>
          <h4>
            Todos sus datos personales serán borrados de nuestra base de datos,
            sin embargo, las reservaciones que hayan hecho no serán eliminadas
          </h4>
          <input
            id="inputBorrarCuenta"
            type="text"
            onChange={(event) => {
              if (event.target.value === "cimacalidez") {
                setListoParaBorrarCuenta(true);
              } else {
                setListoParaBorrarCuenta(false);
              }
            }}
          />
          <button
            disabled={!listoParaBorrarCuenta}
            onClick={() =>
              AnimacionesPanelUsuario.Cierre(() =>
                dispatch(AuthActions.eliminarCuenta())
              )
            }
          >
            Borrar
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default PanelUsuario;
