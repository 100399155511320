import * as types from '../actionTypes';

export const getFechasSinDisponibilidad = (payload) => {
    return {
        type: types.GET_FECHAS_SIN_DISPONIBILIDAD,
        payload
    }
}

export const getCabanasDisponibles = (payload) => {
    return {
        type: types.GET_CABANAS_DISPONIBLES,
        payload
    }
}

export const borrarCabanasDisponibles = (payload) => {
    return {
        type: types.BORRAR_CABANAS_DISPONIBLES,
        payload
    }
}
