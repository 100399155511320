import React, { useEffect } from "react";
import "./FlechaAnimadaScroll.css";
import { ImArrowDown2 } from "react-icons/im";
import ApuntarAbajo from "./Animaciones/ApuntarAbajo";
import AparecerFlecha from "./Animaciones/AparecerFlecha";
import OcultarFlecha from "./Animaciones/OcultarFlecha";

/**
 * @param {{
 * elemento: ElementoDOM,
 * }}
 */

var observador = new IntersectionObserver(
  function (entradas) {
    if (entradas[0].isIntersecting) {
      OcultarFlecha(() => {});
    } else {
      AparecerFlecha(() => {});
    }
  },
  { threshold: [1] }
);

const FlechaAnimadaScroll = ({ elemento }) => {
  useEffect(() => {
    ApuntarAbajo(() => {});
  }, []);
  useEffect(() => {
    if (elemento) {
      observador.observe(elemento);
    }
  }, [elemento]);

  return <ImArrowDown2 className="flecha-animada-scroll" />;
};

export default FlechaAnimadaScroll;
