import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../reservaciones_api/reservaciones_api";
import * as types from "../actionTypes";
import Swal from "sweetalert2";
import { getToken } from "../../services/tokenServices";

export function* getReservacionesUsuarioSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      "reservaciones/reservaciones_de_usuario",
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.GET_RESERVACIONES_USUARIO_SUCCESS, resultados });
  } catch (error) {
    console.log(
      "Error al solicitar reservaciones de usuario: ",
      error.response.data
    );
    Swal.fire(
      "Hubo un error!",
      "No fue posible obtener las reservaciones del servidor",
      "error"
    );
    yield put({ type: types.GET_RESERVACIONES_USUARIO_ERROR, payload });
  }
}

export function* getReservacionUsuarioSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      `reservaciones/reservacion?codigoDeReservacion=${payload.codigoDeReservacion}`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.GET_RESERVACION_USUARIO_SUCCESS, resultados });
  } catch (error) {
    console.log("Error al solicitar la reservacion: ", error.response.data);
    Swal.fire(
      "Hubo un error!",
      "No fue posible obtener la reservacion del servidor",
      "error"
    );
    yield put({ type: types.GET_RESERVACION_USUARIO_ERROR, payload });
  }
}

export function* getReservacionUsuarioTemporalSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      `reservaciones/reservacion_usuario_temporal?codigoDeReservacion=${payload.codigoDeReservacion}&password=${payload.password}`,
      null,
      {
        "Content-Type": "application/json",
      }
    );
    yield put({
      type: types.GET_RESERVACION_USUARIO_TEMPORAL_SUCCESS,
      resultados,
    });
  } catch (error) {
    console.log(
      "Error al intentar solicitar reservacion de usuario temporal: ",
      error.response.data
    );
    Swal.fire(
      "Hubo un error!",
      "No fue posible obtener la reservacion del servidor",
      "error"
    );
    yield put({ type: types.GET_RESERVACION_USUARIO_TEMPORAL_ERROR, payload });
  }
}

export function* putDatosReservacionSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "PUT",
      "reservaciones/actualizar_por_usuario",
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.PUT_DATOS_RESERVACION_SUCCESS,
      resultados,
    });
  } catch (error) {
    console.log(
      "Error al intentar actualizar datos de reservacion: ",
      error.response.data
    );
    Swal.fire(
      "Hubo un error!",
      "No fue posible actualizar la reservación",
      "error"
    );
    yield put({ type: types.PUT_DATOS_RESERVACION_ERROR, payload });
  }
}

export function* enviarCorreodatosReservacionClienteAgenciaViajesSaga({
  payload,
}) {
  try {
    const resultados = yield call(
      apiCall,
      "POST",
      `reservaciones/enviar_datos_reservacion_a_cliente_de_agencia`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    Swal.fire(
      "Correo enviado",
      "Usted tambien puede verlo en la bandeja de entrada de su email",
      "success"
    );
    yield put({
      type: types.ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({
      type: types.ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES_ERROR,
      payload,
    });
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(
    types.GET_RESERVACIONES_USUARIO,
    getReservacionesUsuarioSaga
  );
  yield takeLatest(types.GET_RESERVACION_USUARIO, getReservacionUsuarioSaga);
  yield takeLatest(
    types.GET_RESERVACION_USUARIO_TEMPORAL,
    getReservacionUsuarioTemporalSaga
  );
  yield takeLatest(types.PUT_DATOS_RESERVACION, putDatosReservacionSaga);
  yield takeLatest(
    types.ENVIAR_CORREO_DATOS_RESERVACION_CLIENTE_AGENCIA_VIAJES,
    enviarCorreodatosReservacionClienteAgenciaViajesSaga
  );
}
