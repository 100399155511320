import * as types from "../actionTypes";

const initialState = {
  cabanasARentar: null,
  fechaDeEntrada: null,
  fechaDeSalida: null,
  fechaDeSalidaMaxima: null,
  costo: null,
  costoTotal: null,
  listoParaReservar: false,
  reservacionRegistrada: null,
  numeroDeHuespedes: {
    numeroDeAdultos: 2,
    numeroDeNinos: 0,
    numeroDeBebes: 0,
    numeroDeMascotas: 0,
  },
  cargandoReservaciones: false,
  seCreoReservacionPorAdmin: false,
  seCreoReservacionSinPago: false,
  cupon: null,
};

export default function reservacionesReducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_RESERVACIONES_STATE:
      return {
        ...state,
        cabanasARentar: null,
        fechaDeEntrada: null,
        fechaDeSalida: null,
        fechaDeSalidaMaxima: null,
        costo: null,
        costoTotal: null,
        listoParaReservar: false,
        reservacionRegistrada: null,
        numeroDeHuespedes: {
          numeroDeAdultos: 2,
          numeroDeNinos: 0,
          numeroDeBebes: 0,
          numeroDeMascotas: 0,
        },
        cargandoReservaciones: false,
        seCreoReservacionPorAdmin: false,
        seCreoReservacionSinPago: false,
        cupon: null,
      };
    case types.SET_CABANAS_RENTAR:
      return {
        ...state,
        cabanasARentar: action.arrayDeCabanas,
      };
    case types.SET_FECHA_ENTRADA:
      return {
        ...state,
        fechaDeEntrada: action.fechaDeEntrada,
      };
    case types.SET_FECHA_SALIDA:
      return {
        ...state,
        fechaDeSalida: action.fechaDeSalida,
      };
    case types.SET_FECHA_SALIDA_MAXIMA:
      return {
        ...state,
        fechaDeSalidaMaxima: action.fechaDeSalidaMaxima,
      };
    case types.SET_NUMERO_HUESPEDES:
      return {
        ...state,
        numeroDeHuespedes: action.numeroDeHuespedes,
      };
    case types.SET_COSTO_TOTAL:
      return {
        ...state,
        costoTotal: action.costoTotal,
      };
    case types.SET_LISTO_RESERVAR:
      return {
        ...state,
        listoParaReservar: action.payload,
      };
    case types.GET_COSTO:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.GET_COSTO_SUCCESS:
      return {
        ...state,
        costo: action.resultados.data,
        costoTotal: action.resultados.data.costoTotal,
        cargandoReservaciones: false,
      };
    case types.GET_COSTO_ERROR:
      return {
        ...state,
        costo: null,
        costoTotal: null,
        cargandoReservaciones: false,
        listoParaReservar: false,
      };
    case types.VERIFICAR_DISPONIBILIDAD:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.VERIFICAR_DISPONIBILIDAD_SUCCESS:
      return {
        ...state,
        listoParaReservar: true,
        cargandoReservaciones: false,
      };
    case types.VERIFICAR_DISPONIBILIDAD_ERROR:
      return {
        ...state,
        //Reset de estado
        cabanasARentar: null,
        fechaDeEntrada: null,
        fechaDeSalida: null,
        fechaDeSalidaMaxima: null,
        costo: null,
        costoTotal: null,
        listoParaReservar: false,
        reservacionRegistrada: null,
        numeroDeHuespedes: {
          numeroDeAdultos: 2,
          numeroDeNinos: 0,
          numeroDeBebes: 0,
          numeroDeMascotas: 0,
        },
        cargandoReservaciones: false,
        seCreoReservacionPorAdmin: false,
        seCreoReservacionSinPago: false,
        cupon: null,
      };
    case types.POST_RESERVACION_TEMPORAL_SUCCESS:
      return {
        ...state,
      };
    case types.POST_RESERVACION_TEMPORAL_ERROR:
      return {
        ...state,
      };
    case types.PUT_RESERVACION_TEMPORAL:
      return {
        ...state,
      };
    case types.PUT_RESERVACION_TEMPORAL_SUCCESS:
      return {
        ...state,
        reservacionRegistrada: true,
      };
    case types.PUT_RESERVACION_TEMPORAL_ERROR:
      return {
        ...state,
        reservacionRegistrada: false,
      };
    case types.DELETE_RESERVACION_TEMPORAL_SUCCESS:
      return {
        ...state,
      };
    case types.DELETE_RESERVACION_TEMPORAL_ERROR:
      return {
        ...state,
      };
    case types.CREAR_RESERVACION_COMO_ADMIN:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.CREAR_RESERVACION_COMO_ADMIN_SUCCESS:
      return {
        ...state,
        cargandoReservaciones: false,
        seCreoReservacionPorAdmin: true,
      };
    case types.CREAR_RESERVACION_COMO_ADMIN_ERROR:
      return {
        ...state,
        cargandoReservaciones: false,
      };
    case types.GET_INFO_CUPON:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.GET_INFO_CUPON_SUCCESS:
      return {
        ...state,
        cupon: action.resultados.data,
        cargandoReservaciones: false,
      };
    case types.GET_INFO_CUPON_ERROR:
      return {
        ...state,
        cupon: null,
        cargandoReservaciones: false,
      };
    case types.QUITAR_CUPON:
      return {
        ...state,
        cupon: null,
      };
    case types.PUT_RESERVACION_SIN_PAGO:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.PUT_RESERVACION_SIN_PAGO_SUCCESS:
      return {
        ...state,
        seCreoReservacionSinPago: true,
        cargandoReservaciones: false,
      };
    case types.PUT_RESERVACION_SIN_PAGO_ERROR:
      return {
        ...state,
        seCreoReservacionSinPago: false,
        cargandoReservaciones: false,
      };
    default:
      return { ...state };
  }
}
