import anime from "animejs";

const OcultarMenu = (callbackAfter = () => {}) => {
  let tiempo = 200;
  let timeline = anime.timeline({
    duration: tiempo,
    easing: "linear",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: "#menu",
      translateY: "calc(-100% - 65px)",
      duration: tiempo,
    },
    0
  );
};

export default OcultarMenu;
