import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../reservaciones_api/reservaciones_api";
import * as types from "../actionTypes";
import Swal from "sweetalert2";
import { getToken } from "../../services/tokenServices";

export function* getCostoSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      `costos?fechaDeEntrada=${payload.fechaDeEntrada}&fechaDeSalida=${
        payload.fechaDeSalida
      }&numeroDeAdultos=${payload.numeroDeAdultos}&numeroDeMascotas=${
        payload.numeroDeMascotas
      }&cabana=${payload.cabana}${
        payload.IdCupon ? `&IdCupon=${payload.IdCupon}` : ""
      }`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.GET_COSTO_SUCCESS, resultados });
  } catch (error) {
    console.log("Error al solicitar costos: ", error.response.data);
    Swal.fire(
      "Hubo un error!",
      "No fue posible determinar el costo, vuelva a intentar nuevamente",
      "error"
    );
    yield put({ type: types.GET_COSTO_ERROR, payload });
  }
}

export function* verificarDisponibilidadSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      `disponibilidad/verificador?fechaDeEntrada=${payload.fechaDeEntrada}&fechaDeSalida=${payload.fechaDeSalida}&cabanasARentar=${payload.cabanasARentar}`,
      null,
      {
        "Content-Type": "application/json",
      }
    );
    yield put({ type: types.VERIFICAR_DISPONIBILIDAD_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un inconveniente", error.response.data, "warning");
    yield put({ type: types.VERIFICAR_DISPONIBILIDAD_ERROR, payload });
  }
}

export function* postReservacionTemporalSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "POST",
      "reservaciones/temporal",
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.POST_RESERVACION_TEMPORAL_SUCCESS, resultados });
  } catch (error) {
    console.log("Error al registrar reservacion: ", error.response.data);
    yield put({ type: types.POST_RESERVACION_TEMPORAL_ERROR, payload });
  }
}

export function* putReservacionTemporalSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "PUT",
      "reservaciones/temporal",
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.PUT_RESERVACION_TEMPORAL_SUCCESS, resultados });
  } catch (error) {
    console.log(
      "Error al actualizar datos de reservacion: ",
      error.response.data
    );
    yield put({ type: types.PUT_RESERVACION_TEMPORAL_SUCCESS, payload });
  }
}

export function* deleteReservacionTemporalSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "DELETE",
      "reservaciones/temporal",
      payload,
      {
        "Content-Type": "application/json",
      }
    );
    yield put({ type: types.DELETE_RESERVACION_TEMPORAL_SUCCESS, resultados });
  } catch (error) {
    console.log("Error al eliminar reservacion: ", error.response.data);
    yield put({ type: types.DELETE_RESERVACION_TEMPORAL_ERROR, payload });
  }
}

export function* crearReservacionComoAdminSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "PUT",
      "reservaciones/crear_como_admin",
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.CREAR_RESERVACION_COMO_ADMIN_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.CREAR_RESERVACION_COMO_ADMIN_ERROR, payload });
  }
}

export function* getInfoCuponSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      `cupones/info_cupon?IdCupon=${payload}`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.GET_INFO_CUPON_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.GET_INFO_CUPON_ERROR, payload });
  }
}

export function* putReservacionSinPagoSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "PUT",
      "reservaciones/crear_reservacion_menor_100_pesos",
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.PUT_RESERVACION_SIN_PAGO_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.PUT_RESERVACION_SIN_PAGO_ERROR, payload });
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(types.GET_COSTO, getCostoSaga);
  yield takeLatest(types.VERIFICAR_DISPONIBILIDAD, verificarDisponibilidadSaga);
  yield takeLatest(
    types.POST_RESERVACION_TEMPORAL,
    postReservacionTemporalSaga
  );
  yield takeLatest(types.PUT_RESERVACION_TEMPORAL, putReservacionTemporalSaga);
  yield takeLatest(
    types.DELETE_RESERVACION_TEMPORAL,
    deleteReservacionTemporalSaga
  );
  yield takeLatest(
    types.CREAR_RESERVACION_COMO_ADMIN,
    crearReservacionComoAdminSaga
  );
  yield takeLatest(types.GET_INFO_CUPON, getInfoCuponSaga);
  yield takeLatest(types.PUT_RESERVACION_SIN_PAGO, putReservacionSinPagoSaga);
}
