import * as types from "../actionTypes";

const initialState = {
  estatusDePago: null,
  opcionesDePago: [],
  cargando: false,
  cargandoMercadoPago: false,
  clearSessionMercadoPago: false,
};

export default function MercadoPagoReducer(state = initialState, action) {
  switch (action.type) {
    case types.POST_PAGO_MERCADOPAGO:
      return {
        ...state,
        cargando: true,
        cargandoMercadoPago: true,
      };
    case types.POST_PAGO_MERCADOPAGO_SUCCESS:
      return {
        ...state,
        estatusDePago: action.resultados.data,
        cargando: false,
        cargandoMercadoPago: false,
      };
    case types.POST_PAGO_MERCADOPAGO_ERROR:
      return {
        ...state,
        cargando: false,
        cargandoMercadoPago: false,
        clearSessionMercadoPago: true,
      };
    case types.GET_OPCIONES_PAGO:
      return {
        ...state,
        cargando: true,
        cargandoMercadoPago: true,
      };
    case types.GET_OPCIONES_PAGO_SUCCESS:
      return {
        ...state,
        opcionesDePago: action.resultados.data,
        cargando: false,
        cargandoMercadoPago: false,
      };
    case types.GET_OPCIONES_PAGO_ERROR:
      return {
        ...state,
        opcionesDePago: "error",
        cargando: false,
        cargandoMercadoPago: false,
      };
    case types.RESET_MERCADOPAGO_ESTATUS_PAGO:
      return {
        ...state,
        estatusDePago: null,
      };
    case types.RESET_CLEAR_SESSION_MERCADOPAGO:
      return {
        ...state,
        clearSessionMercadoPago: false,
      };
    default:
      return { ...state };
  }
}
