import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as reservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";
import * as reservacionesActions from "../../redux/Actions/reservacionesActions";
import "./Reservar.css";

const Reservar = ({ visibilidad, cabanasARentar }) => {
  const dispatch = useDispatch();
  const fechaDeEntrada = useSelector((state) =>
    reservacionesSelectors.fechaDeEntrada(state)
  );
  const fechaDeSalida = useSelector((state) =>
    reservacionesSelectors.fechaDeSalida(state)
  );
  const verificar = () => {
    dispatch(reservacionesActions.setCabanasARentar(cabanasARentar));
    dispatch(
      reservacionesActions.verificarDisponibilidad({
        fechaDeEntrada: fechaDeEntrada,
        fechaDeSalida: fechaDeSalida,
        cabanasARentar: cabanasARentar,
      })
    );
  };
  return (
    <button
      id={visibilidad ? "botonReservar" : "botonReservarNoVisible"}
      onClick={verificar}
    >
      Reservar
    </button>
  );
};

export default Reservar;
