import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ListaDeResultados.css";
import Resultado from "../Resultado/Resultado";
import ResultadoCombinado from "../ResultadoCombinado/ResultadoCombinado";
import combinador from "../../util/combinadorParaArray";
import * as reservacionesActions from "../../redux/Actions/reservacionesActions";
import * as reservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";
import * as ComparadorSelectors from "../../redux/Selectors/ComparadorSelectors";
import * as caracteristicasCabanas from "../../consts/CaracteristicasCabanas";
import Swal from "sweetalert2";

const ListaDeResultados = ({
  resultados,
  nuevaBusqueda,
  setNuevaBusqueda,
  busquedaCombinada,
  setBusquedaCombinada,
}) => {
  const numeroDeHuespedes = useSelector((state) =>
    reservacionesSelectors.numeroDeHuespedes(state)
  );
  const dispatch = useDispatch();
  const costos = useSelector((state) => ComparadorSelectors.costos(state));
  const renderResultados = () => {
    let huespedes =
      numeroDeHuespedes.numeroDeAdultos + numeroDeHuespedes.numeroDeNinos;
    let opciones = [];
    let precio;
    const calculoDePrecio = (
      precioBase,
      precioPorPersona,
      minimoAdultos,
      precioPorMascota
    ) => {
      return (
        precioBase +
        precioPorPersona *
          (numeroDeHuespedes.numeroDeAdultos > minimoAdultos
            ? numeroDeHuespedes.numeroDeAdultos - minimoAdultos
            : 0) +
        precioPorMascota * numeroDeHuespedes.numeroDeMascotas
      );
    };
    if (!busquedaCombinada) {
      resultados.forEach((resultado) => {
        if (
          huespedes <=
            caracteristicasCabanas.datos[`Cabana_${resultado}`].capacidadMax &&
          costos
        ) {
          precio = costos.desglose
            .map((fecha) =>
              calculoDePrecio(
                fecha.precioBase[`Cabana_${resultado}_precio_base`],
                fecha.precioPorPersona,
                caracteristicasCabanas.datos[`Cabana_${resultado}`].capacidadMin,
                fecha.precioPorMascota
              )
            )
            .reduce((acc, cur) => acc + cur);
          //Determina el costo con descuento solo para tipo porcentaje y tipo dinero
          let precioConDescuento =
            costos.descuento &&
            costos.descuento.oferta &&
            costos.descuento.tipo === "porcentaje"
              ? costos.desglose
                  .map(
                    (fecha) =>
                      calculoDePrecio(
                        fecha.precioBase[`Cabana_${resultado}_precio_base`],
                        fecha.precioPorPersona,
                        caracteristicasCabanas.datos[`Cabana_${resultado}`]
                          .capacidadMin,
                        fecha.precioPorMascota
                      ) *
                      (fecha.aplicarDescuentoAFecha
                        ? (100 - costos.descuento.valor) / 100
                        : 1)
                  )
                  .reduce((acc, cur) => acc + cur)
              : costos.descuento && costos.descuento.tipo === "dinero"
              ? precio - costos.descuento.valor
              : false;
          let cumpleRequisitos = true;
          if (
            !caracteristicasCabanas.datos[`Cabana_${resultado}`].admiteNinos &&
            numeroDeHuespedes.numeroDeNinos > 0
          ) {
            cumpleRequisitos = false;
          }
          if (
            !caracteristicasCabanas.datos[`Cabana_${resultado}`].admiteNinos &&
            numeroDeHuespedes.numeroDeBebes > 0
          ) {
            cumpleRequisitos = false;
          }
          if (
            !caracteristicasCabanas.datos[`Cabana_${resultado}`]
              .admiteMascotas &&
            numeroDeHuespedes.numeroDeMascotas > 0
          ) {
            cumpleRequisitos = false;
          }
          if (cumpleRequisitos) {
            opciones.push(
              <Resultado
                cabana={resultado}
                key={resultado}
                precio={precio}
                precioConDescuento={precioConDescuento}
              />
            );
          }
        }
      });
      return opciones;
    } else if (busquedaCombinada) {
      const sumar = (propiedad) => (acumulador, valorActual) =>
        acumulador +
        caracteristicasCabanas.datos[`Cabana_${valorActual}`][propiedad];
      let resultadosCombinados = combinador(resultados);
      // Funcion para determinar la cantidad de combinaciones justa sin exceder
      resultadosCombinados = resultadosCombinados.filter((combinacion) => {
        let acumulado = 0;
        for (let index = 0; index < combinacion.length; index++) {
          acumulado =
            acumulado +
            caracteristicasCabanas.datos[`Cabana_${combinacion[index]}`][
              "capacidadMax"
            ];
          if (acumulado >= huespedes) {
            if (index + 1 === combinacion.length) {
              return true;
            } else {
              return false;
            }
          }
        }
        return false;
      });
      let numeroDeOpcion = 1;
      resultadosCombinados.forEach((combinacion) => {
        precio = costos.desglose
          .map((fecha) =>
            calculoDePrecio(
              combinacion
                .map(
                  (numeroDeCabana) =>
                    fecha.precioBase[`Cabana_${numeroDeCabana}_precio_base`]
                )
                .reduce((acc, cur) => acc + cur),
              fecha.precioPorPersona,
              combinacion.reduce(sumar("capacidadMin"), 0),
              fecha.precioPorMascota
            )
          )
          .reduce((acc, cur) => acc + cur);
        //Determina el costo con descuento solo para tipo porcentaje y tipo dinero
        let precioConDescuento =
          costos.descuento &&
          costos.descuento.oferta &&
          costos.descuento.tipo === "porcentaje"
            ? costos.desglose
                .map(
                  (fecha) =>
                    calculoDePrecio(
                      combinacion
                        .map(
                          (numeroDeCabana) =>
                            fecha.precioBase[
                              `Cabana_${numeroDeCabana}_precio_base`
                            ]
                        )
                        .reduce((acc, cur) => acc + cur),
                      fecha.precioPorPersona,
                      combinacion.reduce(sumar("capacidadMin"), 0),
                      fecha.precioPorMascota
                    ) *
                    (fecha.aplicarDescuentoAFecha
                      ? (100 - costos.descuento.valor) / 100
                      : 1)
                )
                .reduce((acc, cur) => acc + cur)
            : costos.descuento && costos.descuento.tipo === "dinero"
            ? precio - costos.descuento.valor
            : false;
        opciones.push(
          <div className="opcionDeCombinacion" key={numeroDeOpcion}>
            <p>Opción {numeroDeOpcion}</p>
            <ResultadoCombinado
              combinacion={combinacion}
              key={numeroDeOpcion}
              precio={precio}
              precioConDescuento={precioConDescuento}
            />
          </div>
        );
        numeroDeOpcion++;
      });
      return opciones;
    }
  };
  const mensaje = () => {
    let maximoDeHuespedesDisponible = 0;
    let maximoDeHuespedesDisponibleCombinados = 0;
    let numeroDeCabanaMayorCapacidadDisponible;
    resultados.forEach((numero) => {
      if (
        caracteristicasCabanas.datos[`Cabana_${numero}`].capacidadMax >
        maximoDeHuespedesDisponible
      ) {
        maximoDeHuespedesDisponible =
          caracteristicasCabanas.datos[`Cabana_${numero}`].capacidadMax;
        numeroDeCabanaMayorCapacidadDisponible = numero;
      }
      const reductor = (acumulador, numero) =>
        acumulador +
        caracteristicasCabanas.datos[`Cabana_${numero}`].capacidadMax;
      maximoDeHuespedesDisponibleCombinados = resultados.reduce(reductor, 0);
    });
    if (nuevaBusqueda === false) {
      if (maximoDeHuespedesDisponible !== 0 && resultados.length !== 0) {
        if (
          busquedaCombinada === false &&
          numeroDeHuespedes.numeroDeAdultos + numeroDeHuespedes.numeroDeNinos >
            maximoDeHuespedesDisponible &&
          resultados.length > 1
        ) {
          Swal.fire(
            "Intente busqueda combinada",
            `La cabaña disponible para estas fechas con mayor capacidad es la Cabaña
          ${
            caracteristicasCabanas.datos[
              `Cabana_${numeroDeCabanaMayorCapacidadDisponible}`
            ].nombre
          }, que tiene una capacidad máxima de 
          ${maximoDeHuespedesDisponible} huespedes contando adultos y niños. 
          Se cambió a busqueda combinada donde le ofrecemos más de una cabaña por reservación`,
            "warning"
          );
          setBusquedaCombinada(true);
        } else if (
          busquedaCombinada === false &&
          numeroDeHuespedes.numeroDeAdultos + numeroDeHuespedes.numeroDeNinos >
            maximoDeHuespedesDisponible &&
          resultados.length === 1
        ) {
          Swal.fire(
            "Sin disponibilidad",
            `La cabaña disponible para estas fechas con mayor capacidad es la Cabaña
            ${
              caracteristicasCabanas.datos[
                `Cabana_${numeroDeCabanaMayorCapacidadDisponible}`
              ].nombre
            }, que tiene una capacidad máxima de 
            ${maximoDeHuespedesDisponible} huespedes contando adultos y niños.`,
            "warning"
          );
          dispatch(reservacionesActions.resetReservacionesState());
          setNuevaBusqueda(true);
        } else if (busquedaCombinada === true && resultados.length === 1) {
          Swal.fire(
            "Sin resultados combinados",
            `La única cabaña disponible para estas fechas es la Cabaña
            ${
              caracteristicasCabanas.datos[
                `Cabana_${numeroDeCabanaMayorCapacidadDisponible}`
              ].nombre
            }, que tiene una capacidad máxima de 
            ${maximoDeHuespedesDisponible} huespedes contando adultos y niños.`,
            "warning"
          );
          setBusquedaCombinada(false);
        } else if (
          busquedaCombinada === true &&
          numeroDeHuespedes.numeroDeAdultos + numeroDeHuespedes.numeroDeNinos >
            maximoDeHuespedesDisponibleCombinados &&
          resultados.length > 1
        ) {
          Swal.fire(
            "Sin disponibilidad",
            "No hay cabañas disponibles para poder alojar esa cantidad de húespedes, intente con otras fechas",
            "warning"
          );
          dispatch(reservacionesActions.resetReservacionesState());
          setNuevaBusqueda(true);
        } else if (
          busquedaCombinada === true &&
          numeroDeHuespedes.numeroDeAdultos + numeroDeHuespedes.numeroDeNinos <=
            maximoDeHuespedesDisponible
        ) {
          Swal.fire(
            "Sin resultados combinados disponibles",
            "Se cambió a busqueda normal",
            "warning"
          );
          setBusquedaCombinada(false);
        }
      } else {
        Swal.fire(
          "Sin disponibilidad",
          "No hay cabañas disponibles para poder alojar esa cantidad de húespedes, intente con otras fechas",
          "warning"
        );
        setNuevaBusqueda(true);
      }
    }
  };
  return (
    <div id="listaDeResultados">
      {mensaje()}
      {renderResultados()}
    </div>
  );
};

export default ListaDeResultados;
