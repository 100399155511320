import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Menu.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import * as AuthSelectors from "../../redux/Selectors/AuthSelector";
import * as AuthActions from "../../redux/Actions/AuthActions";

//Componentes
import Modal from "../../Components/Modal/Modal";
import LoginYRegistro from "../LoginYRegistro/LoginYRegistro";

//Assets
import logo from "../../assets/imagenes/logo.png";
import iconoComparador from "../../assets/imagenes/compare.svg";
import cabezaAveAzul from "../../assets/imagenes/cabezaAveAzul.png";
import cabezaJilguero from "../../assets/imagenes/cabezaJilguero.png";
import cabezaTigrillo from "../../assets/imagenes/cabezaTigrillo.png";
import cabezaColibri from "../../assets/imagenes/cabezaColibri.png";
import cabezaCoa from "../../assets/imagenes/cabezaCoa.png";
import cabezaBuho from "../../assets/imagenes/cabezaBuho.png";
import * as GeneralSVG from "../../assets/SVG/ExportadorGeneralSVG";
import * as BoostrapsIcons from "react-icons/bs";

//Animaciones
import * as AnimacionesGenerales from "../../AnimacionesGenerales/AnimacionesGenerales_exporter";
import * as AnimacionesPanelUsuario from "../../Vistas/PanelUsuario/Animaciones/Animaciones";

//Consts
import GeneralUrls from "../../consts/GeneralUrls";

const opciones = [
  { id: "0b", nombre: "Comparador", icono: iconoComparador, ruta: "/" },
  { id: "1", nombre: "Ave azul", icono: cabezaAveAzul, ruta: "/calendario/1" },
  { id: "2", nombre: "Jilguero", icono: cabezaJilguero, ruta: "/calendario/2" },
  { id: "3", nombre: "Tigrillo", icono: cabezaTigrillo, ruta: "/calendario/3" },
  { id: "4", nombre: "Colibri", icono: cabezaColibri, ruta: "/calendario/4" },
  { id: "5", nombre: "Coa", icono: cabezaCoa, ruta: "/calendario/5" },
  { id: "6", nombre: "Buho", icono: cabezaBuho, ruta: "/calendario/6" },
];

const Menu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const modalLogin = useSelector((state) => AuthSelectors.modalLogin(state));
  const userInfo = useSelector((state) => AuthSelectors.userInfo(state));
  const { pathname } = useLocation();
  const [desplegado, setDesplegado] = useState(false);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    if (modalLogin) {
      setModal(true);
    } else {
      setModal(false);
    }
  }, [modalLogin]);
  return (
    <Fragment>
      <div id="menu">
        <div id="contenedorOpcionesMenu">
          <a href={GeneralUrls.landing_page} className="itemsDeMenu">
            <img src={logo} alt="Inicio" />
            <p>Inicio</p>
          </a>
          {opciones.map((opcion, key) => {
            if (pathname !== opcion.ruta) {
              return (
                <div
                  key={key}
                  // to={{ pathname: opcion.ruta }}
                  className="itemsDeMenu"
                  onClick={() => {
                    setDesplegado(false);
                    AnimacionesGenerales.ColapsarMenu();
                    if (pathname.includes("panel_usuario")) {
                      AnimacionesPanelUsuario.Cierre(() => {
                        history.push(opcion.ruta);
                      });
                    } else {
                      AnimacionesGenerales.Closing(() =>
                        history.push(opcion.ruta)
                      );
                    }
                  }}
                >
                  <img src={opcion.icono} alt={opcion.nombre} />
                  <p>{opcion.nombre}</p>
                </div>
              );
            } else {
              return false;
            }
          })}
          <div
            className="itemsDeMenu"
            onClick={() => {
              setModal(true);
              setDesplegado(false);
              AnimacionesGenerales.ColapsarMenu();
            }}
          >
            <img
              className="imagenPerfilMenu"
              src={
                userInfo && userInfo.imagenPerfil
                  ? userInfo.imagenPerfil
                  : GeneralSVG.Avatar_predeterminado
              }
              alt="Mi cuenta"
            />
            <p>Mi cuenta</p>
          </div>
        </div>
        <button
          onClick={() => {
            if (desplegado) {
              AnimacionesGenerales.ColapsarMenu();
            } else {
              AnimacionesGenerales.DesplegarMenu();
            }
            setDesplegado(!desplegado);
          }}
          id="botonMenu"
        >
          <p>Menú</p>
          <BoostrapsIcons.BsFillCaretDownFill
            className={desplegado ? "icono-menu-abierto" : "icono-menu-cerrado"}
          />
        </button>
      </div>
      <Modal visibilidad={modal} setModal={setModal}>
        <LoginYRegistro
          cerrarModal={() => {
            dispatch(AuthActions.modalLogin(false));
            setModal(false);
          }}
        />
      </Modal>
    </Fragment>
  );
};

export default Menu;
