import { get } from "lodash";

export const cargandoCalendario = (state) =>
  get(state, "CabanaReducer.cargandoCalendario");
export const fechasOcupadas = (state) =>
  get(state, "CabanaReducer.fechasOcupadas");
export const minimoNochesSemana = (state) =>
  get(state, "CabanaReducer.minimoNochesSemana");
export const minimoNochesExcepciones = (state) =>
  get(state, "CabanaReducer.minimoNochesExcepciones");
export const cargandoCabana = (state) =>
  get(state, "CabanaReducer.cargandoCabana");
