import * as types from "../actionTypes";
import { setToken, eraseToken } from "../../services/tokenServices";

const initialState = {
  modalLogin: false,
  userInfo: null,
  logged: false,
  token: null,
  registrado: false,
  correoDeRecuperacionEnviado: false,
  contrasenaActualizada: false,
  cargandoAuth: false,
  esAdmin: false,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case types.MODAL_LOGIN:
      return {
        ...state,
        modalLogin: action.payload,
      };
    case types.LOGIN_USUARIO_REGISTRADO:
      return {
        ...state,
        cargandoAuth: true,
      };
    case types.LOGIN_USUARIO_REGISTRADO_SUCCESS:
      setToken(action.resultados.headers["x-auth-token"]);
      return {
        ...state,
        userInfo: action.resultados.data,
        logged: true,
        token: action.resultados.headers["x-auth-token"],
        cargandoAuth: false,
      };
    case types.LOGIN_USUARIO_REGISTRADO_ERROR:
      return {
        ...state,
        userInfo: null,
        logged: false,
        token: null,
        cargandoAuth: false,
      };
    case types.LOGIN_USUARIO_TEMPORAL:
      return {
        ...state,
        cargandoAuth: true,
      };
    case types.LOGIN_USUARIO_TEMPORAL_SUCCESS:
      return {
        ...state,
        userInfo: action.resultados.data,
        logged: true,
        token: action.resultados.headers["x-auth-token"],
        cargandoAuth: false,
      };
    case types.LOGIN_USUARIO_TEMPORAL_ERROR:
      return {
        ...state,
        userInfo: null,
        logged: false,
        token: null,
        cargandoAuth: false,
      };
    case types.REGISTRAR_USUARIO:
      return {
        ...state,
        cargandoAuth: true,
      };
    case types.REGISTRAR_USUARIO_SUCCESS:
      setToken(action.resultados.headers["x-auth-token"]);
      return {
        ...state,
        registrado: true,
        cargandoAuth: false,
      };
    case types.REGISTRAR_USUARIO_ERROR:
      return {
        ...state,
        cargandoAuth: false,
      };
    case types.RESET_VERIFICAR_USUARIO:
      return {
        ...state,
        registrado: false,
      };
    case types.VERIFICAR_USUARIO_SUCCESS:
      setToken(action.resultados.headers["x-auth-token"]);
      return {
        ...state,
        userInfo: action.resultados.data,
        logged: true,
        token: action.resultados.headers["x-auth-token"],
      };
    case types.VERIFICAR_USUARIO_ERROR:
      return {
        ...state,
        userInfo: null,
        logged: false,
        token: null,
      };
    case types.LOG_OUT:
      eraseToken();
      return {
        ...state,
        userInfo: null,
        logged: false,
        token: null,
      };
    case types.VERIFICA_ACCESS_TOKEN_SUCCESS:
      return {
        userInfo: action.resultados.data,
        logged: true,
      };
    case types.VERIFICA_ACCESS_TOKEN_ERROR:
      eraseToken();
      return {
        ...state,
        userInfo: null,
        logged: false,
        token: null,
      };
    case types.PUT_IMAGEN_PERFIL:
      return {
        ...state,
        cargandoAuth: true,
      };
    case types.PUT_IMAGEN_PERFIL_SUCCESS:
      return {
        ...state,
        userInfo: action.resultados.data,
        cargandoAuth: false,
      };
    case types.PUT_IMAGEN_PERFIL_ERROR:
      return {
        ...state,
        cargandoAuth: false,
      };
    case types.PUT_INFO_USUARIO:
      return {
        ...state,
        cargandoAuth: true,
      };
    case types.PUT_INFO_USUARIO_SUCCESS:
      return {
        ...state,
        userInfo: action.resultados.data,
        cargandoAuth: false,
      };
    case types.PUT_INFO_USUARIO_ERROR:
      return {
        ...state,
        cargandoAuth: false,
      };
    case types.RECUPERACION_ENVIAR_CORREO:
      return {
        ...state,
        cargandoAuth: true,
      };
    case types.RECUPERACION_ENVIAR_CORREO_SUCCESS:
      return {
        ...state,
        correoDeRecuperacionEnviado: true,
        cargandoAuth: false,
      };
    case types.RECUPERACION_ENVIAR_CORREO_ERROR:
      return {
        ...state,
        cargandoAuth: false,
      };
    case types.RECUPERACION_CAMBIAR_CONTRASENA:
      return {
        ...state,
        cargandoAuth: true,
      };
    case types.RECUPERACION_CAMBIAR_CONTRASENA_SUCCESS:
      return {
        ...state,
        contrasenaActualizada: true,
        cargandoAuth: false,
      };
    case types.RECUPERACION_CAMBIAR_CONTRASENA_ERROR:
      return {
        ...state,
        cargandoAuth: false,
      };
    case types.RESET_RECUPERACION:
      return {
        ...state,
        correoDeRecuperacionEnviado: false,
        contrasenaActualizada: false,
      };
    case types.ELIMINAR_CUENTA:
      return {
        ...state,
        cargandoAuth: true,
      };
    case types.ELIMINAR_CUENTA_SUCCESS:
      eraseToken();
      return {
        ...state,
        userInfo: null,
        logged: false,
        token: null,
        cargandoAuth: false,
      };
    case types.ELIMINAR_CUENTA_ERROR:
      return {
        ...state,
        cargandoAuth: false,
      };
    case types.ES_ADMIN_SUCCESS:
      return {
        ...state,
        esAdmin: true,
      };
    case types.ES_ADMIN_ERROR:
      return {
        ...state,
        esAdmin: false,
      };
    default:
      return { ...state };
  }
}
