import anime from "animejs";

const EntraBarraLateral = (callbackAfter = () => {}) => {
  let tiempo = 1000;
  let timeline = anime.timeline({
    duration: tiempo,
    easing: "easeInOutSine",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: ".barraLateralIzquierda",
      left: "-100%",
      duration: tiempo,
    },
    0
  );
};

export default EntraBarraLateral;
