function numeroDeDiasEntreFechas(fechaMenor, fechaMayor) {
  let fechaMenorSinHora = new Date(fechaMenor);
  fechaMenorSinHora.setHours(0, 0, 0, 0);
  let fechaMayorSinHora = new Date(fechaMayor);
  fechaMayorSinHora.setHours(0, 0, 0, 0);
  if (fechaMenor && fechaMayor) {
    let dias = Math.ceil(
      (Date.parse(fechaMayorSinHora) - Date.parse(fechaMenorSinHora)) /
        (1000 * 60 * 60 * 24)
    );
    return dias;
  } else {
    return 0;
  }
}

module.exports = numeroDeDiasEntreFechas;
