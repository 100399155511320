import React, { Fragment, useState, useEffect } from "react";
import "./Perfil.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as AuthSelectors from "../../redux/Selectors/AuthSelector";
import * as AuthActions from "../../redux/Actions/AuthActions";

//Assets
import * as GeneralSVG from "../../assets/SVG/ExportadorGeneralSVG";

//Animaciones
import * as AnimacionesPanelUsuario from "../../Vistas/PanelUsuario/Animaciones/Animaciones";

//Utils
import calcularEdad from "../../util/calcularEdad";

//Services
import * as SeguimientoServices from "../../services/seguimientoServices";

const Perfil = ({ abrirModalModificar, abrirModalEliminarCuenta }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => AuthSelectors.userInfo(state));
  const [subiendoImagen, setSubiendoImagen] = useState(false);
  return (
    <div id="perfil-fixed-div-invisible" className="barraLateralIzquierda">
      <div className="perfil">
        {userInfo && (
          <Fragment>
            <div className="datosDeUsuario">
              {subiendoImagen ? (
                <canvas
                  id="canvasImagenPerfilPanelUsuario"
                  className="divImagenPerfil"
                ></canvas>
              ) : (
                <img
                  src={
                    userInfo && userInfo.imagenPerfil
                      ? userInfo.imagenPerfil
                      : GeneralSVG.Avatar_predeterminado
                  }
                  alt="Imagen de perfil"
                  className="imagenDePerfil"
                  onClick={() =>
                    document.getElementById("perfilImagenUpload").click()
                  }
                />
              )}
              <input
                style={{ display: "none" }}
                id="perfilImagenUpload"
                type="file"
                accept=".jpeg, .png, .jpg"
                onChange={(event) => {
                  let imagen = event.target.files[0];
                  const blobURL = window.URL.createObjectURL(imagen);
                  const img = new Image();
                  img.src = blobURL;
                  img.onload = function () {
                    setSubiendoImagen(true);

                    window.URL.revokeObjectURL(blobURL);
                    const canvas = document.getElementById(
                      "canvasImagenPerfilPanelUsuario"
                    );
                    const proporcion = Math.floor(this.width / 150);
                    const ancho =
                      proporcion > 1 ? this.width / proporcion : this.width;
                    const alto =
                      proporcion > 1 ? this.height / proporcion : this.height;
                    canvas.width = ancho;
                    canvas.height = alto;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, ancho, alto);
                    let dataURL = canvas.toDataURL();
                    dispatch(
                      AuthActions.putImagenDePerfil({
                        imagenPerfil: dataURL,
                      })
                    );
                    setSubiendoImagen(false);
                  };
                }}
              />
              {userInfo.rol !== "Cliente" && <p>{userInfo.rol}</p>}
              <p>
                {userInfo.nombre} {userInfo.apellido}
              </p>
              <p>{calcularEdad(userInfo.fechaNacimiento)} años</p>
              <p>{userInfo.email}</p>
              {userInfo.telefono && <p>{userInfo.telefono}</p>}
            </div>
            <div className="opcionesPerfil">
              <button className="botonActualizar" onClick={abrirModalModificar}>
                Actualizar datos
              </button>
              <button
                className="botonCerrar"
                onClick={() => {
                  AnimacionesPanelUsuario.Cierre(() =>
                    dispatch(AuthActions.logOut())
                  );
                }}
              >
                Cerrar sesión
              </button>
              <button
                className="botonBorrar"
                onClick={abrirModalEliminarCuenta}
              >
                Borrar cuenta
              </button>
              {userInfo.rol === "Administrador" && (
                <button
                  className="botonSeguimiento"
                  onClick={() => {
                    if (
                      SeguimientoServices.getInstruccionSeguimiento() ===
                      "no_seguir"
                    ) {
                      SeguimientoServices.eraseInstruccionSeguimiento();
                      document.location.reload();
                    } else {
                      SeguimientoServices.setInstruccionSeguimiento(
                        "no_seguir"
                      );
                      document.location.reload();
                    }
                  }}
                >
                  {SeguimientoServices.getInstruccionSeguimiento() ===
                  "no_seguir"
                    ? "Activar seguimiento"
                    : "Desactivar seguimiento"}
                </button>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Perfil;
