import * as types from '../actionTypes';

export const postEventoBuscar = (payload) => {
    return {
        type: types.POST_EVENTO_BUSCAR,
        payload
    }
}

export const postEventoCotizacionCabana = (payload) => {
    return {
        type: types.POST_EVENTO_COTIZACION_CABANA,
        payload
    }
}

export const postEventoListoReservar = (payload) => {
    return {
        type: types.POST_EVENTO_LISTO_RESERVAR,
        payload
    }
}

export const putEventoActualBuscar = (payload) => {
    return {
        type: types.PUT_EVENTO_ACTUAL_BUSCAR,
        payload
    }
}

export const putEventoActualCotizacionCabana = (payload) => {
    return {
        type: types.PUT_EVENTO_ACTUAL_COTIZACION_CABANA,
        payload
    }
}

export const borrarEventoActualBuscar = (payload) => {
    return {
        type: types.BORRAR_EVENTO_ACTUAL_BUSCAR,
        payload
    }
}

export const borrarEventoActualCotizacionCabana = (payload) => {
    return {
        type: types.BORRAR_EVENTO_ACTUAL_COTIZACION_CABANA,
        payload
    }
}