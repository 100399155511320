import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../reservaciones_api/reservaciones_api";
import * as types from "../actionTypes";
import Swal from "sweetalert2";


export function* getFechasOcupadasCabanaSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      `disponibilidad/disponibilidad_de_cabana?cabana=${payload}`,
      null,
      {
        "Content-Type": "application/json",
      }
    );
    console.log("Fechas ocupadas de cabaña solicitada exitosamente.");
    yield put({ type: types.GET_FECHAS_OCUPADAS_CABANA_SUCCESS, resultados })
  } catch (error) {
    Swal.fire("Hubo un error!", "No fue posible recibir la información solicitada", "error")
    console.log("Error al solicitar las fechas ocupadas de la cabaña: ", error.response.data);
    yield put({ type: types.GET_FECHAS_OCUPADAS_CABANA_ERROR, payload })
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(types.GET_FECHAS_OCUPADAS_CABANA, getFechasOcupadasCabanaSaga);
}
