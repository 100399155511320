function nombreDiaDeLaSemana(numero) {
  switch (numero) {
    case 0:
      return "Domingo";
    case 1:
      return "Lunes";
    case 2:
      return "Martes";
    case 3:
      return "Miercoles";
    case 4:
      return "Jueves";
    case 5:
      return "Viernes";
    case 6:
      return "Sabado";
    default:
      throw new Error("No es un numero de dia de la semana valido");
  }
}
export default nombreDiaDeLaSemana;
