import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  quitarCupon,
  getCosto,
} from "../../redux/Actions/reservacionesActions";
import * as ReservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";
import Swal from "sweetalert2";
import "./VisualizadorDeCupon.css";

//utils
import fechaLarga from "../../util/fechaLarga";
import SQLtoJSDateParser from "../../util/SQLtoJSDateParser";
import arrayDeNochesDeViaje from "../../util/arrayDeNochesDeViaje";
import fechaEntreRango from "../../util/fechaEntreRango";

const VisualizadorDeCupon = ({ funcionCancelar }) => {
  const dispatch = useDispatch();
  const cupon = useSelector((state) => ReservacionesSelectors.cupon(state));
  const cabanasARentar = useSelector((state) =>
    ReservacionesSelectors.cabanasARentar(state)
  );
  const fechaDeEntrada = useSelector((state) =>
    ReservacionesSelectors.fechaDeEntrada(state)
  );
  const fechaDeSalida = useSelector((state) =>
    ReservacionesSelectors.fechaDeSalida(state)
  );
  const numeroDeHuespedes = useSelector((state) =>
    ReservacionesSelectors.numeroDeHuespedes(state)
  );
  useEffect(() => {
    if (
      cupon &&
      !arrayDeNochesDeViaje(fechaDeEntrada, fechaDeSalida).some((fecha) =>
        fechaEntreRango(
          fecha,
          SQLtoJSDateParser(cupon.Inicio),
          SQLtoJSDateParser(cupon.Fin)
        )
      )
    ) {
      Swal.fire(
        "El cupon no puede ser aplicado",
        "No se puede aplicar la promoción a ninguna de las fechas de viaje",
        "error"
      );
      dispatch(quitarCupon());
      funcionCancelar();
    }
  }, [cupon, fechaDeEntrada, fechaDeSalida, funcionCancelar]);

  return (
    <div id="visualizador-cupon">
      <table>
        <tbody>
          <tr>
            <td className="td-codigo-visualizador-cupon">
              <p>Código de cupón</p>
              <h2>{cupon.Id}</h2>
            </td>
            <td className="td-descuento-visualizador-cupon">
              <p>Descuento de</p>
              <h2>
                {cupon.Tipo === "dinero"
                  ? `$${cupon.Valor}.00 MXN`
                  : cupon.Tipo === "porcentaje"
                  ? `${cupon.Valor}%`
                  : ""}
              </h2>
            </td>
          </tr>
          <tr>
            <td className="td-fechas-visualizador-cupon" colSpan="2">
              <p>
                Para reservaciones con fechas de viaje entre las siguientes
                fechas
                {cupon.Tipo === "porcentaje"
                  ? ", solo se aplicará el descuento sobre las fechas que están dentro del rango indicado"
                  : ""}
              </p>
              <h3>
                {fechaLarga(SQLtoJSDateParser(cupon.Inicio))} <br />
                al
                <br />
                {fechaLarga(SQLtoJSDateParser(cupon.Fin))}
              </h3>
              <p>Vigencia para usar el cupón</p>
              <h4>{fechaLarga(SQLtoJSDateParser(cupon.Expiracion))}</h4>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="div-botones-visualizador-cupon">
        <button
          onClick={() => {
            dispatch(quitarCupon());
            funcionCancelar();
          }}
        >
          Cancelar
        </button>
        <button
          onClick={() => {
            dispatch(
              getCosto({
                fechaDeEntrada,
                fechaDeSalida,
                numeroDeAdultos: numeroDeHuespedes.numeroDeAdultos,
                numeroDeMascotas: numeroDeHuespedes.numeroDeMascotas,
                cabana: cabanasARentar.join("-"),
                IdCupon: cupon.Id,
              })
            );
            funcionCancelar();
          }}
        >
          Aplicar
        </button>
      </div>
    </div>
  );
};

export default VisualizadorDeCupon;
