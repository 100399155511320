import { get } from "lodash";

export const cargandoCalendario = (state) =>
  get(state, "ComparadorReducer.cargandoCalendario");
export const fechasSinDisponibilidad = (state) =>
  get(state, "ComparadorReducer.fechasSinDisponibilidad");
export const cabanasDisponiblesComparador = (state) =>
  get(state, "ComparadorReducer.cabanasDisponibles");
export const costos = (state) => get(state, "ComparadorReducer.costos");
export const minimoNochesSemana = (state) =>
  get(state, "ComparadorReducer.minimoNochesSemana");
export const minimoNochesExcepciones = (state) =>
  get(state, "ComparadorReducer.minimoNochesExcepciones");
export const cargandoComparador = (state) =>
  get(state, "ComparadorReducer.cargandoComparador");
