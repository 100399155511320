import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Cabana.css";
import Calendario from "../../Components/Calendario/Calendario";
import CantidadDeHuespedes from "../../Components/CantidadDeHuespedes/CantidadDeHuespedes";
import MasDetalles from "../../Components/MasDetalles/MasDetalles";
import Reservar from "../../Components/Reservar/Reservar";
import * as AnimacionesGenerales from "../../AnimacionesGenerales/AnimacionesGenerales_exporter";
import * as CabanaActions from "../../redux/Actions/CabanaActions";
import * as ReservacionesActions from "../../redux/Actions/reservacionesActions";
import * as ReservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";
import * as CabanaSelectors from "../../redux/Selectors/CabanaSelector";
import * as caracteristicasCabanas from "../../consts/CaracteristicasCabanas";
import * as eventosActions from "../../redux/Actions/EventosActions";
import { cotizacionCabanaActualId } from "../../redux/Selectors/EventosSelector";

//services
import { getInstruccionSeguimiento } from "../../services/seguimientoServices";

const Cabana = () => {
  const { cabana } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const numeroDeHuespedes = useSelector((state) =>
    ReservacionesSelectors.numeroDeHuespedes(state)
  );
  const fechaDeEntrada = useSelector((state) =>
    ReservacionesSelectors.fechaDeEntrada(state)
  );
  const fechaDeSalida = useSelector((state) =>
    ReservacionesSelectors.fechaDeSalida(state)
  );
  const costo = useSelector((state) => ReservacionesSelectors.costo(state));
  const fechasOcupadas = useSelector((state) =>
    CabanaSelectors.fechasOcupadas(state)
  );
  const minimoNochesSemana = useSelector((state) =>
    CabanaSelectors.minimoNochesSemana(state)
  );
  const minimoNochesExcepciones = useSelector((state) =>
    CabanaSelectors.minimoNochesExcepciones(state)
  );
  const cargandoCalendario = useSelector((state) =>
    CabanaSelectors.cargandoCalendario(state)
  );
  const cotizacionCabanaId = useSelector((state) =>
    cotizacionCabanaActualId(state)
  );
  const [visibilidad, setVisibilidad] = useState(costo ? true : false);
  const [costoTotal, setCostoTotal] = useState(null);
  const [oferta, setOferta] = useState(false);
  const [paginaCargo, setPaginaCargo] = useState(false);
  useEffect(() => {
    window.onbeforeunload = function (e) {}; //Elimina los mensajes previos a cerrar ventana que se pudieron haber generado
    // Limpieza de cotizacionCabanaId
    if (cotizacionCabanaId) {
      dispatch(eventosActions.borrarEventoActualCotizacionCabana());
    }
    return () => {
      if (cotizacionCabanaId) {
        dispatch(eventosActions.borrarEventoActualCotizacionCabana());
      }
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (caracteristicasCabanas.datos[`Cabana_${cabana}`] === undefined)
      history.push("/");
    if (cabana && caracteristicasCabanas.datos[`Cabana_${cabana}`]) {
      AnimacionesGenerales.Opening(() => {
        setPaginaCargo(true);
      });
      dispatch(CabanaActions.getFechasOcupadasCabana(cabana));
      dispatch(ReservacionesActions.resetReservacionesState());
    }
  }, [cabana, dispatch]);
  useEffect(() => {
    if (
      costo &&
      numeroDeHuespedes &&
      fechaDeEntrada &&
      fechaDeSalida &&
      cabana
    ) {
      setVisibilidad(true);
      const calculoDePrecio = (
        precioBase,
        precioPorPersona,
        minimoAdultos,
        precioPorMascota
      ) => {
        return (
          precioBase +
          precioPorPersona *
            (numeroDeHuespedes.numeroDeAdultos > minimoAdultos
              ? numeroDeHuespedes.numeroDeAdultos - minimoAdultos
              : 0) +
          precioPorMascota * numeroDeHuespedes.numeroDeMascotas
        );
      };
      let precio = costo.desglose
        .map((fecha) =>
          calculoDePrecio(
            fecha.precioBase[`Cabana_${cabana}_precio_base`],
            fecha.precioPorPersona,
            caracteristicasCabanas.datos[`Cabana_${cabana}`].capacidadMin,
            fecha.precioPorMascota
          )
        )
        .reduce((acc, cur) => acc + cur);
      let precioConDescuento =
        costo.descuento &&
        costo.descuento.oferta &&
        costo.descuento.tipo === "porcentaje"
          ? costo.desglose
              .map(
                (fecha) =>
                  calculoDePrecio(
                    fecha.precioBase[`Cabana_${cabana}_precio_base`],
                    fecha.precioPorPersona,
                    caracteristicasCabanas.datos[`Cabana_${cabana}`]
                      .capacidadMin,
                    fecha.precioPorMascota
                  ) *
                  (fecha.aplicarDescuentoAFecha
                    ? (100 - costo.descuento.valor) / 100
                    : 1)
              )
              .reduce((acc, cur) => acc + cur)
          : costo.descuento && costo.descuento.tipo === "dinero"
          ? precio - costo.descuento.valor
          : false;
      setCostoTotal(precio);
      setOferta(precioConDescuento);
    } else {
      setVisibilidad(false);
      setCostoTotal(null);
      setOferta(false);
    }
  }, [costo, numeroDeHuespedes, fechaDeEntrada, fechaDeSalida, cabana]);

  useEffect(() => {
    if (fechaDeEntrada && fechaDeSalida && paginaCargo) {
      dispatch(
        ReservacionesActions.getCosto({
          fechaDeEntrada,
          fechaDeSalida,
          numeroDeAdultos: numeroDeHuespedes.numeroDeAdultos,
          numeroDeMascotas: numeroDeHuespedes.numeroDeMascotas,
          cabana,
        })
      );
    } else {
      dispatch(ReservacionesActions.setCostoTotal(null));
    }
  }, [fechaDeEntrada, fechaDeSalida, paginaCargo]);

  // Evento cotizacion de cabana para actividad-api y Actualizacion de evento cotizacion de cabaña para nuevas cantidades de huespedes para actividad-api
  useEffect(() => {
    if (getInstruccionSeguimiento() !== "no_seguir") {
      if (paginaCargo) {
        if (
          cotizacionCabanaId === null &&
          fechaDeEntrada !== null &&
          fechaDeSalida !== null
        ) {
          dispatch(
            eventosActions.postEventoCotizacionCabana({
              fechaDeEntrada,
              fechaDeSalida,
              numeroDeAdultos: numeroDeHuespedes.numeroDeAdultos,
              numeroDeNinos: numeroDeHuespedes.numeroDeNinos,
              numeroDeBebes: numeroDeHuespedes.numeroDeBebes,
              numeroDeMascotas: numeroDeHuespedes.numeroDeMascotas,
              cabana,
            })
          );
        } else if (
          cotizacionCabanaId &&
          (fechaDeEntrada === null || fechaDeSalida === null)
        ) {
          dispatch(eventosActions.borrarEventoActualCotizacionCabana());
        } else if (
          cotizacionCabanaId &&
          fechaDeEntrada !== null &&
          fechaDeSalida !== null
        ) {
          dispatch(
            eventosActions.putEventoActualCotizacionCabana({
              _id: cotizacionCabanaId,
              numeroDeAdultos: numeroDeHuespedes.numeroDeAdultos,
              numeroDeNinos: numeroDeHuespedes.numeroDeNinos,
              numeroDeBebes: numeroDeHuespedes.numeroDeBebes,
              numeroDeMascotas: numeroDeHuespedes.numeroDeMascotas,
            })
          );
        }
      }
    }
  }, [numeroDeHuespedes, fechaDeEntrada, fechaDeSalida, paginaCargo]);
  return (
    <div id="cabana" className="vista">
      {caracteristicasCabanas.datos[`Cabana_${cabana}`] && (
        <Fragment>
          <Helmet>
            <link rel="icon" href="../logoSoloMini.png" />
            <link rel="apple-touch-icon" href="../logoSoloMini.png" />
            <meta
              name="description"
              content={`Calendario de ${`Cabaña ${
                caracteristicasCabanas.datos[`Cabana_${cabana}`].nombre
              }`}, Cima Calidez le ofrece hermosas cabañas en el bosque y uno de los mejores servicios de atención en todo Mazamitla`}
            />
            <title>
              Cima Calidez - Calendario{" "}
              {`Cabaña ${
                caracteristicasCabanas.datos[`Cabana_${cabana}`].nombre
              }`}
            </title>
          </Helmet>
          <button
            id="botonListo"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Listo
          </button>
          <div id="cabanaOpciones">
            <Calendario
              mesesVisibles={2}
              fechasOcupadas={fechasOcupadas}
              minimoNochesSemana={minimoNochesSemana}
              minimoNochesExcepciones={minimoNochesExcepciones}
              cargando={cargandoCalendario}
            />
            <CantidadDeHuespedes />
          </div>
          <div id="cabanaInformacion">
            <h1>
              {`Cabaña ${
                caracteristicasCabanas.datos[`Cabana_${cabana}`].nombre
              }`}
            </h1>
            <img
              className="imagen-portada-vista-cabana"
              src={caracteristicasCabanas.datos[`Cabana_${cabana}`].fotoPortada}
              alt="Portada de cabaña"
            ></img>
            <h2 id={costoTotal ? "precioVisible" : "precioNoVisible"}>
              Total:{" "}
              <span className={oferta ? "tachado" : ""}>${costoTotal}</span>
              {oferta && (
                <Fragment>
                  <br />
                  Oferta:{" "}
                  <span className="precioConDescuento">{`$${oferta}`}</span>
                </Fragment>
              )}
            </h2>
            <div id="contenedorDeBotones">
              <MasDetalles cabana={cabana} />
              <Reservar visibilidad={visibilidad} cabanasARentar={[cabana]} />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Cabana;
