import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../reservaciones_api/reservaciones_api";
import * as types from "../actionTypes";
import Swal from "sweetalert2";
import { getToken } from "../../services/tokenServices";

export function* getFechasSinDisponibilidadSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      `disponibilidad/fechas_sin_disponibilidad_y_restricciones`,
      null,
      {
        "Content-Type": "application/json",
      }
    );
    console.log("Fechas sin disponibilidad solicitadas exitosamente.");
    yield put({
      type: types.GET_FECHAS_SIN_DISPONIBILIDAD_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire(
      "Hubo un error!",
      "No fue posible recibir la información solicitada",
      "error"
    );
    console.log(
      "Error al solicitar fechas sin disponibilidad: ",
      error.response.data
    );
    yield put({ type: types.GET_FECHAS_SIN_DISPONIBILIDAD_ERROR, payload });
  }
}

export function* getCabanasDisponiblesSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      `disponibilidad/comparador?fechaDeEntrada=${payload.fechaDeEntrada}&fechaDeSalida=${payload.fechaDeSalida}&numeroDeAdultos=${payload.numeroDeAdultos}&numeroDeNinos=${payload.numeroDeNinos}&numeroDeBebes=${payload.numeroDeBebes}&numeroDeMascotas=${payload.numeroDeMascotas}`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    console.log("Disponibilidad de cabañas solicitada exitosamente.");
    yield put({ type: types.GET_CABANAS_DISPONIBLES_SUCCESS, resultados });
  } catch (error) {
    Swal.fire(
      "Hubo un error!",
      "No fue posible recibir la información solicitada",
      "error"
    );
    console.log(
      "Error al solicitar disponibilidad de cabañas: ",
      error.response.data
    );
    yield put({ type: types.GET_CABANAS_DISPONIBLES_ERROR, payload });
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(types.GET_CABANAS_DISPONIBLES, getCabanasDisponiblesSaga);
  yield takeLatest(
    types.GET_FECHAS_SIN_DISPONIBILIDAD,
    getFechasSinDisponibilidadSaga
  );
}
