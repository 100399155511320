import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Calendario.css";
import Mes from "../Mes/Mes";
import botonArribaImg from "../../assets/imagenes/botonArriba.svg";
import botonAbajoImg from "../../assets/imagenes/botonAbajo.svg";
import * as ReservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";

//Utils
import nombreDeMes from "../../util/nombreDeMes";

const Calendario = ({
  fechasOcupadas,
  mesesVisibles = 1,
  minimoNochesSemana,
  minimoNochesExcepciones,
  cargando,
}) => {
  const fechaDeEntrada = useSelector((state) =>
    ReservacionesSelectors.fechaDeEntrada(state)
  );
  const fechaDeSalida = useSelector((state) =>
    ReservacionesSelectors.fechaDeSalida(state)
  );
  const [mesBase, setMesBase] = useState(new Date(Date.now()).getMonth());
  const [anioBase, setAnioBase] = useState(new Date(Date.now()).getFullYear());
  const mesAnterior = () => {
    if (Date.parse(new Date(anioBase, mesBase, 1)) > new Date(Date.now())) {
      if (mesBase === 0) {
        setMesBase(11);
        setAnioBase(anioBase - 1);
      } else {
        setMesBase(mesBase - 1);
      }
    }
  };
  const mesSiguiente = () => {
    if (mesBase === 11) {
      setMesBase(0);
      setAnioBase(anioBase + 1);
    } else {
      setMesBase(mesBase + 1);
    }
  };
  const constructorMeses = () => {
    let array = [];
    let mes = mesBase;
    let anio = anioBase;
    for (let index = 0; index < mesesVisibles; index++) {
      array.push({ mes: mes, anio: anio });
      anio = mes + 1 > 11 ? anio + 1 : anio;
      mes = mes + 1 > 11 ? 0 : mes + 1;
    }
    return array;
  };
  return (
    <div id="presentacionDeCalendario">
      <h1>Seleccione sus fechas</h1>
      <div id="contenedorCalendario">
        <button onClick={mesAnterior}>
          <img
            className="botonesCalendario"
            src={botonArribaImg}
            alt="Mes anterior"
          />
        </button>
        {constructorMeses().map((objeto, index) => (
          <Mes
            key={index}
            mes={objeto.mes}
            anio={objeto.anio}
            fechasOcupadas={fechasOcupadas}
            minimoNochesSemana={minimoNochesSemana}
            minimoNochesExcepciones={minimoNochesExcepciones}
            cargando={cargando}
          />
        ))}
        <button onClick={mesSiguiente}>
          <img
            className="botonesCalendario"
            src={botonAbajoImg}
            alt="Mes siguiente"
          />
        </button>
      </div>
      <div className="leyendas">
        <div>
          <div className="noDisponible">{new Date(Date.now()).getDate()}</div>
          <p>No disponible</p>
        </div>
        <div>
          <div className="restringida">{new Date(Date.now()).getDate()}</div>
          <p>Restringida</p>
        </div>
        <div>
          <div className="seleccionada">{new Date(Date.now()).getDate()}</div>
          <p>Seleccionada</p>
        </div>
      </div>
      <div id="fechaDeEntradaYSalida">
        <table>
          <thead>
            <tr>
              <th>Fecha de entrada</th>
              <th>Fecha de salida</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {fechaDeEntrada ? (
                  <div className="fechasBonitas">
                    <h1>{fechaDeEntrada.getDate()}</h1>
                    <p>{nombreDeMes(fechaDeEntrada)}</p>
                  </div>
                ) : (
                  "Sin definir"
                )}
              </td>
              <td>
                {fechaDeSalida ? (
                  <div className="fechasBonitas">
                    <h1>{fechaDeSalida.getDate()}</h1>
                    <p>{nombreDeMes(fechaDeSalida)}</p>
                  </div>
                ) : (
                  "Sin definir"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Calendario;
