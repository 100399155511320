import anime from "animejs";

const OcultarFlecha = (callbackAfter = () => {}) => {
  let tiempo = 300;
  let timeline = anime.timeline({
    duration: tiempo,
    easing: "linear",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: ".flecha-animada-scroll",
      opacity: 0,
      zIndex: -10,
      duration: tiempo,
    },
    0
  );
};

export default OcultarFlecha;
