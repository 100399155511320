import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getInfoCupon } from "../../redux/Actions/reservacionesActions";
import "./ConsultorDeCupon.css";

const ConsultorDeCupon = ({ funcionCancelar }) => {
  const dispatch = useDispatch();
  const [IdCupon, setIdCupon] = useState("");
  return (
    <form id="consultor-cupon">
      <h3>Ingrese el código del cupón</h3>
      <input
        type="text"
        required
        onChange={(e) => setIdCupon(e.target.value.toUpperCase())}
        value={IdCupon}
      />
      <div className="div-botones-consultor-cupon">
        <button
          onClick={(e) => {
            e.preventDefault();
            funcionCancelar();
          }}
        >
          Cancelar
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (document.getElementById("consultor-cupon").checkValidity()) {
              dispatch(getInfoCupon(IdCupon));
            } else {
              document.getElementById("consultor-cupon").reportValidity();
            }
          }}
        >
          Consultar
        </button>
      </div>
    </form>
  );
};

export default ConsultorDeCupon;
