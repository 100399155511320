import * as types from "../actionTypes";

const initialState = {
  busquedaActualId: null,
  cotizacionCabanaActualId: null,
};

export default function EventosReducer(state = initialState, action) {
  switch (action.type) {
    case types.POST_EVENTO_BUSCAR_SUCCESS:
      return {
        ...state,
        busquedaActualId: action.resultados.data,
      };
    case types.POST_EVENTO_COTIZACION_CABANA_SUCCESS:
      return {
        ...state,
        cotizacionCabanaActualId: action.resultados.data,
      };
    case types.BORRAR_EVENTO_ACTUAL_BUSCAR:
      return {
        ...state,
        busquedaActualId: null,
      };
    case types.BORRAR_EVENTO_ACTUAL_COTIZACION_CABANA:
      return {
        ...state,
        cotizacionCabanaActualId: null,
      };
    default:
      return { ...state };
  }
}
