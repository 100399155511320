import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import "./App.css";

//Redux
import { useSelector } from "react-redux";
import * as AuthSelectors from "../redux/Selectors/AuthSelector";
import * as CabanaSelectors from "../redux/Selectors/CabanaSelector";
import * as ComparadorSelectors from "../redux/Selectors/ComparadorSelectors";
import * as MercadoPagoSelectors from "../redux/Selectors/MercadoPagoSelector";
import * as ReservacionesSelectors from "../redux/Selectors/reservacionesSelectors";
import * as UsuarioSelectors from "../redux/Selectors/UsuarioSelector";

//Componentes
import ReduxListener from "../Components/ReduxListener/ReduxListener";
import PanelDeReservacion from "../Vistas/PanelDeReservacion/PanelDeReservacion";
import Comparador from "../Vistas/Comparador/Comparador";
import Cabana from "../Vistas/Cabana/Cabana";
import PanelUsuario from "../Vistas/PanelUsuario/PanelUsuario";
import ReservacionTemporal from "../Vistas/ReservacionTemporal/ReservacionTemporal";
import RecuperacionDeContrasena from "../Vistas/RecuperacionDeContrasena/RecuperacionDeContrasena";
import Menu from "../Components/Menu/Menu";
// import iconoCargando from "../../assets/imagenes/cargando.svg";
import Cargando from "../Components/Cargando/Cargando";

//Animaciones
import * as AnimacionesGenerales from "../AnimacionesGenerales/AnimacionesGenerales_exporter";

//Services
import { getIdSesion, setIdSesion } from "../services/seguimientoServices";

//utils
import generadorDeCodigo from "../util/generadorDeCodigoTemp";

const App = () => {
  const cargandoAuth = useSelector((state) =>
    AuthSelectors.cargandoAuth(state)
  );
  const cargandoCabana = useSelector((state) =>
    CabanaSelectors.cargandoCabana(state)
  );
  const cargandoComparador = useSelector((state) =>
    ComparadorSelectors.cargandoComparador(state)
  );
  const cargandoMercadoPago = useSelector((state) =>
    MercadoPagoSelectors.cargandoMercadoPago(state)
  );
  const cargandoReservaciones = useSelector((state) =>
    ReservacionesSelectors.cargandoReservaciones(state)
  );
  const cargandoUsuario = useSelector((state) =>
    UsuarioSelectors.cargandoUsuario(state)
  );
  const [visibilidadCargando, setVisibilidadCargando] = useState(false);
  useEffect(() => {
    if (!getIdSesion()) setIdSesion(generadorDeCodigo());
  }, []);
  useEffect(() => {
    if (visibilidadCargando) {
      AnimacionesGenerales.IniciarCargando();
    }
    if (
      cargandoAuth ||
      cargandoCabana ||
      cargandoComparador ||
      cargandoMercadoPago ||
      cargandoReservaciones ||
      cargandoUsuario
    ) {
      setVisibilidadCargando(true);
    } else {
      AnimacionesGenerales.FinalizarCargando(() =>
        setVisibilidadCargando(false)
      );
    }
  }, [
    visibilidadCargando,
    cargandoAuth,
    cargandoCabana,
    cargandoComparador,
    cargandoMercadoPago,
    cargandoReservaciones,
    cargandoUsuario,
  ]);
  return (
    <Router>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href="../logoSoloMini.png" />
        <link rel="apple-touch-icon" href="../logoSoloMini.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Reservaciones de cabañas en línea, Cima Calidez le ofrece hermosas cabañas en el bosque y uno de los mejores servicios de atención en todo Mazamitla"
        />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
        <title>Cima Calidez - Reservaciones</title>
      </Helmet>
      <Menu />
      <ReduxListener />
      {visibilidadCargando && <Cargando />}
      <Suspense fallback={<Cargando />}>
        <Switch>
          <Route exact path="/" name="Comparador" component={Comparador} />
          <Route
            exact
            path="/calendario/:cabana"
            name="Calendario"
            component={Cabana}
          />
          <Route
            exact
            path="/reservacion"
            name="Confirmar_reservacion"
            component={PanelDeReservacion}
          />
          <Route
            exact
            path={[
              "/panel_usuario",
              "/panel_usuario/reservacion/:codigoDeReservacion",
              "/panel_usuario/verificacion_usuario/:idUsuario/:passwordVerificacion",
            ]}
            name="Datos_de_reservacion"
            component={PanelUsuario}
          />
          <Route
            exact
            path={"/reservacion_temporal/:codigoDeReservacion/:password"}
            name="Datos_de_reservacion"
            component={ReservacionTemporal}
          />
          <Route
            exact
            path={[
              "/recuperacion",
              "/recuperacion/:idUsuario/:passwordTemporalRecuperacion",
            ]}
            name="Datos_de_reservacion"
            component={RecuperacionDeContrasena}
          />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
