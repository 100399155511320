import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//Redux
import * as reservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";
import * as reservacionesActions from "../../redux/Actions/reservacionesActions";
import * as AuthActions from "../../redux/Actions/AuthActions";

//Services
import { getToken } from "../../services/tokenServices";

//Animaciones
import * as AnimacionesGenerales from "../../AnimacionesGenerales/AnimacionesGenerales_exporter";

const ReduxListener = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const listoParaReservar = useSelector((state) =>
    reservacionesSelectors.listoParaReservar(state)
  );
  useEffect(() => {
    if (getToken()) {
      dispatch(AuthActions.verificaAccessToken());
    }
    dispatch(reservacionesActions.resetReservacionesState());
  }, []);
  useEffect(() => {
    if (listoParaReservar) {
      AnimacionesGenerales.Closing(() => history.push("/reservacion"));
    }
  }, [listoParaReservar]);
  return <></>;
};

export default ReduxListener;
