import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import "./Comparador.css";
import Calendario from "../../Components/Calendario/Calendario";
import CantidadDeHuespedes from "../../Components/CantidadDeHuespedes/CantidadDeHuespedes";
import Buscar from "../../Components/Buscar/Buscar";
import ListaDeResultados from "../../Components/ListaDeResultados/ListaDeResultados";
import * as ComparadorActions from "../../redux/Actions/ComparadorActions";
import * as reservacionesActions from "../../redux/Actions/reservacionesActions";
import * as eventosActions from "../../redux/Actions/EventosActions";
import * as ReservacionesSelectors from "../../redux/Selectors/reservacionesSelectors";
import * as ComparadorSelectors from "../../redux/Selectors/ComparadorSelectors";
import { busquedaActualId } from "../../redux/Selectors/EventosSelector";
import * as AnimacionesGenerales from "../../AnimacionesGenerales/AnimacionesGenerales_exporter";
import * as AnimacionesComparador from "./Animaciones/Animaciones_exporter";

//services
import { getInstruccionSeguimiento } from "../../services/seguimientoServices";

const Comparador = () => {
  const dispatch = useDispatch();
  const fechaDeEntrada = useSelector((state) =>
    ReservacionesSelectors.fechaDeEntrada(state)
  );
  const fechaDeSalida = useSelector((state) =>
    ReservacionesSelectors.fechaDeSalida(state)
  );
  const numeroDeHuespedes = useSelector((state) =>
    ReservacionesSelectors.numeroDeHuespedes(state)
  );
  const listoParaReservar = useSelector((state) =>
    ReservacionesSelectors.listoParaReservar(state)
  );
  const cabanasDisponiblesComparador = useSelector((state) =>
    ComparadorSelectors.cabanasDisponiblesComparador(state)
  );
  const fechasSinDisponibilidad = useSelector((state) =>
    ComparadorSelectors.fechasSinDisponibilidad(state)
  );
  const cargandoCalendario = useSelector((state) =>
    ComparadorSelectors.cargandoCalendario(state)
  );
  const minimoNochesSemana = useSelector((state) =>
    ComparadorSelectors.minimoNochesSemana(state)
  );
  const minimoNochesExcepciones = useSelector((state) =>
    ComparadorSelectors.minimoNochesExcepciones(state)
  );
  const busquedaId = useSelector((state) => busquedaActualId(state));
  const [nuevaBusqueda, setNuevaBusqueda] = useState(true);
  const [resultados, setResultados] = useState([]);
  const [busquedaCombinada, setBusquedaCombinada] = useState(false);
  const [paginaCargo, setPaginaCargo] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    window.onbeforeunload = function (e) {}; //Elimina los mensajes previos a cerrar ventana que se pudieron haber generado
    AnimacionesGenerales.Opening(() => {
      setPaginaCargo(true);
    });
    dispatch(ComparadorActions.borrarCabanasDisponibles());
    dispatch(ComparadorActions.getFechasSinDisponibilidad());
    // Limpieza de busquedaId
    if (busquedaId) {
      dispatch(eventosActions.borrarEventoActualBuscar());
    }
    return () => {
      if (busquedaId) {
        dispatch(eventosActions.borrarEventoActualBuscar());
      }
    };
  }, []);
  useEffect(() => {
    if (!listoParaReservar) {
      dispatch(reservacionesActions.resetReservacionesState());
    }
  }, [dispatch, listoParaReservar]);
  useEffect(() => {
    AnimacionesComparador.NuevosResultadosCerrar(() => {
      setNuevaBusqueda(true);
      setResultados([]);
      dispatch(ComparadorActions.borrarCabanasDisponibles());
    });
  }, [fechaDeEntrada, dispatch]);
  useEffect(() => {
    if (cabanasDisponiblesComparador.length > 0) {
      setResultados(cabanasDisponiblesComparador);
    }
  }, [cabanasDisponiblesComparador]);
  useEffect(() => {
    if (resultados && resultados.length > 0) {
      AnimacionesComparador.NuevosResultadosAbrir(() => {
        let listaDeResultados = document.getElementById("listaDeResultados");
        if (listaDeResultados)
          listaDeResultados.scrollIntoView({ behavior: "smooth" });
      });
    }
  }, [resultados]);
  // Actualizacion de evento busqueda para nuevas cantidades de huespedes para actividad-api
  useEffect(() => {
    if (getInstruccionSeguimiento() !== "no_seguir") {
      if (paginaCargo) {
        if (busquedaId && (fechaDeEntrada === null || fechaDeSalida === null)) {
          dispatch(eventosActions.borrarEventoActualBuscar());
        } else if (
          busquedaId &&
          fechaDeEntrada !== null &&
          fechaDeSalida !== null
        ) {
          dispatch(
            eventosActions.putEventoActualBuscar({
              _id: busquedaId,
              numeroDeAdultos: numeroDeHuespedes.numeroDeAdultos,
              numeroDeNinos: numeroDeHuespedes.numeroDeNinos,
              numeroDeBebes: numeroDeHuespedes.numeroDeBebes,
              numeroDeMascotas: numeroDeHuespedes.numeroDeMascotas,
            })
          );
        }
      }
    }
  }, [numeroDeHuespedes, fechaDeEntrada, fechaDeSalida, paginaCargo]);

  return (
    <div id="comparador" className="vista">
      <Helmet>
        <link rel="icon" href="../logoSoloMini.png" />
        <link rel="apple-touch-icon" href="../logoSoloMini.png" />
        <meta
          name="description"
          content={`Comparador de disponibilidad y precios de cabañas, Cima Calidez le ofrece hermosas cabañas en el bosque y uno de los mejores servicios de atención en todo Mazamitla`}
        />
        <title>Cima Calidez - Reservaciones</title>
      </Helmet>
      <div id="calendarioYCantidadDeHuespedes">
        <Calendario
          mesesVisibles={2}
          fechasOcupadas={fechasSinDisponibilidad}
          minimoNochesSemana={minimoNochesSemana}
          minimoNochesExcepciones={minimoNochesExcepciones}
          cargando={cargandoCalendario}
        />
        <div id="cantidadDeHuespedesYBuscar">
          <CantidadDeHuespedes />
          <button
            onClick={() => setBusquedaCombinada(!busquedaCombinada)}
            className="botonTipoDeBusqueda"
          >
            {busquedaCombinada
              ? "Cambiar a busqueda normal"
              : "Cambiar a busqueda combinada"}
          </button>
          <Buscar
            setNuevaBusqueda={setNuevaBusqueda}
            setResultados={setResultados}
            busquedaCombinada={busquedaCombinada}
          />
        </div>
      </div>
      <ListaDeResultados
        resultados={resultados}
        nuevaBusqueda={nuevaBusqueda}
        setNuevaBusqueda={setNuevaBusqueda}
        busquedaCombinada={busquedaCombinada}
        setBusquedaCombinada={setBusquedaCombinada}
      />
    </div>
  );
};

export default Comparador;
