import { all } from "redux-saga/effects";
import AuthSaga from "./AuthSaga";
import ComparadorSaga from "./ComparadorSaga";
import ReservacionesSaga from "./ReservacionesSaga";
import CabanaSaga from "../Sagas/CabanaSaga";
import MercadoPagoSaga from "./MercadoPagoSaga";
import UsuarioSaga from "./UsuarioSaga";
import EventosSaga from "./EventosSaga";

/**
 * Redux Sagas sit between the Actions and Reducers listening for "messages"
 */

export default function* rootSaga(params) {
  console.log(" <---------  Sagas index --------->");
  // console.log(params)
  yield all([
    ComparadorSaga(),
    ReservacionesSaga(),
    CabanaSaga(),
    MercadoPagoSaga(),
    AuthSaga(),
    UsuarioSaga(),
    EventosSaga(),
  ]);
}
