import * as types from "../actionTypes";

const initialState = {
  fechasOcupadas: [],
  cargandoCalendario: false,
  minimoNochesSemana: null,
  minimoNochesExcepciones: null,
  cargandoCabana: false,
};

export default function CabanasReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_FECHAS_OCUPADAS_CABANA:
      return {
        ...state,
        cargandoCalendario: true,
        cargandoCabana: true,
      };
    case types.GET_FECHAS_OCUPADAS_CABANA_SUCCESS:
      return {
        ...state,
        fechasOcupadas: action.resultados.data.fechasOcupadas.map((fecha) =>
          // Se realiza el parseo en Front, ya que back maneja otra hora base
          Date.parse(new Date(fecha.anio, fecha.mes, fecha.dia, 0, 0, 0, 0))
        ),
        minimoNochesSemana: action.resultados.data.minimoNochesSemana,
        minimoNochesExcepciones: action.resultados.data.minimoNochesExcepciones,
        cargandoCalendario: false,
        cargandoCabana: false,
      };
    case types.GET_FECHAS_OCUPADAS_CABANA_ERROR:
      return {
        ...state,
        fechasOcupadas: [],
        cargandoCabana: false,
      };
    default:
      return { ...state };
  }
}
