import { get } from "lodash";

export const reservacionesPropias = (state) =>
  get(state, "UsuarioReducer.reservacionesPropias");
export const reservacionEnfocada = (state) =>
  get(state, "UsuarioReducer.reservacionEnfocada");
export const refrescaReservacionesPropias = (state) =>
  get(state, "UsuarioReducer.refrescaReservacionesPropias");
export const refrescaReservacionEnfocada = (state) =>
  get(state, "UsuarioReducer.refrescaReservacionEnfocada");
export const cargandoUsuario = (state) =>
  get(state, "UsuarioReducer.cargandoUsuario");
