import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import "./RecuperacionDeContrasena.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as AuthActions from "../../redux/Actions/AuthActions";
import * as AuthSelectors from "../../redux/Selectors/AuthSelector";

//Animacions
import * as AnimacionesGenerales from "../../AnimacionesGenerales/AnimacionesGenerales_exporter";

const RecuperacionDeContrasena = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const correoDeRecuperacionEnviado = useSelector((state) =>
    AuthSelectors.correoDeRecuperacionEnviado(state)
  );
  const contrasenaActualizada = useSelector((state) =>
    AuthSelectors.contrasenaActualizada(state)
  );
  const { idUsuario, passwordTemporalRecuperacion } = useParams();
  const [email, setEmail] = useState(null);
  const [pass1, setPass1] = useState(null);
  const [pass2, setPass2] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    AnimacionesGenerales.Opening(() => {});
  }, []);
  useEffect(() => {
    if (contrasenaActualizada || correoDeRecuperacionEnviado) {
      dispatch(AuthActions.resetRecuperacion());
      history.push("/");
    }
  }, [contrasenaActualizada, correoDeRecuperacionEnviado]);
  return (
    <div id="recuperacionDeContrasena" className="vista">
      <Helmet>
        <link rel="icon" href="../logoSoloMini.png" />
        <link rel="apple-touch-icon" href="../logoSoloMini.png" />
        <meta
          name="description"
          content={`Cima Calidez le ofrece hermosas cabañas en el bosque y uno de los mejores servicios de atención en todo Mazamitla`}
        />
        <title>Cima Calidez - Recuperación de cuenta</title>
      </Helmet>
      {!idUsuario && !passwordTemporalRecuperacion ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (e.target.checkValidity()) {
              dispatch(
                AuthActions.recuperacionEnviarCorreo(email.toLowerCase())
              );
            }
          }}
        >
          <h2>Recupere su contraseña</h2>
          <label>Por favor, ingrese el correo electronico de su cuenta</label>
          <input
            type="email"
            required
            onChange={(event) => setEmail(event.target.value.toLowerCase())}
          />
          <button>Enviar</button>
        </form>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (e.target.checkValidity() && pass1 && pass2 && pass1 === pass2) {
              dispatch(
                AuthActions.recuperacionCambiarContrasena({
                  nuevaContrasena: pass1,
                  idUsuario: idUsuario,
                  passwordTemporalRecuperacion: passwordTemporalRecuperacion,
                })
              );
            } else if (pass1 && pass2 && pass1 !== pass2) {
              Swal.fire("Las contraseñas no coinciden", "", "error");
            }
          }}
        >
          <h2>Actualice su contraseña</h2>
          <label>Ingrese su nueva contraseña</label>
          <input
            type="password"
            minLength={8}
            required
            onChange={(event) => setPass1(event.target.value)}
          />
          <label>Repita su contraseña</label>
          <input
            type="password"
            minLength={8}
            required
            onChange={(event) => setPass2(event.target.value)}
          />
          <button>Enviar</button>
        </form>
      )}
    </div>
  );
};

export default RecuperacionDeContrasena;
