import { put, call, takeLatest } from "redux-saga/effects";
import { actCall } from "../reservaciones_api/reservaciones_api";
import * as types from "../actionTypes";
import { getIdSesion } from "../../services/seguimientoServices";

export function* postEventoBuscarSaga({ payload }) {
  try {
    const resultados = yield call(
      actCall,
      "POST",
      "eventos/busqueda",
      {
        fechaDeEntrada: payload.fechaDeEntrada,
        fechaDeSalida: payload.fechaDeSalida,
        numeroDeAdultos: payload.numeroDeAdultos,
        numeroDeNinos: payload.numeroDeNinos,
        numeroDeBebes: payload.numeroDeBebes,
        numeroDeMascotas: payload.numeroDeMascotas,
        idSesion: getIdSesion(),
      },
      {
        "Content-Type": "application/json",
      }
    );
    yield put({ type: types.POST_EVENTO_BUSCAR_SUCCESS, resultados });
  } catch (error) {
    console.log("Error al registrar evento buscar: ", error.response.data);
    yield put({ type: types.POST_EVENTO_BUSCAR_ERROR, payload });
  }
}

export function* postEventoCotizarCabanaSaga({ payload }) {
  try {
    const resultados = yield call(
      actCall,
      "POST",
      "eventos/cotizacion_cabana",
      {
        fechaDeEntrada: payload.fechaDeEntrada,
        fechaDeSalida: payload.fechaDeSalida,
        numeroDeAdultos: payload.numeroDeAdultos,
        numeroDeNinos: payload.numeroDeNinos,
        numeroDeBebes: payload.numeroDeBebes,
        numeroDeMascotas: payload.numeroDeMascotas,
        cabana: payload.cabana,
        idSesion: getIdSesion(),
      },
      {
        "Content-Type": "application/json",
      }
    );
    yield put({
      type: types.POST_EVENTO_COTIZACION_CABANA_SUCCESS,
      resultados,
    });
  } catch (error) {
    console.log(
      "Error al registrar evento cotizar cabaña: ",
      error.response.data
    );
    yield put({ type: types.POST_EVENTO_COTIZACION_CABANA_ERROR, payload });
  }
}

export function* postEventoListoReservarSaga({ payload }) {
  try {
    const resultados = yield call(
      actCall,
      "POST",
      "eventos/listo_reservar",
      {
        fechaDeEntrada: payload.fechaDeEntrada,
        fechaDeSalida: payload.fechaDeSalida,
        numeroDeAdultos: payload.numeroDeAdultos,
        numeroDeNinos: payload.numeroDeNinos,
        numeroDeBebes: payload.numeroDeBebes,
        numeroDeMascotas: payload.numeroDeMascotas,
        cabana: payload.cabana,
        idSesion: getIdSesion(),
      },
      {
        "Content-Type": "application/json",
      }
    );
    yield put({ type: types.POST_EVENTO_LISTO_RESERVAR_SUCCESS, resultados });
  } catch (error) {
    console.log(
      "Error al registrar evento listo para reservar: ",
      error.response.data
    );
    yield put({ type: types.POST_EVENTO_LISTO_RESERVAR_ERROR, payload });
  }
}

export function* putEventoBuscarActualSaga({ payload }) {
  try {
    const resultados = yield call(
      actCall,
      "PUT",
      "eventos/busqueda_actual",
      {
        _id: payload._id,
        numeroDeAdultos: payload.numeroDeAdultos,
        numeroDeNinos: payload.numeroDeNinos,
        numeroDeBebes: payload.numeroDeBebes,
        numeroDeMascotas: payload.numeroDeMascotas,
        idSesion: getIdSesion(),
      },
      {
        "Content-Type": "application/json",
      }
    );
    yield put({ type: types.PUT_EVENTO_ACTUAL_BUSCAR_SUCCESS, resultados });
  } catch (error) {
    console.log(
      "Error al actualizar evento actual buscar: ",
      error.response.data
    );
    yield put({ type: types.PUT_EVENTO_ACTUAL_BUSCAR_ERROR, payload });
  }
}

export function* putEventoCotizarCabanaActualSaga({ payload }) {
  try {
    const resultados = yield call(
      actCall,
      "PUT",
      "eventos/cotizacion_cabana_actual",
      {
        _id: payload._id,
        numeroDeAdultos: payload.numeroDeAdultos,
        numeroDeNinos: payload.numeroDeNinos,
        numeroDeBebes: payload.numeroDeBebes,
        numeroDeMascotas: payload.numeroDeMascotas,
        idSesion: getIdSesion(),
      },
      {
        "Content-Type": "application/json",
      }
    );
    yield put({
      type: types.PUT_EVENTO_ACTUAL_COTIZACION_CABANA_SUCCESS,
      resultados,
    });
  } catch (error) {
    console.log(
      "Error al registrar evento actual cotizar cabaña: ",
      error.response.data
    );
    yield put({
      type: types.PUT_EVENTO_ACTUAL_COTIZACION_CABANA_ERROR,
      payload,
    });
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(types.POST_EVENTO_BUSCAR, postEventoBuscarSaga);
  yield takeLatest(
    types.POST_EVENTO_COTIZACION_CABANA,
    postEventoCotizarCabanaSaga
  );
  yield takeLatest(
    types.POST_EVENTO_LISTO_RESERVAR,
    postEventoListoReservarSaga
  );
  yield takeLatest(types.PUT_EVENTO_ACTUAL_BUSCAR, putEventoBuscarActualSaga);
  yield takeLatest(
    types.PUT_EVENTO_ACTUAL_COTIZACION_CABANA,
    putEventoCotizarCabanaActualSaga
  );
}
